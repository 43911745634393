/* eslint-disable */
import React, { useContext, useEffect } from "react";
import "./App.scss";
import { Redirect, Route, Switch } from "react-router-dom";
import StartPage from "./pages/StartPage";
// import LoginPage from "./pages/LoginPage";
import LoginPage from "./screens/LoginScreen";
// import RegistrationPage from "./pages/RegistrationPage";
import RegistrationPage from "./screens/RegisterScreen";
// import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetPasswordPage from "./screens/ResetPasswordScreen";
import UpdatePasswordPage from "./screens/UpdatePasswordScreen";
import CheckCountryPage from "./pages/CheckCountryPage";
import VerificationPage from "./pages/VerificationPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/PrivateRoute";
import UploadKYCPage from "./pages/UploadKYCPage";
import UpdateKYCDocumentPage from "./pages/UpdateKYCDocumentPage";
import PersonalDetailsPage from "./pages/PersonalDetailsPage";
import MainTemplate from "./pages/MainTemplate";
import LogoutPage from "./pages/LogoutPage";
import ResendRegcodePage from "./pages/ResendRegcodePage";
import { getOwnerSettings } from "./services/general";
import { Context } from "./store";
import Cookies from "js-cookie";
import ReactGA from "react-ga";
import useSuperState from "./hooks/useSuperState";
import Spin from "antd/lib/spin";
import { getUser } from "./services/users";
import ScrollToTop from "./hooks/scrollToTop";
import AccountTypePage from "./pages/AccountTypePage";
import RegistrationEntityPage from "./pages/RegistrationEntityPage";
import UIContextProvider from "./components/RegisterComponents/UIContext";
import Register from "./components/testcode/Register";
import Login from "./components/testcode/LoginPage";
//import KYBPage from "./pages/KYBPage";

const scriptUrl = process.env.REACT_APP_PT_SCRIPT_URL;
const scriptId = process.env.REACT_APP_PT_SCRIPT_ID;

function App() {
  const [state, dispatch] = useContext(Context);
  const [readyForRender, setReadyForRender] = useSuperState(false, true);

  useEffect(() => {
    (async () => {
      const token = Cookies.get("token");
      if (token) {
        try {
          // const { data: userSettings } = await getOwnerSettings();
          const { data: userData } = await getUser();
          // dispatch({ type: "SET_OWNER_SETTINGS", payload: userSettings });
          dispatch({ type: "SET_USER", payload: userData });
        } catch (error) {
          console.error("app page", error);
        } finally {
          setReadyForRender(true);
        }
      } else {
        setReadyForRender(true);
      }
    })();
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: "User",
      action: "Created an Account",
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (!readyForRender) return <Spin size="large" className="ant-spinner" />;

  return (
    <>
      <UIContextProvider>
        <ScrollToTop>
          <div className="App">
            <Switch>
              <Route exact path="/" component={StartPage} />
              <Route path="/registration" component={Register} />
              <Route path="/login" component={Login} />
              <Route path="/verification" component={VerificationPage} />
              <Route path="/registration-entity" component={RegistrationEntityPage} />
              <Route path="/choose-acc-type" component={AccountTypePage} />
              <Route path="/check-country" component={CheckCountryPage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route path="/resend-reg-code" component={ResendRegcodePage} />
              <Route path="/reset-password-confirm" component={UpdatePasswordPage} />
              <PrivateRoute path="/personal-details" component={PersonalDetailsPage} />
              <PrivateRoute path="/upload-kyc" component={UploadKYCPage} />
              <PrivateRoute path="/update-kyc-documents/:id" component={UpdateKYCDocumentPage} />
              {/* <PrivateRoute path="/main/kyc" component={KYBPage} />
            <PrivateRoute path="/main/kyb" component={KYBPage} />
            <PrivateRoute path="/main/kyc" component={KYCPage} /> */}
              <PrivateRoute path="/main" component={MainTemplate} />
              <PrivateRoute path="/logout" component={LogoutPage} />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </div>
        </ScrollToTop>
        <ToastContainer position="bottom-right" />
      </UIContextProvider>
    </>
  );
}

export default App;
