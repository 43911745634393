/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import Cards from "react-credit-cards";
import ReactCardCarousel from "react-card-carousel";
import {
  getCCToken,
  getCCVerificationCode,
  getCreditCardList,
  postPurchases,
  postWithdraw,
} from "../../services/wallet";
import Button from "antd/lib/button";
import "react-credit-cards/lib/styles.scss";
import Row from "antd/lib/grid/row";
import Col from "antd/lib/grid/col";
import Spin from "antd/lib/spin";
import { PURCHASE_SOURCES_AS_OBJECT } from "eurst-shared/src/enums";
import { CARD_TYPE } from "../../constants/constants";
import { Context } from "../../store";
import { Breadcrumb, Divider, Form, Input, Modal, Switch } from "antd";
import { Link, useHistory } from "react-router-dom";
import withKYCApproved from "../../hocs/withKYCApproved";

function CardPayment() {
  const history = useHistory();
  const [token, setToken] = useState("");
  const [state, dispatch] = useContext(Context);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModal3DSVisible, setIsModal3DSVisible] = useState(false);
  const [cardSelection, setCardSelection] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [fundsTransferMethodId, setFundsTransferMethodId] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [resourceId, setResourceId] = useState("");
  const [challengeId, setChallengeId] = useState("");
  const [cards, setCards] = useState([]);
  let carouselRef = useRef();

  const { mintFee } = state.ownerSettings;

  useEffect(() => console.log("carouselRef.current", carouselRef.current));

  const sendDeposit = async () => {
    const { id } = cards[carouselRef.current.getCurrentIndex()];
    window.onPurchaseProtectionReady = async (purchaseProtection) => {
      console.log("[primetrust] 2. Loading embedded accountcreation...");
      setProcessing(true);
      const challengeId = await purchaseProtection
        .start3DS({
          amount: state.amountUSD,
          fundsTransferMethodId: fundsTransferMethodId || id,
          challengeContainer: document.getElementById("challenge"),
        })
        .then(async (challengeId) => {
          setChallengeId(challengeId);
          const localStorageValue = localStorage.getItem("forterToken");
          const payload = {
            currency: "USD",
            amount: state.amountEURST,
            source: PURCHASE_SOURCES_AS_OBJECT.creditCard,
            fundsTransferMethodId: fundsTransferMethodId || id,
            threeDsChallengeId: challengeId,
            forterTokenCookie: localStorageValue,
          };
          try {
            const response = await postPurchases(payload);
            showModal();
          } catch (error) {
            console.error("card payment page", error);
          } finally {
            setProcessing(false);
          }
        })
        .catch((error) => {
          console.error("card payment page", error);
        })
        .finally(() => setProcessing(false));
    };
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    history.push("/main/transactions");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    history.push("/main/dashboard");
  };

  const onChangeCardSelection = (checked) => {
    setCardSelection(checked);
  };

  const onClickVerification = async () => {
    const {
      attributes: { descriptor },
    } = await getCCVerificationCode(resourceId);
    setVerificationCode(descriptor);
  };
  const fetchData = async () => {
    setLoading(true);
    const {
      data: { token, resourceId },
    } = await getCCToken();
    const { include } = await getCreditCardList();
    const element = document.getElementById("verification");
    if (element.children.length === 0) {
      window.primeTrustReady = function (pt) {
        pt.launchCreditCard({
          target: document.getElementById("verification"),
          resourceTokenHash: token,
          events: {
            cardVerified: function (contactId, fundsTransferMethodId) {
              (async () => {
                setLoading(true);
                const { include } = await getCreditCardList();
                setCards(include);
                setLoading(false);
                setCardSelection(true);
                setFundsTransferMethodId(fundsTransferMethodId);
                console.log(
                  "[primetrust] 4. Funds transfer method created with ID:",
                  fundsTransferMethodId
                );
              })();
            },
          },
        });
      };
    }

    setLoading(false);
    setCards(include);
    setResourceId(resourceId);
    setToken(token);
  };

  useEffect(() => {
    fetchData().then();
  }, []);

  const breadcrumbItems = [
    <Breadcrumb.Item key="dashboard">
      <Link to="/main/dashboard">Dashboard</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="mint">
      <Link to="/main/mint-process">Mint</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key={"card-payment"}>
      <Link to={"/main/card-payment"}>Card Payment</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <React.Fragment>
      <Breadcrumb
        style={{
          textAlign: "left",
          marginLeft: "20px",
          marginBottom: "20px",
        }}
      >
        {breadcrumbItems}
      </Breadcrumb>
      <Spin tip="Loading..." spinning={loading}>
        <Row justify={"end"} style={{ paddingRight: "40px" }}>
          <span>Add a card</span>
          <Switch
            checked={cardSelection}
            onChange={onChangeCardSelection}
            style={{ margin: "0 20px" }}
          />
          <span>Use existing card</span>
        </Row>
        <Row style={{ marginTop: "100px", height: "100%" }}>
          <Col span={20} offset={2} style={{ display: !cardSelection ? "block" : "none" }}>
            <div id="verification"></div>
          </Col>
          <Col
            span={20}
            offset={4}
            style={{
              display: cardSelection ? "block" : "none",
            }}
          >
            <Row>
              <Col span={12}>
                <Form
                  wrapperCol={{ span: 14 }}
                  layout="vertical"
                  onValuesChange={() => {}}
                  size={"default"}
                >
                  <Form.Item label="Payment amount due (USD)">
                    <Input value={state.amountUSD} disabled />
                  </Form.Item>
                </Form>
                <Row>
                  <p
                    style={{
                      marginTop: "64px",
                      textAlign: "left",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "17px",
                    }}
                  >
                    <Divider orientation="left"> Note:</Divider>* The amount due includes {mintFee}%
                    commision fee
                    <br /> <br />
                    ** Your USD deposit at Wallex will not be deducted from the payment amount due
                    <br />
                  </p>
                </Row>
                {cards.length !== 0 ? (
                  <Row
                    style={{
                      position: "relative",
                      marginTop: "150px",
                    }}
                  >
                    <ReactCardCarousel
                      style={{
                        position: "relative",
                        marginTop: "400px",
                      }}
                      ref={carouselRef}
                    >
                      {cards.map(({ attributes }, index) => (
                        <Cards
                          key={index}
                          cvc={"123"}
                          issuer={CARD_TYPE[attributes["credit-card-type"]]}
                          expiry={attributes["credit-card-expiration-date"]}
                          name={attributes["credit-card-name"]}
                          preview={true}
                          number={`************${attributes["last-4"]}`}
                        />
                      ))}
                    </ReactCardCarousel>
                  </Row>
                ) : (
                  <span style={{ marginTop: "30px" }}>Please add a card</span>
                )}
              </Col>
              <Col span={12}>
                <div
                  style={{
                    height: "500px",
                    marginRight: "50px",
                  }}
                  id="challenge"
                ></div>
              </Col>
            </Row>

            <Row
              style={{
                marginTop: "80px",
                marginRight: "50px",
                marginBottom: "20px",
              }}
            >
              <Button
                disabled={cards.length === 0 || !state.amountEURST}
                className="primary-button"
                loading={processing}
                onClick={sendDeposit}
                type="primary"
              >
                Proceed to payment
              </Button>
            </Row>
          </Col>
        </Row>
      </Spin>
      <Modal
        title="Payment is accepted"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Go to transactions
          </Button>,
        ]}
      >
        <p>Now you can go to the transactions page and watch the status of your transaction.</p>
      </Modal>
    </React.Fragment>
  );
}

export default withKYCApproved(CardPayment);
