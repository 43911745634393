import React, { useContext, useEffect, useState } from "react";
import { Row, Form, Input, Button, Col, Select, Divider, Spin, Modal } from "antd";
import { Context } from "../../store";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getPersonalInfo, getUser } from "../../services/users";
import { getFundsTransferMethodsList, postWithdraw } from "../../services/wallet";
import "./index.scss";
import withKYCApproved from "../../hocs/withKYCApproved";
import CustomSelect from "../../components/CustomSelect";
import { getCountries } from "../../services/countries";
import { WITHDRAW_SOURCES_AS_OBJECT } from "eurst-shared/src/enums";

const { Option } = Select;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
};

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

function PayerLabel({ countryOfResidence }) {
  let result = "";

  if (countryOfResidence && countryOfResidence === WITHDRAW_SOURCES_AS_OBJECT["us"].toUpperCase()) {
    result = "(US residents)";
  } else if (
    countryOfResidence &&
    countryOfResidence !== WITHDRAW_SOURCES_AS_OBJECT["us"].toUpperCase()
  ) {
    result = "(Non US residents)";
  }

  return <React.Fragment>{result}</React.Fragment>;
}

function BankDetailsPage() {
  const history = useHistory();
  const [state] = useContext(Context);
  const [processing, setProcessing] = useState(false);
  const [user, setUser] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phoneNumber] = useState("");
  const [countries, setCountries] = useState([]);
  const [fundsTransferMethod, setFundsTransferMethod] = useState({});
  const [form] = Form.useForm();

  let payload;

  const onFinish = async (values) => {
    setProcessing(true);
    if (personalInfo.countryOfResidence === WITHDRAW_SOURCES_AS_OBJECT["us"].toUpperCase()) {
      payload = {
        type: WITHDRAW_SOURCES_AS_OBJECT["us"],
        bankAccountName: `${user.firstName} ${user.lastName}`,
        bankAccountNumber: values.bankAccountNumber,
        amount: state.withdrawAmount,
        routingNumber: values.routingNumber,
      };
    } else {
      payload = {
        type: WITHDRAW_SOURCES_AS_OBJECT["international"],
        bankName: values.bankName,
        bankAccountName: values.bankAccountName,
        amount: state.withdrawAmount,
        bankAccountNumber: values.bankAccountNumber,
        intermediaryBankAddress: {
          city: values.ibankCity,
          country: values.ibankCountry,
          street1: values.ibankStreet1,
          postalCode: values.ibankPostalCode,
        },
        swiftCode: values.swiftCode,
        intermediaryBankName: values.intermediaryBankName,
        intermediaryBankAccountNumber: values.intermediaryBankAccountNumber,
        intermediaryBankReference: values.intermediaryBankReference,
        intermediaryBankRoutingNumber: values.intermediaryBankRoutingNumber,
      };
    }
    try {
      const response = await postWithdraw(payload);
      if (response.status === 200) {
        showModal();
      }
    } catch (error) {
      console.error("bank details page", error);
    } finally {
      setProcessing(false);
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    history.push("/main/transactions#withdraw");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    history.push("/withdraw");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getUser();
      const personalData = await getPersonalInfo();
      const countries = await getCountries();
      const funds = await getFundsTransferMethodsList();
      setCountries(countries);
      setUser(data.data);
      setPersonalInfo(personalData.data);

      if (funds.length > 0) {
        setFundsTransferMethod(funds[funds.length - 1]);
      }
      setIsLoading(false);
    })();
  }, []);

  const onInputChange = (event, fieldName) => {
    let updateField = {};
    if (event.target.value === " ") {
      updateField[fieldName] = "";
    } else {
      updateField[fieldName] = event.target.value;
    }
    form.setFieldsValue(updateField);
  };

  const initialBankInfo =
    Object.keys(fundsTransferMethod).length !== 0
      ? {
          bankName: fundsTransferMethod.attributes["bank-name"],
          bankAccountName: fundsTransferMethod.attributes["bank-account-name"],
          routingNumber: fundsTransferMethod.attributes["routing-number"],
          swiftCode: fundsTransferMethod.attributes["swift-code"],
          intermediaryBankName: fundsTransferMethod.attributes["intermediary-bank-name"],
          intermediaryBankReference: fundsTransferMethod.attributes["intermediary-bank-reference"],
          intermediaryBankRoutingNumber:
            fundsTransferMethod.attributes["intermediary-bank-routing-number"],
        }
      : {};
  if (
    personalInfo.countryOfResidence !== WITHDRAW_SOURCES_AS_OBJECT["us"].toUpperCase() &&
    fundsTransferMethod &&
    fundsTransferMethod.attributes &&
    fundsTransferMethod.attributes["intermediary-bank-address"]
  ) {
    initialBankInfo.ibankCountry =
      fundsTransferMethod.attributes["intermediary-bank-address"].attributes.country;
    initialBankInfo.ibankCity =
      fundsTransferMethod.attributes["intermediary-bank-address"].attributes.city;
    initialBankInfo.ibankStreet1 =
      fundsTransferMethod.attributes["intermediary-bank-address"].attributes["street-1"];
    initialBankInfo.ibbankPostalCode =
      fundsTransferMethod.attributes["intermediary-bank-address"].attributes["postal-code"];
  }
  return (
    <React.Fragment>
      <Row>
        <Col className="bank-details-wrap" span={24}>
          <Row justify={"center"}>
            <Col span={24}>
              <span className="bank-details-header">
                Bank details for wire payments{" "}
                <PayerLabel countryOfResidence={personalInfo.countryOfResidence} />
              </span>
              <p className="bank-details-header-desc">
                Used for Payment, Refund and Disbursement when you choose the “Wire” funds transfer
                method
              </p>
            </Col>
          </Row>
          <Row style={{ width: "100%" }} justify={"center"}>
            <Col span={24}>
              {!isLoading ? (
                <Form
                  {...formItemLayout}
                  form={form}
                  style={{ height: "100%", textAlign: "left" }}
                  name="basic"
                  initialValues={{
                    ...personalInfo.address,
                    ...user,
                    bankAccountName: `${user.firstName} ${user.lastName}`,
                    ...initialBankInfo,
                  }}
                  onFinish={onFinish}
                  onFieldsChange={onFieldsChange}
                  onFinishFailed={onFinishFailed}
                  validateMessages={validateMessages}
                >
                  <Row>
                    <Divider orientation="left">Personal details</Divider>
                  </Row>
                  <Row className="bank-form-row">
                    <Col className="bank-details-col" span={12}>
                      <Form.Item name="firstName" label="First Name">
                        <Input disabled placeholder="First Name" />
                      </Form.Item>
                    </Col>
                    <Col className="bank-details-col" span={12}>
                      <Form.Item name="lastName" label="Last Name">
                        <Input disabled placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Divider orientation="left">Beneficiary Address </Divider>
                  </Row>
                  <Row className="bank-form-row">
                    <Col className="bank-details-col" span={12}>
                      <Row>
                        <Col span={24}>
                          <Form.Item name={"address1"} label={"Address 1"}>
                            <Input disabled placeholder="Address 1" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="bank-details-col" span={12}>
                      <Row>
                        <Col span={24}>
                          <Form.Item name={"address2"} label={"Address 2"}>
                            <Input disabled placeholder="Address 2" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="bank-form-row">
                    <Col className="bank-details-col" span={12}>
                      <Form.Item name={"city"} label={"City"}>
                        <Input
                          disabled
                          onChange={(event) => onInputChange(event, "city")}
                          placeholder="City"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="bank-details-col" span={12}>
                      <Form.Item name={"zip"} label={"Postal code"}>
                        <Input disabled placeholder="Postal code" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="bank-form-row" justify={"start"}>
                    <Col className="bank-details-col" span={12}>
                      <Form.Item name="phoneNumber" label="Phone Number">
                        <PhoneInput
                          disableCountryCode
                          disabled
                          value={phoneNumber}
                          country={personalInfo?.countryOfResidence.toLowerCase()}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {personalInfo.countryOfResidence ===
                    WITHDRAW_SOURCES_AS_OBJECT["us"].toUpperCase() && (
                    <Row className="bank-form-row">
                      <Col span={10} offset={2}>
                        <fieldset>
                          <legend>Bank information</legend>

                          <Col className="bank-details-col" span={24}>
                            <Form.Item
                              name="bankAccountName"
                              label="Bank account name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Account Name!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Bank account name"
                                onChange={(event) => onInputChange(event, "bankAccountName")}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24} style={{ textAlign: "left" }}>
                            <Form.Item
                              name={"bankAccountNumber"}
                              label={"US account number"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Account Number!",
                                },
                                {
                                  max: 17,
                                  message: "Account Number should be less than 18 symbols!",
                                  whitespace: true,
                                },
                                {
                                  min: 4,
                                  message: "Account Number should be more than 3 symbols!",
                                  whitespace: true,
                                },
                                {
                                  pattern: /^\d+$/,
                                  message: "Account Number is not valid!",
                                  whitespace: true,
                                },
                              ]}
                            >
                              <Input
                                onChange={(event) => onInputChange(event, "bankAccountNumber")}
                                placeholder="US account number"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24} style={{ textAlign: "left" }}>
                            <Form.Item
                              name={"routingNumber"}
                              label={"ABA Routing number"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Routing Number!",
                                },
                                {
                                  min: 9,
                                  message:
                                    "Routing Number should not be shorter than 9 characters!",
                                  whitespace: true,
                                },
                              ]}
                            >
                              <Input
                                onChange={(event) => onInputChange(event, "routingNumber")}
                                placeholder="ABA Routing number"
                              />
                            </Form.Item>
                          </Col>
                        </fieldset>
                      </Col>
                    </Row>
                  )}
                  {personalInfo.countryOfResidence !==
                    WITHDRAW_SOURCES_AS_OBJECT["us"].toUpperCase() && (
                    <Row className="bank-form-row">
                      <Col className="bank-details-col" span={12}>
                        <fieldset>
                          <legend>Bank information</legend>

                          <Form.Item
                            name="bankName"
                            label="Bank name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Bank name!",
                              },
                            ]}
                          >
                            <Input placeholder="Bank name" />
                          </Form.Item>

                          <Form.Item
                            name={"bankAccountName"}
                            label={"Bank Account Name"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your Account Name!",
                              },
                            ]}
                          >
                            <Input
                              onChange={(event) => onInputChange(event, "bankAccountName")}
                              placeholder="Bank Account Name"
                            />
                          </Form.Item>

                          <Form.Item
                            name={"bankAccountNumber"}
                            label={"Bank Account Number"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your Bank Account Number!",
                              },
                              {
                                max: 17,
                                message: "Account Number should be less than 18 symbols!",
                                whitespace: true,
                              },
                              {
                                min: 4,
                                message: "Account Number should be more than 3 symbols!",
                                whitespace: true,
                              },
                              {
                                pattern: /^\d+$/,
                                message: "Account Number is not valid!",
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              onChange={(event) => onInputChange(event, "bankAccountNumber")}
                              placeholder="Bank Account Number"
                            />
                          </Form.Item>

                          <Form.Item
                            name={"swiftCode"}
                            label={"SWIFT code"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your SWIFT code!",
                              },
                              {
                                len: 8,
                                message: "SWIFT code should be 8 symbols!",
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              onChange={(event) => onInputChange(event, "swiftCode")}
                              placeholder="SWIFT code"
                            />
                          </Form.Item>
                        </fieldset>
                      </Col>
                      <Col className="bank-details-col" span={12}>
                        <fieldset>
                          <legend>Intermediary Bank information</legend>

                          <Form.Item
                            name={"intermediaryBankName"}
                            label={"Bank name"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your Intermediary bank name!",
                              },
                            ]}
                          >
                            <Input
                              onChange={(event) => onInputChange(event, "intermediaryBankName")}
                              placeholder="bank name"
                            />
                          </Form.Item>

                          <Form.Item
                            name={"intermediaryBankAccountNumber"}
                            label={"Bank account number"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your Intermediary bank account number!",
                              },
                              {
                                max: 17,
                                message: "Account Number should be less than 18 symbols!",
                                whitespace: true,
                              },
                              {
                                min: 4,
                                message: "Account Number should be more than 3 symbols!",
                                whitespace: true,
                              },
                              {
                                pattern: /^\d+$/,
                                message: "Account Number is not valid!",
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              onChange={(event) =>
                                onInputChange(event, "intermediaryBankAccountNumber")
                              }
                              placeholder="Bank account number"
                            />
                          </Form.Item>

                          <Form.Item
                            name={"intermediaryBankRoutingNumber"}
                            label={"Bank Routing Number"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your Routing Number!",
                              },
                              {
                                min: 9,
                                message: "Routing Number should not be shorter than 9 characters!",
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              onChange={(event) =>
                                onInputChange(event, "intermediaryBankRoutingNumber")
                              }
                              placeholder="Routing Number"
                            />
                          </Form.Item>

                          <Form.Item name={"intermediaryBankReference"} label={"Bank reference"}>
                            <Input
                              onChange={(event) =>
                                onInputChange(event, "intermediaryBankReference")
                              }
                              placeholder="Bank reference"
                            />
                          </Form.Item>

                          <Form.Item
                            name="ibankCountry"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: "Please input bank country!",
                              },
                            ]}
                          >
                            <CustomSelect
                              style={{ textAlign: "initial" }}
                              placeholder="Select a country"
                            >
                              {countries.map((item, index) => (
                                <Option key={index} value={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                            </CustomSelect>
                          </Form.Item>

                          <Form.Item
                            name={"ibankCity"}
                            label={"City"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your Bank City!",
                              },
                            ]}
                          >
                            <Input
                              onChange={(event) => onInputChange(event, "ibankCity")}
                              placeholder="City"
                            />
                          </Form.Item>

                          <Form.Item
                            name={"ibankStreet1"}
                            label={"Street"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your Bank Address!",
                              },
                            ]}
                          >
                            <Input
                              onChange={(event) => onInputChange(event, "ibankStreet1")}
                              placeholder="Street"
                            />
                          </Form.Item>

                          <Form.Item name={"ibankPostalCode"} label={"Postal code"}>
                            <Input placeholder="Postal code" />
                          </Form.Item>
                        </fieldset>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className="bank-details-btn-col">
                      <Form.Item>
                        <Row justify={"space-between"}>
                          <Col>
                            <Button className="secondary-button" onClick={onCancel}>
                              Cancel
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              className="primary-button"
                              disabled={disabled}
                              loading={processing}
                              htmlType="submit"
                              type="primary"
                            >
                              Save and continue
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <Spin />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title="Your withdrawal request is now being processed"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Go to transactions
          </Button>,
        ]}
      >
        <p>Please check your email to confirm the transactions.</p>
        <p>
          The withdrawal process may take 1-5 business days before the USD is credited to your bank
          account.
        </p>
        <p>Your USD deposit will be frozen until the settlement.</p>
      </Modal>
    </React.Fragment>
  );
}

export default withKYCApproved(BankDetailsPage);
