import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./_admin-sidebar.scss";
import WallexLogo from "../assets/img/sidebar/logo.png";
import DashoardAtiveIcon from "../assets/img/header/dashboard-active.png";
import HomeActiveIcon from "../assets/img/header/home-mobile-active.png";
import TransactionsActiveIcon from "../assets/img/header/transactions-mobile-active.png";
import TransferActiveIcon from "../assets/img/header/transfer-mobile-active.png";
import KybActiveIcon from "../assets/img/header/kyb-mobile-active.png";
import AvatarIcon from "../assets/img/sidebar/avatar.png";
import LogoutImage from "../assets/img/popup/logout-icon.png";
import { Context } from "../store";
import LogoutIcon from "../assets/img/sidebar/logout.png";
import { Modal } from "antd";
import auth from "../services/auth";
import { getUser } from "../services/users";

export default function NewSidebar() {
  const [state, dispatch] = useContext(Context);
  const itemsRef = useRef(null);
  const [localUser, setLocalUser] = useState(null);
  const [currentHeight, setCurrentHeight] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentTop, setCurrentTop] = useState(0);
  const [currentLeft, setCurrentLeft] = useState(0);
  const [user, setUser] = useState(null);
  const history = useHistory();
  const [show, setShow] = useState(true);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const animate = () => {
    if (itemsRef.current) {
      itemsRef.current.querySelectorAll(".admin-sidebar__link").forEach((element, index) => {
        if (element.classList.contains("active-link")) {
          console.log(element.offsetTop);
          setCurrentHeight(element.clientHeight);
          setCurrentWidth(element.clientWidth + 30);
          setCurrentTop(index * 83);
          setCurrentLeft(element.offsetLeft - 15);
        }
      });
    }
  };
  const modalText = "Are you sure you want to logout?";

  const handleOk = () => {
    setConfirmLoading(true);
    auth.logout();
    setConfirmLoading(false);
    setVisible(false);
    history.push("/");
    window.location.reload();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getUser();
      setUser(data);
    };

    getData().catch((err) => {
      console.log(err);
    });
  }, []);

  console.log(user, ":User data");

  history.listen(() => {
    if (history.location.pathname === "/main/profile") {
      setShow(false);
    } else {
      setShow(true);
    }
    setTimeout(() => {
      animate();
    }, 100);
  });

  useEffect(async () => {
    animate();

    if (!user?.type) {
      const user = await JSON.parse(localStorage.getItem("user"));
      console.log(user, "Im useEff");
      setLocalUser(user);
    }
  }, []);

  useEffect(() => {});

  return (
    <div className="admin-sidebar">
      <div className="admin-sidebar__logo">
        <Link to="/main/dashboard">
          <img src={WallexLogo} alt="logo" />
        </Link>
      </div>
      <hr />
      <div className="admin-sidebar__content">
        <p className="admin-sidebar__header">Menu</p>
        <ul className="admin__sidebar__items" ref={itemsRef}>
          {!show ? null : (
            <div
              className="admin-sidebar__overlay"
              style={{
                transform: `translateY(${currentTop}px)`,
                width: currentWidth,
                height: currentHeight,
                left: currentLeft,
              }}></div>
          )}
          <li className="admin-sidebar__item">
            <NavLink
              to="/main/dashboard"
              className="admin-sidebar__link"
              activeClassName="active-link">
              <img src={DashoardAtiveIcon} alt="Dashboard active icon" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="admin-sidebar__item">
            <NavLink to="/main/home" activeClassName="active-link" className="admin-sidebar__link">
              <img src={HomeActiveIcon} alt="Dashboard active icon" />
              <span>Mint &amp; Redeem</span>
            </NavLink>
          </li>
          <li className="admin-sidebar__item">
            <NavLink
              to="/main/withdraw"
              activeClassName="active-link"
              className="admin-sidebar__link">
              <img src={TransferActiveIcon} alt="Dashboard active icon" />
              <span>Transfer</span>
            </NavLink>
          </li>
          <li className="admin-sidebar__item">
            <NavLink
              to="/main/transactions"
              activeClassName="active-link"
              className="admin-sidebar__link">
              <img src={TransactionsActiveIcon} alt="Dashboard active icon" />
              <span>Transactions</span>
            </NavLink>
          </li>
          {user?.type === "company" || localUser?.type === "company" ? (
            <li className="admin-sidebar__item">
              <NavLink to="/main/kyb" activeClassName="active-link" className="admin-sidebar__link">
                <img src={KybActiveIcon} alt="Dashboard active icon" />
                <span>KYB</span>
              </NavLink>
            </li>
          ) : (
            <li className="admin-sidebar__item">
              <NavLink to="/main/kyc" activeClassName="active-link" className="admin-sidebar__link">
                <img src={KybActiveIcon} alt="Dashboard active icon" />
                <span>KYC</span>
              </NavLink>
            </li>
          )}
        </ul>
        <div
          className="admin-sidebar__profile"
          onClick={() => {
            history.push("/main/profile");
          }}>
          <div className="admin-sidebar__header" style={{ marginBottom: "5px" }}>
            Profie
          </div>
          <div className="admin-sidebar__user">
            <img src={AvatarIcon} alt="profile" draggable="false" />
            <div className="admin-sidebar__info">
              <p className="admin-sidebar__name" style={{ textAlign: "center" }}>
                {user?.userName}
              </p>
              <p className="admin-sidebar__email">{user?.email}</p>
            </div>
          </div>
          <div
            className="admin-sidebar__logout"
            onClick={() => {
              setVisible(true);
            }}>
            <img src={LogoutIcon} alt="logout" draggable={false} />
            <p>Logout</p>
          </div>
        </div>
      </div>
      <Modal
        title={
          <>
            <img src={LogoutImage} alt="Logout icon" />
            <span>Logout</span>
          </>
        }
        visible={visible}
        centered
        okText="Yes, Logout"
        cancelText="Cancel"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}
