import { Modal } from "antd";
import { USER_TYPE } from "eurst-shared/src/enums";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import auth from "../../services/auth";
import PrivacyPolicy from "../RegisterComponents/PrivacyPolicy";
import TermsOfUse from "../RegisterComponents/TermsOfUse";
import { UIContext } from "../RegisterComponents/UIContext";
import CheckBox from "./CheckBox";
import InputGroup from "./InputGroup";
import SelectCountry from "./SelectCountry";

export default function BusinessTab() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValidity, setEmailValidity] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState(false);
  const [passwordConfirmValidity, setPasswordConfirmValidity] = useState(false);
  const [privacyVisible, setPrivacyVisible] = useState(false);
  const [companyNameValidaty, setCompanyNameValidaty] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [countryValidaity, setCountryValidaity] = useState(false);
  const [checked, setChecked] = useState(false);
  const [country, setCountry] = useState("");
  const { setVisible: setVisibleFn } = useContext(UIContext);
  const history = useHistory();

  const showModal = () => {
    setVisibleFn(true);
  };

  const handleCompanyNameChange = (e) => {
    e.persist();
    setCompanyName(e.target.value);
  };

  const handleSubmit = async () => {
    if (!country) {
      setCountryError(true);
      return;
    } else {
      setCountryError(false);
    }
    const requestPayload = {
      email,
      password,
      userName: companyName,
      countryCode: country.code,
      type: USER_TYPE.company,
    };
    try {
      const registrationResponse = await auth.registration(requestPayload);
      if (registrationResponse.id) {
        history.push("/login");
        showModal();
      }
      console.log(requestPayload);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePasswordChange = (e) => {
    e.persist();
    setPassword(e.target.value);
  };

  const handlePasswordConfirmChange = (e) => {
    e.persist();
    setPasswordConfirm(e.target.value);
  };

  const handleEmailChange = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  const handleTermsClick = () => {
    setTermsVisible(true);
  };

  const handleOkTerms = () => {
    setTermsVisible(false);
  };

  const handleCancelTerms = () => {
    setTermsVisible(false);
  };

  const showPrivacyPolicyModal = () => {
    setPrivacyVisible(true);
  };

  const handleOkPrivacy = () => {
    setPrivacyVisible(false);
  };

  const handleCancelPrivacy = () => {
    setPrivacyVisible(false);
  };
  return (
    <>
      <SelectCountry
        name="business-select-country"
        handleCountryChange={(country) => {
          setCountry(country);
        }}
        errorValue={countryError}
        markAsValid={(validatity) => {
          setCountryValidaity(validatity);
        }}
      />
      <InputGroup
        name="email"
        placeholder="john_deo855@gmail.com"
        type="email"
        value={email}
        markAsValid={(validatity) => {
          setEmailValidity(validatity);
        }}
        rules={[
          { type: "required", message: "Email is required." },
          { type: "email", message: "Email is invalid." },
        ]}
        onChange={handleEmailChange}
        error={emailError}
        label="Email Address"
      />
      <InputGroup
        name="company_name"
        rules={[
          {
            type: "required",
            message: "Company Name is required.",
          },
          {
            type: "min",
            min: 2,
            message: "Company Name must be at least 2 characters.",
          },
          {
            type: "max",
            max: 20,
            message: "Company Name can't be more than 20 characters.",
          },
        ]}
        placeholder="Business for User"
        type="text"
        markAsValid={(validatity) => {
          setCompanyNameValidaty(validatity);
        }}
        value={companyName}
        onChange={handleCompanyNameChange}
        error={companyNameError}
        label="Create Company Name"
      />
      <InputGroup
        name="password"
        placeholder="*********"
        rules={[
          { type: "required", message: "Password is required." },

          {
            type: "min",
            min: 8,
            message: "Password must be at least 8 Symbols.",
          },
          {
            type: "password",
            message: "password must have at least 1 uppercase character & 1 number & 1 Symbol",
          },
        ]}
        type="password"
        markAsValid={(validatity) => {
          setPasswordValidity(validatity);
        }}
        value={password}
        onChange={handlePasswordChange}
        error={passwordError}
        icon="remove_red_eye"
        label="Create Password"
      />
      <InputGroup
        name="password_confirm"
        placeholder="*********"
        type="password"
        icon="remove_red_eye"
        markAsValid={(validatity) => {
          setPasswordConfirmValidity(validatity);
        }}
        value={passwordConfirm}
        onChange={handlePasswordConfirmChange}
        error={passwordConfirmError}
        label="Confirm Password"
        rules={[
          { type: "required", message: " Please confirm your password." },
          { type: "check", against: password, message: "Passwords do not match." },
        ]}
      />
      <div className="auth-card__actions">
        <CheckBox
          name="header-bulk-bussiness"
          id="header-bulk-bussiness"
          onChange={(v) => {
            setChecked(v);
          }}
          isAnimated={true}
          checked={false}
          style="primary"
          label=""
        />
        <p>
          I have read the{" "}
          <a onClick={handleTermsClick} style={{ cursor: "pointer" }}>
            Terms of Use{" "}
          </a>
          and <a onClick={showPrivacyPolicyModal}>Privacy Policy</a>.
        </p>
      </div>
      <div className="auth-card__submit">
        <button
          className={`btn btn-primary btn-block ${
            companyNameValidaty &&
            emailValidity &&
            passwordValidity &&
            passwordConfirmValidity &&
            countryValidaity &&
            checked
              ? ""
              : "disabled"
          }`}
          onClick={handleSubmit}>
          Register
        </button>
      </div>
      <div className="login-message">
        <p>
          Already have an account?{" "}
          <a
            onClick={() => {
              history.push("/login");
            }}
            style={{ cursor: "pointer" }}>
            Login
          </a>
        </p>
      </div>
      <Modal
        className="terms-of-use-popup"
        visible={termsVisible}
        centered
        okText="Agree"
        onOk={handleOkTerms}
        cancelText="Close"
        onCancel={handleCancelTerms}>
        <TermsOfUse handleCancelTerms={handleCancelTerms} />
      </Modal>
      <Modal
        className="privacy-policy-popup"
        visible={privacyVisible}
        centered
        okText="Agree"
        onOk={handleOkPrivacy}
        cancelText="Close"
        onCancel={handleCancelPrivacy}>
        <PrivacyPolicy handleCancelPrivacy={handleCancelPrivacy} />
      </Modal>
    </>
  );
}
