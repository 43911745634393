// modules
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// assets
import CloseIcon from "../../assets/img/reset-password/close.png";
// redux
import { toggleDashboardActionsModal } from "../../context/actions";

const DashboardActionsModal = () => {
  // dispatcher
  const dispatcher = useDispatch();
  // toggle modal
  const toggleModal = (e) => {
    e.stopPropagation();
    dispatcher(toggleDashboardActionsModal());
  };

  return (
    <div className="dashboard-actions-modal">
      <div className="dashboard-modal-heading">
        <h2>Apply Filter</h2>
        <img src={CloseIcon} alt="Close icon" className="close" onClick={toggleModal} />
      </div>
      <div className="dashboard-modal-body">
        <Link to="/main/mint-process" onClick={toggleModal}>
          Mint
        </Link>
        <Link to="/main/redeem-process" onClick={toggleModal}>
          Redeem
        </Link>
      </div>
    </div>
  );
};

export default DashboardActionsModal;
