import DateOfBirth from "./DateOfBirth";
import InputGroup from "./InputGroup";
import SelectCountry from "./SelectCountry";
import React, { useContext, useState } from "react";
import CheckBox from "./CheckBox";
import moment from "moment";
import auth from "../../services/auth";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import TermsOfUse from "../RegisterComponents/TermsOfUse";
import PrivacyPolicy from "../RegisterComponents/PrivacyPolicy";
import { UIContext } from "../RegisterComponents/UIContext";

export default function PrivateTab() {
  const USER_TYPE = {
    naturalPerson: "natural_person",
    company: "company",
  };
  const [email, setEmail] = useState("");
  const [emailValidity, setEmailValidity] = useState(false);
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [termsVisible, setTermsVisible] = useState(false);
  const [privacyVisible, setPrivacyVisible] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [username, setUsername] = useState("");
  const [passwordValidity, setPasswordValidity] = useState(false);
  const [passwordConfirmValidity, setPasswordConfirmValidity] = useState(false);
  const [usernameValidity, setUsernameValidity] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [date, setDate] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [country, setCountry] = useState("");
  const [checked, setChecked] = useState(false);

  const [dateValidaty, setDateValidaty] = useState(false);
  const [countryValidaity, setCountryValidaity] = useState(false);
  const { setVisible: setVisibleFn } = useContext(UIContext);
  const showModal = () => {
    setVisibleFn(true);
  };

  const handleSubmit = async (e) => {
    if (!country) {
      setCountryError(true);
      return;
    } else {
      setCountryError(false);
    }
    const requestPayload = {
      email,
      password,
      userName: username,
      countryCode: country.code,
      dateOfBirth: moment(date).format("YYYY-MM-DD"),
      type: USER_TYPE.naturalPerson,
    };
    try {
      const registrationResponse = await auth.registration(requestPayload);
      if (registrationResponse.id) {
        history.push("/login");
        showModal();
      }
      console.log(requestPayload);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUsernameChange = (e) => {
    e.persist();
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    e.persist();
    setPassword(e.target.value);
  };

  const handlePasswordConfirmChange = (e) => {
    e.persist();
    setPasswordConfirm(e.target.value);
  };

  const handleEmailChange = (e) => {
    e.persist();
    setEmail(e.target.value);
  };

  const handleTermsClick = () => {
    setTermsVisible(true);
  };

  const handleOkTerms = () => {
    setTermsVisible(false);
  };

  const handleCancelTerms = () => {
    setTermsVisible(false);
  };

  const showPrivacyPolicyModal = () => {
    setPrivacyVisible(true);
  };

  const handleOkPrivacy = () => {
    setPrivacyVisible(false);
  };

  const handleCancelPrivacy = () => {
    setPrivacyVisible(false);
  };

  return (
    <>
      <SelectCountry
        name="private-select-country"
        handleCountryChange={(country) => {
          setCountry(country);
        }}
        errorValue={countryError}
        markAsValid={(validatity) => {
          setCountryValidaity(validatity);
        }}
      />
      <InputGroup
        name="email"
        placeholder="john_deo855@gmail.com"
        type="email"
        value={email}
        markAsValid={(validatity) => {
          setEmailValidity(validatity);
        }}
        rules={[
          { type: "required", message: "Email is required." },
          { type: "email", message: "please enter a valid email" },
        ]}
        onChange={handleEmailChange}
        error={emailError}
        label="Email Address"
      />
      <InputGroup
        name="username"
        rules={[
          {
            type: "required",
            message: "Username is required.",
          },
          {
            type: "min",
            min: 5,
            message: "Username must be at least 5 characters.",
          },
          {
            type: "max",
            max: 20,
            message: "Username can't be more than 20 characters.",
          },
        ]}
        placeholder="john_doe"
        type="text"
        markAsValid={(validatity) => {
          setUsernameValidity(validatity);
        }}
        value={username}
        onChange={handleUsernameChange}
        error={usernameError}
        label="Create Username"
      />
      <DateOfBirth
        name="date-of-birth"
        markAsValid={(valid) => {
          setDateValidaty(valid);
        }}
        onDateChange={(date) => {
          console.log(date);
          setDate(date);
        }}
      />
      <InputGroup
        name="password"
        placeholder="*********"
        rules={[
          { type: "required", message: "Password is required." },
          {
            type: "min",
            min: 8,
            message: "Password must be at least 8 Symbols.",
          },
          {
            type: "password",
            message: "password must have at least 1 uppercase character & 1 number & 1 Symbol",
          },
        ]}
        type="password"
        markAsValid={(validatity) => {
          setPasswordValidity(validatity);
        }}
        value={password}
        onChange={handlePasswordChange}
        error={passwordError}
        icon="remove_red_eye"
        label="Create Password"
      />
      <InputGroup
        name="password_confirm"
        placeholder="*********"
        type="password"
        icon="remove_red_eye"
        markAsValid={(validatity) => {
          setPasswordConfirmValidity(validatity);
        }}
        value={passwordConfirm}
        onChange={handlePasswordConfirmChange}
        error={passwordConfirmError}
        label="Confirm Password"
        rules={[
          { type: "required", message: " Please confirm your password." },
          { type: "check", against: password, message: "Passwords do not match." },
        ]}
      />
      <div className="auth-card__actions">
        <CheckBox
          name="header-bulk"
          id="header-bulk"
          onChange={(v) => {
            setChecked(v);
          }}
          isAnimated={true}
          checked={false}
          style="primary"
          label=""
        />
        <p>
          I have read the{" "}
          <a onClick={handleTermsClick} style={{ cursor: "pointer" }}>
            Terms of Use{" "}
          </a>
          and <a onClick={showPrivacyPolicyModal}>Privacy Policy</a>.
        </p>
      </div>
      <div className="auth-card__submit">
        <button
          className={`btn btn-primary btn-block ${
            usernameValidity &&
            emailValidity &&
            passwordValidity &&
            passwordConfirmValidity &&
            countryValidaity &&
            dateValidaty &&
            checked
              ? ""
              : "disabled"
          }`}
          onClick={(e) => {
            if (
              usernameValidity &&
              emailValidity &&
              passwordValidity &&
              passwordConfirmValidity &&
              countryValidaity &&
              dateValidaty &&
              checked
            ) {
              handleSubmit();
            } else {
              e.preventDefault();
            }
          }}>
          Register
        </button>
        <Modal
          className="terms-of-use-popup"
          visible={termsVisible}
          centered
          okText="Agree"
          onOk={handleOkTerms}
          cancelText="Close"
          onCancel={handleCancelTerms}>
          <TermsOfUse handleCancelTerms={handleCancelTerms} />
        </Modal>
        <Modal
          className="privacy-policy-popup"
          visible={privacyVisible}
          centered
          okText="Agree"
          onOk={handleOkPrivacy}
          cancelText="Close"
          onCancel={handleCancelPrivacy}>
          <PrivacyPolicy handleCancelPrivacy={handleCancelPrivacy} />
        </Modal>
      </div>
      <div className="login-message">
        <p>
          Already have an account?{" "}
          <a
            onClick={() => {
              history.push("/login");
            }}
            style={{ cursor: "pointer" }}>
            Login
          </a>
        </p>
      </div>
    </>
  );
}
