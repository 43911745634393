// modules & hooks
import React from "react";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../constants";
// components
import { HomeShowcase } from "../components/HomeComponents";
import { RecentTransactions } from "../components/TransactionsComponents";
// styles
import "../styles/PagesStyles/HomeStyles/index.scss";

const HomePage = () => {
  // window size
  const windowSize = useWindowSize();
  return (
    <section
      className={`home ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "container-fluid"
      }`}>
      <HomeShowcase />
      <RecentTransactions />
    </section>
  );
};

export default HomePage;
