// modules
import React from "react";

const RedeemForm = () => {
  return (
    <div className="redeem__form">
      <form>
        <div className="form__grid">
          <div className="form__col">
            <div className="input__field">
              <label htmlFor="balance">EURST Balance</label>
              <input className="grey" type="text" placeholder="0" id="balance" name="balance" />
            </div>
            <div className="conclusion__rate">
              <span>Rate</span>
              <div className="rate__content">
                <span>1 EURST</span>
                <span>=</span>
                <span>1.137879 USD</span>
              </div>
            </div>
          </div>
          <div className="form__col">
            <div className="input__field">
              <label htmlFor="deposit">Deposit USD as Wallex</label>
              <input className="grey" type="text" placeholder="0" id="deposit" name="deposit" />
            </div>
          </div>
        </div>
        <div className="form__conclusion">
          <div className="form__grid">
            <div className="form__col">
              <div className="input__field container">
                <label htmlFor="amount">Amount</label>
                <span style={{ fontStyle: "italic", fontWeight: "600", fontSize: "1rem" }}>E</span>
                <input className="grey" type="text" placeholder="0" id="amount" name="amount" />
              </div>
            </div>
            <div className="form__col">
              <div className="input__field container">
                <label htmlFor="currency">USD</label>
                <span className="dollar">$</span>
                <input className="grey" type="text" placeholder="0" id="currency" name="currency" />
              </div>
            </div>
          </div>
        </div>
        <button>Redeem EURST</button>
      </form>
    </div>
  );
};

export default RedeemForm;
