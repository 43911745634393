// modules
import React from "react";
import { UpdatePasswordForm } from "../components/UpdatePasswordComponents";
// styles
import "../styles/ScreensStyles/UpdatePasswordStyles/index.scss";

const UpdatePasswordPage = () => {
  return (
    <div className="update-password">
      <div className="container-fluid">
        <UpdatePasswordForm />
      </div>
    </div>
  );
};

export default UpdatePasswordPage;
