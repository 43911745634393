// modules
import React, { useEffect, useState } from "react";
import { MEDIA_QUERIES } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
// components
import BankAccount from "./BankAccount";

const TransferShowcase = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);

  console.log({ selectedAccount });

  useEffect(() => {
    setBankAccounts((ps) => DUMMY_ACCOUNTS);
  }, []);

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="transfer__showacase">
      <div className="available-balance">
        <h3 className="container-heading">Available Balance</h3>
        <div className="content">
          <h4>Wallet Balance</h4>
          <span>$15,16,890.12</span>
        </div>
      </div>
      <div
        className={`select-account ${
          windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null
        }`}>
        <h3 className="container-heading">Select Account</h3>
        <div className="selection__container scrollable">
          {bankAccounts?.map((account) => (
            <BankAccount
              key={account.id}
              account={account}
              setSelectedAccount={setSelectedAccount}
              selectedAccount={selectedAccount}
            />
          ))}
        </div>
        <div className="showcase__options">
          <button
            className={`btn-black lg ${selectedAccount ? "clickable" : null}`}
            disabled={!selectedAccount ? true : false}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

const DUMMY_ACCOUNTS = [
  {
    id: Math.floor(Math.random() * 1000),
    bank_name: "Barclays Bank",
    username: "Deepak Sharma",
    account_no: 98956735987234,
  },
  {
    id: Math.floor(Math.random() * 1000),
    bank_name: "Bank of America",
    username: "John Doe",
    account_no: 98956735987234,
  },
  {
    id: Math.floor(Math.random() * 1000),
    bank_name: "NatWest Markets PLC.",
    username: "Robert Williamson",
    account_no: 98956735987234,
  },
];

export default TransferShowcase;
