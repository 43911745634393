import React, { useEffect, useState } from "react";
import TransactionsHeader from "./TransactionsHeader";
import { Table } from "antd";
import TransactionsApplyFilter from "../../../UI/TransactionsApplyFilter";
import { useSelector } from "react-redux";
import { GetTransactions } from "../../../services/users";

// antd table columns
const columns = [
  {
    title: "S.No.",
    dataIndex: "no",
    key: "no",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Token",
    dataIndex: "token",
    key: "token",
  },
  {
    title: "USD Payment",
    dataIndex: "usd_payment",
    key: "usd_payment",
  },
  {
    title: "Payment Method",
    dataIndex: "method",
    key: "method",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "Reference",
    dataIndex: "reference",
    key: "reference",
  },
  {
    title: "Fee",
    dataIndex: "fee",
    key: "fee",
  },
];
// antd table data
const data = [
  {
    no: "1",
    id: "11",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Approved"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "2",
    id: "22",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURSsssT",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Pending"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "3",
    id: "33",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Approved"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "4",
    id: "48",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Declined"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "5",
    id: "89",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Declined"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "6",
    id: "86",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Pending"],
    reference: "-",
    fee: 3.45,
  },
  {
    no: "7",
    id: "90",
    date: "1-3-2020",
    amount: "2345.45",
    token: "EURST",
    usd_payment: 2345.34,
    payment_method: "Credit Card",
    status: ["Approved"],
    reference: "-",
    fee: 3.45,
  },
];

const HomeTransactions = () => {
  const isTransactionsApplyFilter = useSelector(
    (state) => state.shared.ui.isTransactionsApplyFilter
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await GetTransactions();
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    getData().catch((err) => {
      console.log(err);
    });
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <TransactionsHeader />
      <Table columns={columns} dataSource={data || []} />
      {isTransactionsApplyFilter ? <TransactionsApplyFilter /> : null}
    </div>
  );
};

export default HomeTransactions;
