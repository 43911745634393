// modules & hooks
import React from "react";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../constants";
// components
import { LoginForm } from "../components/LoginComponents";
// styles
import "../styles/ScreensStyles/LoginStyles/index.scss";

const Login = () => {
  // window size
  const windowSize = useWindowSize();

  return (
    <div
      className={`login-form ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "container-fluid"
      }`}>
      <LoginForm />
    </div>
  );
};

export default Login;
