import { getInstance } from "../utils/helpers";
import Cookies from "js-cookie";

function registration({ userName, email, password, countryCode, dateOfBirth, type }) {
  const payload = {
    userName,
    email,
    password,
    countryCode,
    dateOfBirth,
    type,
  };
  const url = `/api/v1/auth/register`;
  return getInstance().post(url, payload);
}

function login({ email, password }) {
  const payload = {
    email,
    password,
  };
  const url = `/api/v1/auth/login`;
  return getInstance().post(url, payload);
}

function verification(token) {
  const url = `/api/v1/auth/register-confirm`;
  const payload = {
    token,
  };
  return getInstance().put(url, payload);
}

function logout() {
  Cookies.remove("token");
}

function resetPassword(email) {
  const url = `/api/auth/reset-password`;
  const payload = {
    email,
  };
  return getInstance().post(url, payload);
}

function resetPasswordConfirm(token, data) {
  Cookies.set("token", token);

  const url = `/api/auth/reset-password/confirm`;

  return getInstance()
    .put(url, data)
    .finally(() => Cookies.remove("token"));
}

function resendRegLink(data) {
  const url = `/api/auth/resend-confirmation-link`;

  return getInstance().post(url, data);
}

export default {
  login,
  registration,
  verification,
  logout,
  resetPassword,
  resetPasswordConfirm,
  resendRegLink,
};
