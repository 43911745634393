// modules
import React, { useState } from "react";
import { Select } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import useWindowSize from "../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../constants";

const MintForm = () => {
  const TRANSFER_TYPES = { CARD: "CARD", WIRE_PAYMENT: "WIRE_PAYMENT" };
  // transfer type
  const [transferType, setTransferType] = useState(TRANSFER_TYPES.CARD);

  const { Option } = Select;

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  // toggle the tranfer type
  const toggleTransferType = (type) => setTransferType(type);

  // window size
  const windowSize = useWindowSize();

  return (
    <div className={`mint__form ${windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null}`}>
      <form>
        <div className="form__grid">
          <div className="form__col">
            <div className="input__field">
              <label htmlFor="recipientName">EURST Balance</label>
              <input
                className="grey"
                type="text"
                placeholder="0"
                id="recipientName"
                name="recipientName"
              />
            </div>
            <div className="input__field">
              <label htmlFor="recipientPhoneNumber">Amount</label>
              <input
                className="grey"
                type="text"
                placeholder="0"
                id="recipientPhoneNumber"
                name="recipientPhoneNumber"
              />
            </div>
          </div>
          <div className="form__col">
            <div className="input__field">
              <label htmlFor="country">USD Deposit at Wallex</label>
              <input
                className="grey"
                type="text"
                placeholder="0"
                id="accountNumber"
                name="accountNumber"
              />
            </div>
            <div className="input__field">
              <label htmlFor="bank">Destination Address</label>
              <Select defaultValue="Select Bank" style={{ width: 200 }} onChange={handleChange}>
                <Option value="Select Bank">Select Bank</Option>
                <Option value="Select Bank">Select Bank</Option>
              </Select>
            </div>
          </div>
        </div>
        <div
          className={`form__conclusion ${
            windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null
          }`}>
          <div className="form__col">
            {windowSize.width > MEDIA_QUERIES.MD ? (
              <div className="conclusion__rate">
                <span>Rate</span>
                <div className="rate__content">
                  <span>1 EURST</span>
                  <span>=</span>
                  <span>1.137879 USD</span>
                </div>
              </div>
            ) : null}
            <div className="tranfer-type">
              <span>Transfer Type</span>
              <div className="transfer-content">
                <div
                  className={`type ${transferType === TRANSFER_TYPES.CARD ? "selected" : null}`}
                  onClick={() => toggleTransferType(TRANSFER_TYPES.CARD)}>
                  {transferType === TRANSFER_TYPES.CARD ? (
                    <CheckCircleFilled
                      style={{
                        color: "#0054B8",
                        position: "absolute",
                        right: ".2rem",
                        top: ".2rem",
                      }}
                    />
                  ) : null}
                  <span>Credit/Debit Card</span>
                </div>
                <div
                  className={`type ${
                    transferType === TRANSFER_TYPES.WIRE_PAYMENT ? "selected" : null
                  }`}
                  onClick={() => toggleTransferType(TRANSFER_TYPES.WIRE_PAYMENT)}>
                  {transferType === TRANSFER_TYPES.WIRE_PAYMENT ? (
                    <CheckCircleFilled
                      style={{
                        color: "#0054B8",
                        position: "absolute",
                        right: ".2rem",
                        top: ".2rem",
                      }}
                    />
                  ) : null}
                  <span>Wire Payment</span>
                </div>
              </div>
            </div>
          </div>
          {windowSize.width > MEDIA_QUERIES.MD ? (
            <div className="form__col">
              <div className="input__field">
                <label htmlFor="country">USD</label>
                <input
                  className="grey"
                  type="text"
                  placeholder="0"
                  id="accountNumber"
                  name="accountNumber"
                />
              </div>
            </div>
          ) : null}
        </div>
        <button>{windowSize.width > MEDIA_QUERIES.MD ? "Proceed to Payment" : "Mint EURST"}</button>
      </form>
    </div>
  );
};

export default MintForm;
