/* eslint-disable */
import React, { useContext, useState } from "react";
import { Form, Input, Layout, Col, Row, Button, Badge, Divider, Modal, Breadcrumb } from "antd";
import { Context } from "../../store";
import { getPurchasesList, postPurchases } from "../../services/wallet";
import { Link, useHistory, withRouter } from "react-router-dom";
import { PURCHASE_SOURCES_AS_OBJECT } from "eurst-shared/src/enums";
import "./index.scss";
import withKYCApproved from "../../hocs/withKYCApproved";

const { Content } = Layout;

function WirePaymentPage(props) {
  const [state] = useContext(Context);
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const { fee, feeAsOne } = state.ownerSettings;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    history.push("/main/transactions");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    history.push("/main/dashboard");
  };

  const onClickButton = async () => {
    setLoading(true);
    const payload = {
      currency: "USD",
      amount: state.amountEURST,
      source: PURCHASE_SOURCES_AS_OBJECT.wire,
    };

    await postPurchases(payload);
    const listPurchases = await getPurchasesList();

    setLoading(false);

    showModal();
  };

  const breadcrumbNameMap = {
    "/main/dashboard": "Dashboard",
    "/main/mint-process": "Mint",
    "/main/wire-payment": "Wire payment",
  };

  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="dashboard">
      <Link to="/main/dashboard">Dashboard</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="mint">
      <Link to="/main/mint-process">Mint</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key={"wire-payment"}>
      <Link to={"/main/wire-payment"}>Wire payment</Link>
    </Breadcrumb.Item>,
  ];
  return (
    <React.Fragment>
      <Layout className="mint-wrap wire-wrap">
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        <Content>
          <Row>
            <Row>
              <span className="wire-header">Wire payment</span>
            </Row>
            <Row>
              <Col className="mint-col" span={12} offset={2}>
                <Form
                  wrapperCol={{ span: 24 }}
                  layout="vertical"
                  onValuesChange={() => {}}
                  size={"default"}
                >
                  <Form.Item label="Payment amount due (USD)">
                    <Input value={state.amountUSD} disabled />
                  </Form.Item>
                </Form>
                <Row className="note-wrap">
                  <p>
                    <Divider orientation="left"> Note:</Divider>* The amount due includes {fee}%
                    commision fee
                    <br />
                    ** Your USD deposit at Wallex will not be deducted from the payment amount due
                  </p>
                </Row>
              </Col>
              <Col className="mint-col" span={12} offset={2}>
                <Row>
                  <Divider orientation="left">Instruction</Divider>
                  <p style={{ textAlign: "left" }}>
                    1. See the payment instruction in your email
                    <br />
                    2. Complete the wire payment from your bank account in accordance with the
                    instruction
                    <br />
                    3. Check the status of your payment in{" "}
                    <Link to={"/main/transactions"}>the list of transactions</Link>
                    <br />
                  </p>
                  <Divider orientation="left">Statuses</Divider>
                  <div style={{ textAlign: "left" }}>
                    <Badge color="orange" text="Waiting" />
                    <br />
                    <Badge color="green" text="Success" />
                    <br />
                    <Badge color="red" text="Rejected (see the reason in your email)" />
                  </div>

                  <p className="note-wrap">
                    <Divider orientation="left"> Note</Divider>
                    Due to bank wire transfer times, mints may take 1-5 business days to appear
                    after your wire is sent. You will receive an email notification as soon as your
                    wire is received and again when the tokens are minted to your wallet.
                  </p>
                </Row>
                <Row className="wire-payment-btn-wrap" justify={"space-between"}>
                  <Button className="secondary-button" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!state.amountEURST}
                    className="primary-button"
                    loading={loading}
                    onClick={onClickButton}
                  >
                    Proceed to payment
                  </Button>
                </Row>
              </Col>
            </Row>
          </Row>
        </Content>
      </Layout>
      <Modal
        title="Payment Instruction"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Go to transactions
          </Button>,
        ]}
      >
        <p>Instructions have been sent to your email.</p>
        <p>Now you can go to the transactions page and watch the status of your transaction.</p>
      </Modal>
    </React.Fragment>
  );
}

export default withRouter(withKYCApproved(WirePaymentPage));
