import React, { useContext, useState } from "react";
import { Form, Input, Button, Checkbox, Row, Tooltip, Typography, Space } from "antd";
import auth from "../../services/auth";
import { toast } from "react-toastify";
import { Redirect, useHistory } from "react-router-dom";
import "./index.scss";
import Cookies from "js-cookie";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import Card from "antd/lib/card";
import { Context } from "../../store";
import Modal from "antd/es/modal";
import { USER_TYPE } from "eurst-shared/src/enums";

const { Title, Paragraph } = Typography;

const TERMS_OF_USE = (
  <Typography>
    <Title level={2}>EURST TERMS OF USE</Title>
    <Paragraph>
      Welcome to www.eurst.io (the “Website” or “Site”). Please read these Terms of Use our Privacy
      Policy (collectively the “Terms”) carefully, by continuing to access, link to, or use this
      site, or any service on this site, you signify your acceptance of the Term, as described
      further below.
    </Paragraph>
    <Title level={3}>Acceptance of the Terms of Use</Title>
    <Paragraph>
      These terms of use are entered into by and between you and WallexTrust, (“Company,”
      “WallexTrust,” “we,” “our,” or “us”), and they govern your access to and use of the Website,
      including any content, functionality, and services offered on or through the Website, unless a
      different policy is provided on a particular site, application or service, in which case such
      different policy shall govern and control.
    </Paragraph>
    <Paragraph>
      Please read the Terms carefully before you use the Website. By using the Website, or by
      clicking to accept or agree to the Terms of Use when this option is made available to you, you
      accept and agree to be bound and abide by these Terms of Use, our Privacy Policy. If you do
      not agree to these Terms of Use and Privacy Policy do not access or use the Website or any
      services.
    </Paragraph>
    <Title level={3}>Eligibility</Title>
    <Paragraph>
      The Website is only intended for adults eighteen (18) years of age and older. You are not
      allowed to use the Website, or provide information on it if you are under eighteen (18) years
      of age. You further represent that you are not a citizen, resident, or member of any
      jurisdiction or group that is subject to economic sanctions by the United States, or where
      your use of the Website would be illegal or otherwise violate any applicable law. You further
      represent that your access and use of the Website will fully comply with all applicable laws
      and regulations, and that you will not access or use the Website to conduct, promote, or
      otherwise facilitate any illegal activity.
    </Paragraph>
    <Title level={3}>Changes to the Terms of Use and Website</Title>
    <Paragraph>
      We will likely change and update these Terms from time to time, and these changes are
      effective as soon as we post them on the Website. We reserve the right, in our sole
      discretion, to modify this Agreement from time to time. If you continue to use the Website
      following the posting of any revised Terms, you are accepting and agreeing to the changes.
    </Paragraph>
    <Paragraph>
      We may also update the content on the Website as we see fit, but do not guarantee that any
      content is necessarily complete or up-to-date.
    </Paragraph>
    <Title level={3}>No Class Proceedings</Title>
    <Paragraph>
      You and we agree that any party hereto may bring claims against the other on an individual
      basis only and not as a plaintiff or class member in any purported class or representative
      action or proceeding. Unless the parties agree otherwise, any adjudicator of any claim—whether
      judicial or otherwise—may not consolidate or join more than one party’s claims and may not
      otherwise preside over any form of a consolidated, representative, or class proceeding. Any
      adjudicator of any claim may award relief, including monetary, injunctive, and declaratory
      relief, only in favor of the party seeking relief, and only to the extent necessary to provide
      relief necessitated by that party’s claim(s). Any relief awarded cannot affect other users of
      the Website or of any Services.
    </Paragraph>
    <Title level={3}>Taxes</Title>
    <Paragraph>
      You are solely liable for all the taxes, fees, and commissions resulting from the use of the
      Website. These Terms of Use alone do not create a joint venture, partnership, or
      principal-agent relationship between you and the Company, or any other users, and nothing in
      these Terms of Use may be used to imply such a relationship. You agree to indemnify the
      Company from any liability for, or assessment of, any claims or penalties with respect to such
      taxes, labor, or employment requirements, including any liability for, or assessment of taxes
      imposed on the Company by the relevant taxing authorities with respect to any fees paid to you
      as the result of using the Website.
    </Paragraph>
    <Title level={3}>Assumption of Risk; No Advice</Title>
    <Paragraph>
      Cryptocurrency markets are volatile and shift quickly in terms of liquidity, market depth, and
      trading dynamics. You are solely responsible and liable for knowing the true status of your
      digital assets.
    </Paragraph>
    <Paragraph>
      The Company does not provide investment advice. The information contained within the Website
      is for informational purposes only, and the Company is not soliciting any action based upon
      such materials. The material is not to be construed as investment advice. We do not provide
      any consultation on the advisability of lending or borrowing digital assets, trading
      techniques, models, algorithms, or any other schemes.
    </Paragraph>
    <Paragraph>
      To the fullest extent permitted by law, you acknowledge and agree that we owe no fiduciary
      duties or liabilities to you or any other party, and that to the extent any such duties or
      liabilities may exist at law or in equity, those duties and liabilities are hereby irrevocably
      disclaimed, waived, and eliminated. You further agree that the only duties and obligations
      that we owe you are those set out expressly in these Terms.
    </Paragraph>
    <Title level={3}>Assumption of Risk</Title>
    <Paragraph>
      By accessing and using the Website, you represent that you understand the inherent risks
      associated with using cryptographic and blockchain-based systems, and that you have a working
      knowledge of the usage and intricacies of digital assets such as bitcoin (BTC), ether (ETH),
      and other digital tokens such as those following the Ethereum Token Standard (ERC-20). You
      further understand that the markets for these digital assets are highly volatile due to
      factors including (but not limited to) adoption, speculation, technology, security, and
      regulation. You acknowledge that the cost and speed of transacting with cryptographic and
      blockchain-based systems such as Ethereum are variable and may increase dramatically at any
      time. You further acknowledge the risk that your digital assets may lose some or all of their
      value while they are lent through the Protocol. You further acknowledge that we are not
      responsible for any of these variables or risks, and cannot be held liable for any resulting
      losses that you experience while accessing or using the Website. Accordingly, you understand
      and agree to assume full responsibility for all of the risks of accessing and using the
      Website and interacting with the Protocol.
    </Paragraph>
    <Title level={3}>Feedback</Title>
    <Paragraph>
      In the event that you provide us any ideas, thoughts, criticisms, suggested improvements, or
      other feedback related to Website (collectively “​Feedback​”), you agree that we may use the
      Feedback in any way we deem appropriate to: (a) improve our Website and (b) promote the
      Website, and that you will not be due any compensation for your Feedback that is used in these
      ways.
    </Paragraph>
    <Title level={3}>Intellectual Property Rights</Title>
    <Paragraph>
      The Website and its entire contents, features, and functionality (including but not limited to
      all information, software, text, displays, images, video and audio, and the design, selection,
      and arrangement thereof), are owned by the Company, its licensors, or other providers of such
      material and are protected by United States and international copyright, trademark, patent,
      trade secret, and other intellectual property or proprietary rights laws.
    </Paragraph>
    <Paragraph>
      These Terms of Use permit you to use the Website for your use only, subject to the following
      restrictions:
      <ul>
        <li>
          You must not reproduce, distribute, modify, create derivative works of, publicly display,
          publicly perform, republish, download, store, or transmit any of the material on the
          Website, except as it is created and owned by you.
        </li>
        <li>
          You must not delete or alter any copyright, trademark, or other proprietary rights notices
          from copies of materials from this site.
        </li>
        <li>
          No right, title, or interest in or to the Website or any content on the Website is
          transferred to you, and all rights not expressly granted are reserved by the Company.
        </li>
      </ul>
    </Paragraph>
    <Title level={3}>Prohibited Uses</Title>
    <Paragraph>
      You may use the Website only for lawful purposes and in accordance with these Terms of Use.
      You agree not to use the Website:
      <ul>
        <li>
          In any way that violates any applicable federal, state, local, or international law or
          regulation (including, without limitation, any laws regarding the export of data or
          software to and from the US or other countries).
        </li>
        <li>
          For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way
          by exposing them to inappropriate content, asking for personally identifiable information,
          or otherwise.
        </li>
        <li>
          To impersonate or attempt to impersonate the Company, a Company employee, another user, or
          any other person or entity.
        </li>
        <li>
          In any way that infringes upon the rights of others, or in any way is illegal,
          threatening, fraudulent, or harmful, or in connection with any unlawful, illegal,
          fraudulent, or harmful purpose or activity.
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the
          Website, or which, as determined by us, may harm or offend the Company or users of the
          Website or expose them to liability.
        </li>
        <li>
          By means of any robot, bot spider, or other automatic device or process, or by any manual
          process, intended to monitor or copy any of material on the Website or for any other
          unauthorized purpose.
        </li>
        <li>
          In order to disguise the proceeds of, or to further, any breach of applicable laws or
          regulations, or to deal in any contraband digital assets, funds, or proceeds.
        </li>
        <li>
          To use any funds or other value on the Website, or use any Services, with anything other
          than funds, keys, or digital assets that have been legally obtained by you and that belong
          to you.
        </li>
        <li>
          To interfere with or subvert our rights or obligations or the rights or obligations of any
          other Website customer or any other third party;
        </li>
        <li>
          To engage in conduct that is detrimental to us or to any other Website customer or any
          other third party;
        </li>
        <li>
          To falsify or materially omit any information or provide misleading information requested
          by us in the course of, directly or indirectly relating to, or arising from your
          activities on the Website or the use of any Services, including at registration;
        </li>
        <li>
          To reverse-engineer, decompile, or disassemble any software running on the Website; or,
          attempt to harm us through your access to the Website or any Services, except that nothing
          in this subparagraph shall be construed as limiting your free speech rights under
          applicable law.
        </li>
      </ul>
    </Paragraph>
    <Title level={3}>Reliance on Information Posted</Title>
    <Paragraph>
      We only collect personal information that we believe to be relevant and required to conduct
      our business. We may share your personal information with our business partners and service
      providers, but only to the extent that they each need to know specific information to continue
      to provide the Website or services to you. This includes:
    </Paragraph>
    <Paragraph>
      The Website includes content provided by third parties, including materials provided by other
      users, attorneys, third-party licensors, syndicators, aggregators, and/or reporting services.
      All statements and/or opinions expressed in these materials, other than the content provided
      by the Company, are solely the opinions and the responsibility of the person or entity
      providing those materials. We are not responsible, or liable to you or any third party, for
      the content or accuracy of any materials provided by any third parties.
    </Paragraph>
    <Title level={3}>Links from the Website</Title>
    <Paragraph>
      Links to any third-party websites on the Website are provided for your convenience only. We
      have no control over the contents of those sites or resources and such links are not an
      endorsement of any information, product or service that is offered on or reached through such
      third-party websites. If you decide to access any of the third-party websites linked to the
      Website, you do so entirely at your own risk and subject to the terms and conditions of use
      for such websites.
    </Paragraph>
    <Title level={3}>Users Outside the U.S.</Title>
    <Paragraph>
      The Website is controlled and operated from the United States and is subject to its laws. If
      you choose to access the Website outside of the United States, you do so at your own risk and
      are responsible for complying with all applicable laws, rules, and regulations.
    </Paragraph>
    <Title level={3}>Release of Claims</Title>
    <Paragraph>
      You expressly agree that you assume all risks in connection with your access and use of the
      Website and your interaction with the Protocol. You further expressly waive and release us
      from any and all liability, claims, causes of action, or damages arising from or in any way
      relating to your use of the Website and your interaction with the Protocol.
    </Paragraph>
    <Title level={3}>Disclaimer of Warranties</Title>
    <Paragraph>
      YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS
      AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY
      KIND, EITHER EXPRESS OR IMPLIED.
    </Paragraph>
    <Paragraph>
      NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
      REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
      AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
      ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
      UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER THAT MAKES IT
      AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE, OR ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
    </Paragraph>
    <Paragraph>
      THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
      STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
      NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
    </Paragraph>
    <Paragraph>
      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
      APPLICABLE LAW.
    </Paragraph>
    <Title level={3}>Limitation on Liability</Title>
    <Paragraph>
      IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS,
      EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
      THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
      WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE, TRUECURRENCY TOKENS, OR ANY SERVICES OR
      ITEMS OBTAINED THROUGH THE WEBSITE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
      SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS, OR
      ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
      (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
    </Paragraph>
    <Paragraph>
      IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES,
      AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY
      PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE) EXCEED THE
      AMOUNT YOU HAVE PAID TO THE COMPANY FOR THE APPLICABLE SERVICES IN THE LAST 12 MONTHS OUT OF
      WHICH LIABILITY AROSE.
    </Paragraph>
    <Paragraph>
      THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
      APPLICABLE LAW.
    </Paragraph>
    <Title level={3}>Indemnification</Title>
    <Paragraph>
      You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and
      service providers, and its and their respective officers, directors, employees, contractors,
      agents, licensors, suppliers, successors, and assigns from and against any claims,
      liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including
      reasonable attorneys’ fees) (collectively “Claims”) arising out of or relating to: (i) your
      violation of these Terms of Use; or (ii) your use of the Website.
    </Paragraph>
    <Title level={3}>Governing Law</Title>
    <Paragraph>
      All matters relating to the Website and these Terms and any dispute or claim arising therefrom
      or related thereto shall be governed by the internal laws of the District of Columbia without
      giving effect to any choice or conflict of law provisions or rules.
    </Paragraph>
    <Title level={3}>Arbitration</Title>
    <Paragraph>
      At the Company’s sole discretion, it may require you to submit any disputes arising from the
      use of these Terms of Use or the Website, including disputes arising from or concerning their
      interpretation, violation, invalidity, non-performance, or termination, to final and binding
      arbitration under the Rules of Arbitration of the American Arbitration Association applying
      District of Columbia law.
    </Paragraph>
    <Title level={3}>Waiver and Severability</Title>
    <Paragraph>
      No waiver by the Company of any term or condition set forth in these Terms shall be deemed a
      further or continuing waiver of such term or condition, and any failure of the Company to
      assert a right or provision under these Terms shall not constitute a waiver of such right or
      provision.
    </Paragraph>
    <Paragraph>
      If any provision of these Terms is held by a court or other tribunal of competent jurisdiction
      to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or
      limited to the minimum extent such that the remaining provisions of the Terms will continue in
      full force and effect.
    </Paragraph>
    <Title level={3}>Entire Agreement</Title>
    <Paragraph>
      The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you
      and the Company. with respect to the Website and supersedes all prior and contemporaneous
      understandings, agreements, representations and warranties, both written and oral, with
      respect to the Website.
    </Paragraph>
    <Title level={3}>Your Comments and Concerns</Title>
    <Paragraph>
      All feedback, comments, requests for technical support and other communications relating to
      the Website should be directed to: support@eurst.io.
    </Paragraph>
  </Typography>
);
const PRIVACY_POLICY = (
  <Typography>
    <Title level={2}>EURST Privacy Policy</Title>
    <Title level={3}>Guidelines and Resources</Title>
    <Paragraph>
      Wallex Trust (“Company,” “our,” “we”) understands the importance of privacy, especially when
      it comes to your personal information. Data privacy and your trust are our top priorities, and
      in this Privacy Policy we explain what information we collect from you when you use
      https://eurst.io and subdomains of https://eurst.io, (collectively, the “Website”), how we
      will use this information, and the very limited ways that we may share your information
    </Paragraph>
    <Paragraph>
      Please read this policy carefully to understand our policies and practices regarding your
      information, as you agree to this Privacy Policy by using the Website. ​If you do not agree
      with our policies and practices, do not use the Website. This Privacy Policy is part of our
      Terms of Use available at https://eurst.io.
    </Paragraph>
    <Title level={3}>Users Under Age 18</Title>
    <Paragraph>
      The Website is only intended for adults eighteen (18) years of age and older. You are not
      allowed to use the Website or provide information on it if you are under eighteen (18) years
      of age.
    </Paragraph>
    <Paragraph>
      The Website is not directed at children under age thirteen (13), and therefore if the Company
      receives any information about a child under thirteen (13), the Company will assume that such
      information is being given with parental consent. If you believe we might have any information
      from or about a child under thirteen (13) without parental consent, please contact us
      immediately at legal@eurst.io.
    </Paragraph>
    <Title level={3}>Information We Collect About You</Title>
    <Paragraph>
      We collect several types of information from and about users of the Website, including:
    </Paragraph>
    <Paragraph>
      Information about your internet connection, the equipment you use to access the Website, data
      associated with usage of Google Analytics and Website usage details, as well as information
      you may provide to us, such as your email address.
    </Paragraph>
    <Space></Space>
    <Title level={3}>How We Collect Information From You</Title>
    <Paragraph>We collect information from you in two ways:</Paragraph>
    <Paragraph>
      <ul>
        <li>
          First, we collect information directly from you when you provide it to us, such as or
          entering your email address to sign up for our newsletter.
        </li>
        <li>
          Second, we collect generic information about your usage and equipment automatically as you
          navigate through the Website, such as usage details, IP addresses, web browser
          preferences, and information collected through cookies and other tracking technologies.
          Although we do not correlate tracking information to individuals, some information
          collected, such as IP addresses, will be unique.
        </li>
      </ul>
    </Paragraph>
    <Title level={3}>How We Collect Information From You</Title>
    <Paragraph>
      We do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism
      is in place.
    </Paragraph>
    <Title level={3}>How We Use Your Information</Title>
    <Paragraph>
      We use information that we collect about you or that you provide to us, including personal
      information:
    </Paragraph>
    <Paragraph>
      <ul>
        <li>To continue the daily operation of the Website.</li>
        <li>
          To meet the requirements to make disclosures under the requirements of any law binding on
          the Website under and for the purposes of any guidelines issued by regulatory or other
          authorities with which the Website is expected to comply.
        </li>
        <li>To provide you with information, products, or services that you request from us.</li>
        <li>
          To notify you about changes to the Website or any products or services we offer or provide
          through it.
        </li>
        <li>In any other way we may describe when you provide the information.</li>
      </ul>
    </Paragraph>
    <Title level={3}>Disclosure of Your Information</Title>
    <Paragraph>
      We only collect personal information that we believe to be relevant and required to conduct
      our business. We may share your personal information with our business partners and service
      providers, but only to the extent that they each need to know specific information to continue
      to provide the Website or services to you. This includes:
    </Paragraph>
    <Paragraph>
      <ul>
        <li>
          Our contractors, employees, service providers, and other third parties that we use to
          support our business and improve the Website;
        </li>
        <li>
          To a buyer or other successor in the event of a merger or reorganization if the personal
          information of our users is part of the assets transferred;
        </li>
        <li>
          To comply with any court order, law, or legal process, including to respond to any
          government or regulatory request;
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the rights, property, or
          safety of the Website, our users, or others;
        </li>
        <li>For any other purpose disclosed by us when you provide the information.</li>
      </ul>
    </Paragraph>
    <Paragraph>
      We may disclose aggregated information about our users, which means information that does not
      identify any individual, without restriction. As an example, we may include in our marketing
      materials that a certain percentage of our users are female, but we won’t share a list of all
      female users in these materials.
    </Paragraph>
    <Title level={3}>Accessing and Correcting Your Information</Title>
    <Paragraph>
      You may send us an email at support@eurst.io to request access to, correct, or delete any
      personal information that you have provided to us. We may not accommodate a request to change
      information if we believe the change would violate any law or legal requirement or cause the
      information to be incorrect.
    </Paragraph>
    <Title level={3}>Data Security</Title>
    <Paragraph>
      We have implemented measures designed to secure your personal information from accidental loss
      and from unauthorized access. For example, access by a user to his or her personal information
      is available through a unique customer ID and password selected by the user that should be
      regularly updated. This information is encrypted through the Website with Secure Socket Layer
      technology (SSL) and is also encrypted when it is stored by us to prevent unauthorized parties
      from viewing such information. Also, we perform regular malware scanning of the Website and
      all servers and computers used by us to support the Website. All Company employees are
      required to adhere to our security and confidentiality procedures and undergo training related
      to maintaining the security of user personal information.
    </Paragraph>
    <Paragraph>
      The safety and security of your information also depends on you. Where you have chosen a
      password for access to certain parts of the Website, you are responsible for keeping this
      password confidential. We ask you not to share your password with anyone and be careful about
      giving out information to other users on the Website if requested.
    </Paragraph>
    <Paragraph>
      Unfortunately, the transmission of information via the internet is not completely secure.
      Although we do our best to protect your personal information, we cannot guarantee the security
      of your personal information transmitted to the Website. Any transmission of personal
      information is at your own risk. We are not responsible for circumvention of any privacy
      settings or security measures contained on the Website.
    </Paragraph>
    <Title level={3}>Third Party Links</Title>
    <Paragraph>
      The Website may contain links that lead to other websites, and the Company is not responsible
      for the privacy practices, content, and/or activities of these linked websites. Nonetheless,
      we seek to protect the integrity of the Website and welcome any feedback about these external
      websites.
    </Paragraph>
    <Title level={3}>Changes to Our Privacy Policy, if any.</Title>
    <Paragraph>
      We will post any changes we make to our privacy policy on this page and you will be notified
      of any material changes on the Website home page and in an email to the most recent email
      address that you provided to us, if any.
    </Paragraph>
    <Title level={3}>Contact Information</Title>
    <Paragraph>
      To ask questions or comment about this Privacy Policy and our privacy practices, contact us at
      legal@eurst.io.
    </Paragraph>
  </Typography>
);

function RegistrationEntityPage() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [state] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const [terms, setTerms] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  if (!state.countryCode) {
    return <Redirect to="/check-country" />;
  }

  const onFinish = async (values) => {
    values.countryCode = Cookies.get("countryCode");
    values.userName = values.companyName;
    values.type = USER_TYPE.company;

    setLoading(true);
    try {
      const registrationResponse = await auth.registration(values);
      if (registrationResponse.status === 200) {
        toast(
          "Congratulations! You have successfully registered an account. Please check your mailbox to complete your registration and verify your account.",
          {
            type: "success",
            position: "bottom-right",
          }
        );
        history.push("/");
      }
    } catch (error) {
      console.error("registration entity page", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickCancel = () => {
    history.goBack();
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <React.Fragment>
      <div className="login-wrap registration-wrap">
        <h1 className="registration-header">Create your account</h1>
        <Card className="login-card">
          <Form
            form={form}
            layout={"vertical"}
            size={"large"}
            name="register"
            onFieldsChange={handlerFieldsChange}
            onFinish={onFinish}
            initialValues={{}}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
                {
                  required: true,
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input placeholder="E-mail" autoComplete={false} />
            </Form.Item>

            <Form.Item
              name="companyName"
              label={<span>Create Company Name</span>}
              rules={[
                {
                  required: true,
                  message: "Please enter a Company Name!",
                  whitespace: true,
                },
                {
                  max: 80,
                  message: "This field should be less than 80 symbols!",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Create Company Name" autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Create Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 8,
                  message: "Password must be more than 8 symbols!",
                },
                {
                  pattern: /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]/,
                  message: "Password is not valid!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={
                  <Tooltip
                    title={
                      <div>
                        <span>Password must:</span>
                        <ol>
                          <li>be eight or more characters long.</li>
                          <li>
                            contain:
                            <ul style={{ paddingLeft: "10px" }}>
                              <li>Uppercase characters A-Z (Latin alphabet);</li>
                              <li>Lowercase characters a-z (Latin alphabet);</li>
                              <li>Digits 0-9;</li>
                              <li>Special characters (!, $, #, %, etc.).</li>
                            </ul>
                          </li>
                        </ol>
                      </div>
                    }
                  >
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
                placeholder="Create Password"
                autoComplete="new-password"
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                {
                  min: 8,
                  message: "Confirm password must be more than 8 symbols!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Passwords do not match!");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" autoComplete="off" />
            </Form.Item>

            <Form.Item
              className="agreement-check-wrap"
              name="agreement"
              valuePropName="checked"
              rules={[
                { required: true, message: "Should accept agreement---" },
                {
                  validator: (_, value) => {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
            >
              <Checkbox>
                I have read the{" "}
                <a
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    setTerms(true);
                    setVisible(true);
                  }}
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    setTerms(false);
                    setVisible(true);
                  }}
                >
                  Privacy Policy
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item shouldUpdate={true}>
              <Row>
                <Button onClick={onClickCancel} className="secondary-button">
                  Cancel
                </Button>
                <Button
                  className="primary-button"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  disabled={disabled}
                >
                  Register
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <Modal
        title={terms ? "Terms of Use" : "Privacy Policy"}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        {terms ? TERMS_OF_USE : PRIVACY_POLICY}
      </Modal>
    </React.Fragment>
  );
}

export default RegistrationEntityPage;
