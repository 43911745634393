// modules & hooks
import React from "react";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../constants";
// components
import { ResetPasswordForm } from "../components/ResetPasswordComponents";
// styles
import "../styles/ScreensStyles/ResetPasswordStyles/index.scss";

const ResetPasswordPage = () => {
  // window size
  const windowSize = useWindowSize();

  return (
    <div
      className={`reset-password ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "container-fluid"
      }`}>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPasswordPage;
