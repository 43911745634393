import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import Card from "antd/lib/card";
import Row from "antd/lib/grid/row";
import Button from "antd/lib/button";
import Select from "antd/lib/select";
import CustomSelect from "../../components/CustomSelect";
import { getCountries } from "../../services/countries";
import "./index.scss";
import { Context } from "../../store";

const { Option } = Select;

function CheckCountryPage() {
  const history = useHistory();
  // eslint-disable-next-line
  const [state, dispatch] = useContext(Context);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      history.push("/main/dashboard");
    } else {
      (async () => {
        try {
          const data = await getCountries();

          setCountries(data.filter((item) => item.available));
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [history]);

  const onClickRegistration = () => {
    history.push("/choose-acc-type");
  };

  const onClickCancel = () => {
    history.goBack();
  };

  function onChange(value) {
    setCountryCode(value);
    Cookies.set("countryCode", value);
    dispatch({ type: "SET_COUNTRY_CODE", payload: value });
  }

  return (
    <div className="login-wrap">
      <h1 className="registration-header">Check service availability in your country</h1>
      <Card className="login-card" type={"inner"}>
        <Row>
          <CustomSelect placeholder="Select a country" onChange={onChange}>
            {countries.map((item, index) => (
              <Option value={item.code} key={index} disabled={!item.available}>
                {item.name}
              </Option>
            ))}
          </CustomSelect>
        </Row>
        <Row className="btn-wrap">
          <Button className="secondary-button" onClick={onClickCancel}>
            Cancel
          </Button>
          <Button
            className="primary-button"
            disabled={!countryCode}
            type="primary"
            onClick={onClickRegistration}
          >
            Continue
          </Button>
        </Row>
      </Card>
    </div>
  );
}

export default CheckCountryPage;
