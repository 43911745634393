/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Layout, Col, Row, Divider, Breadcrumb } from "antd";
import WallexLogo from "../../logo_wallex.svg";
import DollarCircleOutlined from "@ant-design/icons/lib/icons/DollarCircleOutlined";
import { getUser } from "../../services/users";
import { getDeposit } from "../../services/wallet";
import { Context } from "../../store";
import { calculationOfDecimalNumbers } from "eurst-shared/src/helpers/math";
import { Link, useHistory } from "react-router-dom";
import Spin from "antd/lib/spin";
import withKYCApproved from "../../hocs/withKYCApproved";
import { flatten, unflattenObject } from "eurst-shared/src/helpers/object";

const { Content } = Layout;

const objectToForm = (serverValues) => flatten(serverValues);

const formToServerObject = (formValues, delimiter = ".") => unflattenObject(formValues, delimiter);

function WithdrawPage() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [blockNumber, setBlockNumber] = React.useState(null);
  const [wallet, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [balance, setBalance] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [usd, setUsd] = useState("");
  const [eurst, setEurst] = useState("");

  useEffect(async () => {
    setLoading(true);
    await fetchUserData();
    await fetchDeposit();
    setLoading(false);
  }, []);

  const { fee, feeAsOne, minWithdrawAmount, maxWithdrawAmount } = state.ownerSettings;
  // const PERCENTAGE = 1.02;

  const onClickButton = () => {
    dispatch({ type: "SET_WITHDRAW_AMOUNT", payload: form.getFieldValue("amount") });
    history.push("/main/bank-details");
  };

  const fetchUserData = async () => {
    const data = await getUser();
    setBalance(data.data.balance ? data.data.balance : null);
    setWallet(data.data.ethAddress ? data.data.ethAddress : null);
    setStatus(data.data.status ? data.data.status : null);
  };

  const fetchDeposit = async () => {
    const {
      data: {
        deposit: { disbursable },
      },
    } = await getDeposit();
    setDeposit(disbursable);
  };

  const onFieldsChange = (changedField, allFields) => {
    const hasErrors = allFields.some(function (field) {
      return field.errors.length > 0;
    });
    if (hasErrors) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const onChange = (fieldName) => {
    return (event) => {
      const { value } = event.target;

      if (["amount"].includes(fieldName)) {
        let updateField = {};

        const newValue = value.replace(/[^\d.]+/g, "");
        const numbersAfterDot = calculationOfDecimalNumbers(newValue);

        if (value === " ") {
          updateField[fieldName] = "";
        } else {
          updateField[fieldName] = numbersAfterDot > 2 ? newValue.slice(0, -1) : newValue;
        }

        form.setFieldsValue(updateField);
      }
    };
  };

  const onChangeUSD = (event) => {
    const normalaizeValue = event.target.value;
    const numbersAfterDot = calculationOfDecimalNumbers(normalaizeValue);
    if (
      (normalaizeValue != 0 && !Number(normalaizeValue) && normalaizeValue.length > 0) ||
      numbersAfterDot > 2
    ) {
      return;
    }
    setUsd(normalaizeValue);
  };

  const breadcrumbItems = [
    <Breadcrumb.Item key="dashboard">
      <Link to="/main/dashboard">Dashboard</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="withdraw">
      <Link to="/main/withdraw">Withdraw</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <React.Fragment>
      <Spin tip="Loading..." spinning={loading}>
        <Layout className="mint-wrap">
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
          <Content>
            <Row>
              <Col className="mint-col" span={12} offset={2}>
                <Divider orientation="left">Enter USD amount to withdraw</Divider>
                <Form
                  form={form}
                  layout="vertical"
                  onFieldsChange={(changedFields, allFields) =>
                    onFieldsChange(changedFields, allFields)
                  }
                  size={"default"}
                >
                  <Form.Item
                    name={"amount"}
                    label="Amount"
                    rules={[
                      {
                        required: true,
                        message: "This field cannot be empty!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          const amount = parseFloat(value);
                          if (
                            isNaN(amount) ||
                            (amount <= deposit &&
                              amount >= minWithdrawAmount &&
                              amount <= maxWithdrawAmount)
                          ) {
                            return Promise.resolve();
                          }
                          if (amount < minWithdrawAmount) {
                            return Promise.reject(
                              `Minimum amount to withdraw is ${minWithdrawAmount}$`
                            );
                          }
                          if (amount > maxWithdrawAmount) {
                            return Promise.reject(
                              `Maximum amount to withdraw is ${maxWithdrawAmount}$`
                            );
                          }
                          return Promise.reject("Insufficient balance");
                        },
                      }),
                    ]}
                  >
                    <Input suffix="USD" value={usd} placeholder="0" onChange={onChange("amount")} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="primary-button"
                      disabled={disabled}
                      onClick={onClickButton}
                      type="primary"
                    >
                      Confirm
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col className="mint-col" span={12} offset={2}>
                <Divider orientation="left">EURST balance</Divider>
                <Row justify={"space-between"}>
                  <div className="mint-row-logo">
                    <div className="logo-wrap">
                      <img src={WallexLogo} alt="logo" />
                    </div>
                    <Input disabled value={balance} placeholder="0" />
                  </div>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Input value={wallet} disabled />
                </Row>
                <Divider orientation="left">USD deposit at Wallex</Divider>
                <Row justify={"space-around"}>
                  <div className="mint-row-logo">
                    <div className="logo-wrap">
                      <DollarCircleOutlined style={{ fontSize: "60px" }} />
                    </div>
                    <Input disabled value={deposit} placeholder="0" />
                  </div>
                </Row>
                <Row className="note-wrap">
                  <Divider orientation="left"> Note</Divider>
                  <p style={{ textAlign: "left" }}>
                    * Your can withdraw your USD deposit at Wallex to your bank account;
                    <br />
                    the wire transfer fee up to 35 USD will be applicable
                    <br />
                    ** USD amount should be between {minWithdrawAmount} and {maxWithdrawAmount}
                    <br />
                    *** Due to bank wire transfer times, the withdrawal process may take 1-5
                    business days before the USD is credited to your bank account your USD deposit
                    will be frozen untill the settlement;
                  </p>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </React.Fragment>
  );
}

export default withKYCApproved(WithdrawPage);
