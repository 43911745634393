import React, { useEffect, useRef, useState } from "react";
import Icon from "./Icon";
import { getCountries } from "../../services/countries";

export default function SelectCountry({
  name,
  handleCountryChange,
  errorValue,
  markAsValid = () => {},
}) {
  const [value, setValue] = useState("");
  const [countries, setCountries] = useState([]);
  const [countriesPtr, setCountriesPtr] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const timeout = useRef(null);
  const [message, setMessage] = useState("");
  const [valid, setValid] = useState(null);
  const dropDownRef = useRef(null);

  window.addEventListener("click", (e) => {
    if (!e.target.closest(".select-country")) {
      if (dropDownRef.current) {
        dropDownRef.current.classList.remove("active");
      }
    }
  });

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res);
        setCountriesPtr(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e) => {
    e.persist();
    setValue(e.target.value);
    if (e.target.value) {
      if (
        countriesPtr
          .map((country) => {
            return country.name.toLowerCase();
          })
          .indexOf(e.target.value.trim().toLowerCase()) === -1
      ) {
        setMessage("Selected Country is invalid.");
        markAsValid(false);
      } else {
        markAsValid(true);
      }
    } else {
      setMessage("Country is required.");
      markAsValid(false);
    }
  };

  const handleInputFoucs = (e) => {
    if (dropDownRef.current) {
      dropDownRef.current.classList.add("active");
    }
  };

  const handleCountryClick = (country) => {
    setValue(country.name);
    if (dropDownRef.current) {
      dropDownRef.current.classList.remove("active");
      handleCountryChange(country);
      clearTimeout(timeout.current);
    }
  };

  useEffect(() => {
    if (value.trim()) {
      const debounce = () => {
        if (!timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
          let oldCountries = [...countriesPtr];
          oldCountries = oldCountries.filter((country) => {
            return country.name.toLowerCase().includes(value.trim().toLowerCase());
          });
          setCountries(oldCountries);
          if (dropDownRef.current) {
            dropDownRef.current.classList.add("active");
          }
        }, 400);
      };
      debounce();
    } else {
      setCountries(countriesPtr);
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [value]);

  useEffect(() => {
    if (
      value.trim() &&
      typeof valid === "boolean" &&
      countriesPtr
        .map((country) => {
          return country.name.toLowerCase();
        })
        .indexOf(value.trim().toLowerCase()) !== -1
    ) {
      setValid(true);
    } else if (typeof valid === "boolean") {
      setValid(false);
    }
    if (value.trim()) {
      if (
        countriesPtr
          .map((country) => {
            return country.name.toLowerCase();
          })
          .indexOf(value.trim().toLowerCase()) === -1
      ) {
        setMessage("Selected Country is invalid.");
        markAsValid(false);
      } else {
        markAsValid(true);
      }
    } else {
      setMessage("Country is required.");
      markAsValid(false);
    }
  }, [value]);

  return (
    <div className="select-country">
      <label htmlFor={name} className="form-label">
        Select Country&nbsp; <span style={{ color: "red" }}>*</span>
      </label>
      <input
        autoComplete="off"
        className={`${
          errorValue || (typeof valid === "boolean" && !valid)
            ? "invalid"
            : typeof valid === "boolean" && valid
            ? "success"
            : ""
        } form-control select-country__input`}
        type="text"
        placeholder="Select a Country"
        name={name}
        id={name}
        onChange={handleInputChange}
        value={value}
        onClick={handleInputFoucs}
        onBlur={() => {
          if (!value.trim()) {
            setMessage("Country is required.");
            setValid(false);
          } else {
            if (
              countriesPtr
                .map((country) => {
                  return country.name.toLowerCase();
                })
                .indexOf(value.trim().toLowerCase()) === -1
            ) {
              setMessage("Selected Country is invalid.");
              setValid(false);
            } else {
              setValid(true);
            }
          }
        }}
      />
      {errorValue || (typeof valid === "boolean" && !valid) ? (
        <div className="invalid-feedback">{message}</div>
      ) : null}
      <div
        className={`select-country__icon ${typeof valid === "boolean" ? "active" : ""}`}
        style={{ transform: showDropDown ? "rotate(180deg)" : "rotate(0deg)" }}>
        <label htmlFor={name}>
          <Icon size={25} icon="keyboard_arrow_down" />
        </label>
      </div>
      {typeof valid === "boolean" ? (
        <div className="validity-icons">
          {valid ? (
            <div className="valid-icon-country">
              <Icon icon="check" size={22} />
            </div>
          ) : (
            <div className="invalid-icon-country">
              <Icon icon="highlight_remove" size={22} />
            </div>
          )}
        </div>
      ) : null}

      {countries.length > 1 ? (
        <ul className="select-country__select" ref={dropDownRef}>
          {countries.map((country) => {
            return (
              <li
                onClick={handleCountryClick.bind(null, country)}
                key={country.code}
                className={`${!country.available ? "disabled" : ""}`}>
                {country.name}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
}
