import React from "react";
import { PT_DOCUMENT_TYPES } from "eurst-shared/src/enums";

const BackSideMessage4Upload = ({ docType }) => {
  const withBackside = PT_DOCUMENT_TYPES.filter((r) => r.id === docType && r.backside);

  if (withBackside.length > 0) {
    return <p className="ant-upload-hint">Please, upload both sides of the document.</p>;
  }
  return null;
};

export default BackSideMessage4Upload;
