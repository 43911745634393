// modules
import React from "react";
// components
import BasicInfo from "./BasicInfo";
import KYCInfo from "./KYCInfo";
import UploadedDocs from "./UploadedDocs";
import WalletInfo from "./WalletInfo";

const InfoBox = ({ infoCategory, basicInfo, kycInfo, walletInfo, uploadDoc }) => {
  return (
    <div className="infoBox">
      {infoCategory === "Basic Information" ? (
        <BasicInfo data={basicInfo} />
      ) : infoCategory === "KYC Information" ? (
        <KYCInfo data={kycInfo} />
      ) : infoCategory === "Wallet Information" ? (
        <WalletInfo data={walletInfo} />
      ) : infoCategory === "Uploaded Documents" ? (
        <UploadedDocs data={uploadDoc} />
      ) : null}
    </div>
  );
};

export default InfoBox;
