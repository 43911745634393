/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Row, Layout, Form, Input, Button, DatePicker, Col, Select, Divider } from "antd";
import { Context } from "../../store";
import { US_STATES, GENDER } from "../../constants/constants";
import { USER_STATUSES_AS_OBJECT } from "eurst-shared/src/enums";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import { getCountries } from "../../services/countries";
import "react-phone-input-2/lib/style.css";
import {
  savePersonalDataToLs,
  getPersonalInfo,
  getPersonalDataFromLs,
  setKycStatus,
} from "../../services/users";
import "./style.scss";
import CustomSelect from "../../components/CustomSelect";
const { Content } = Layout;

const { Option } = Select;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
};

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

function PersonalDetailsPage() {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  if (
    state.user &&
    ![
      USER_STATUSES_AS_OBJECT.kycWaiting,
      USER_STATUSES_AS_OBJECT.kycFilling,
      USER_STATUSES_AS_OBJECT.kycVerification,
    ].includes(state.user.status)
  ) {
    return <Redirect to="/main/dashboard" />;
  }

  const onFinish = async (values) => {
    values.dateOfBirth = moment(values.dateOfBirth).format("YYYY-MM-DD");
    const personalInfo = { ...values };
    const residenceInfo = {
      address: {
        ...values,
      },
    };

    savePersonalDataToLs({ personalInfo, residenceInfo });

    const updatedUser = await setKycStatus();

    dispatch({ type: "SET_USER", payload: updatedUser.data });

    history.push("/upload-kyc");
  };

  const checkIfAllFieldsFilled = (formFields) => {
    const formFieldValues = form.getFieldsValue();
    const filled = Object.keys(formFieldValues).filter((key) => {
      if (formFieldValues[key]) {
        return true;
      }
      return false;
    });
    if (filled.length === Object.keys(formFieldValues).length) return true;

    return false;
  };

  const onCancel = () => {
    history.goBack();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields[0].name[0] === "countryOfResidence") {
      setCountryCode(changedFields[0].value);
    }
    if (changedFields[0].name[0] === "firstName") {
      changedFields[0].value = "";
    }
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const disabledDate = (current) => {
    const start = moment("1900-01-01", "YYYY-MM-DD");
    return current < start || current > moment();
  };

  const onValuesChange = (changedFields, allFields) => {};

  const onChangePhone = (phoneNumber) => {
    form.setFieldsValue({ phoneNumber: phoneNumber });
    setPhoneNumber(phoneNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCountries();
      setCountries(data);

      const personalInfoFromLs = getPersonalDataFromLs();

      if (personalInfoFromLs) {
        setPersonalInfo(personalInfoFromLs.personalInfo);
        setCountryCode(personalInfoFromLs.personalInfo.countryOfResidence);

        const formValues = {};
        Object.keys(personalInfoFromLs.personalInfo).forEach((key) => {
          if (key === "dateOfBirth") {
            formValues[key] = moment(personalInfoFromLs.personalInfo[key]);
          } else {
            formValues[key] = personalInfoFromLs.personalInfo[key];
          }
        });

        form.setFieldsValue(formValues);

        const formFilledFields = form.getFieldsValue();

        let undefinedValues = 0;

        Object.keys(formFilledFields).forEach((key) => {
          if (formFilledFields[key] === undefined) undefinedValues++;
        });

        if (undefinedValues === 0) {
          setDisabled(false);
        }
      } else {
        const personalData = await getPersonalInfo();

        const formValues = {
          countryOfResidence: personalData.data.countryOfResidence,
          dateOfBirth: moment(personalData.data.dateOfBirth),
        };

        setPersonalInfo(personalData.data);
        setCountryCode(personalData.data.countryOfResidence);

        if (state.user.firstName) {
          formValues.firstName = state.user.firstName;
        }
        if (state.user.lastName) {
          formValues.lastName = state.user.lastName;
        }

        if (personalData.data?.gender) {
          formValues.gender = personalData.data.gender;
        }
        if (personalData.data?.citizenship) {
          formValues.citizenship = personalData.data.citizenship;
        }
        if (personalData.data?.taxIdNumber) {
          formValues.taxIdNumber = personalData.data.taxIdNumber;
        }
        if (personalData.data?.address?.address1) {
          formValues.address1 = personalData.data.address.address1;
        }
        if (personalData.data?.address?.address2) {
          formValues.address2 = personalData.data.address.address2;
        }
        if (personalData.data?.address?.city) {
          formValues.city = personalData.data.address.city;
        }
        if (personalData.data?.address?.zip) {
          formValues.zip = personalData.data.address.zip;
        }
        if (personalData.data?.address?.phoneNumber) {
          formValues.phoneNumber = personalData.data.address.phoneNumber;
        }
        form.setFieldsValue(formValues);

        if (checkIfAllFieldsFilled(formValues)) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    };
    fetchData();
  }, []);

  const onInputChange = (event, fieldName) => {
    let updateField = {};
    if (event.target.value === " ") {
      updateField[fieldName] = "";
    } else {
      updateField[fieldName] = event.target.value;
    }
    form.setFieldsValue(updateField);
  };
  const dateFormatList = ["YYYY/MM/DD", "YY/MM/DD"];

  return (
    <Row className="personal-wrap">
      <Col span={24}>
        <Row justify={"center"}>
          <Col className="personal-header-wrap" span={24}>
            <span className="personal-header">Complete the KYC Procedure</span>
            <p className="personal-header-desc">
              This step is necessary to get access to the full range of EURST operations
            </p>
          </Col>
        </Row>
        <Row style={{ width: "100%" }} justify={"center"}>
          <Col span={24}>
            <Form
              {...formItemLayout}
              form={form}
              style={{ height: "100%", textAlign: "left" }}
              name="basic"
              className="personal-form-wrap"
              initialValues={{ ...personalInfo }}
              onFinish={onFinish}
              onFieldsChange={onFieldsChange}
              onValuesChange={onValuesChange}
              onFinishFailed={onFinishFailed}
              validateMessages={validateMessages}
            >
              <Row>
                <Divider orientation="left">Personal details</Divider>
              </Row>
              <Row>
                <Col span={10}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "First Name is required!",
                      },
                      {
                        max: 80,
                        message: "First Name should be less than 80 symbols!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={(event) => onInputChange(event, "firstName")}
                      placeholder="First Name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="dateOfBirth"
                    label="Date of birth"
                    rules={[
                      { required: true },
                      () => ({
                        validator(rule, value) {
                          const age = moment().diff(moment(value), "years");
                          if (age >= 18) {
                            return Promise.resolve();
                          }

                          return Promise.reject("You must be 18 years old or above!");
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      inputReadOnly
                      placeholder={"YEAR/MM/DD"}
                      disabledDate={disabledDate}
                      format={dateFormatList}
                    />
                  </Form.Item>
                  <Form.Item
                    name="countryOfResidence"
                    label="Country of residence"
                    rules={[
                      {
                        required: true,
                        message: "Please input your country of residence!",
                      },
                    ]}
                  >
                    <CustomSelect style={{ textAlign: "initial" }} placeholder="Select a country">
                      {countries
                        .filter((item) => item.available)
                        .map((item, index) => (
                          <Option key={index} value={item.code} disabled={!item.available}>
                            {item.name}
                          </Option>
                        ))}
                    </CustomSelect>
                  </Form.Item>

                  <Form.Item name="citizenship" label="Citizenship" rules={[{ required: true }]}>
                    <CustomSelect
                      style={{ textAlign: "initial" }}
                      placeholder="Select a citizenship"
                    >
                      {countries.map((item, index) => (
                        <Option key={`citizenship-${index}`} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </CustomSelect>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Last Name is required!",
                      },
                      {
                        max: 80,
                        message: "Last Name should be less than 80 symbols!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={(event) => onInputChange(event, "lastName")}
                      placeholder="Last Name"
                    />
                  </Form.Item>
                  <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
                    <CustomSelect placeholder="Gender">
                      {Object.values(GENDER).map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </CustomSelect>
                  </Form.Item>
                  <Form.Item
                    name="taxIdNumber"
                    label="Tax ID number"
                    rules={[
                      {
                        required: true,
                        message: "Tax ID is required!",
                      },
                    ]}
                  >
                    <Input
                      onChange={(event) => onInputChange(event, "taxIdNumber")}
                      placeholder="Tax ID Number for US or ID # listed on uploaded proof id for Non US"
                    />
                  </Form.Item>
                  {countryCode === "US" && (
                    <Form.Item
                      name="taxState"
                      label="Tax State"
                      hidden={countryCode !== "US"}
                      rules={[{ required: countryCode === "US" }]}
                    >
                      <CustomSelect placeholder="Tax State">
                        {US_STATES.map((item, i) => {
                          return (
                            <Option key={`state-${i}`} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row>
                <Divider orientation="left">Address</Divider>
              </Row>

              <Row>
                <Col span={10}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name={"address1"}
                        label={"Address 1"}
                        rules={[
                          {
                            required: true,
                            message: "Address 1 is required!",
                          },
                          {
                            max: 100,
                            message: "Address 1 should be less than 100 symbols!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) => onInputChange(event, "address1")}
                          placeholder="Address 1"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name={"address2"}
                        label={"Address 2"}
                        rules={[
                          {
                            required: true,
                            message: "Address 2 is required!",
                          },
                          {
                            max: 100,
                            message: "Address 2 should be less than 100 symbols!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                          onChange={(event) => onInputChange(event, "address2")}
                          placeholder="Address 2"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={10}>
                  <Form.Item
                    name={"city"}
                    label={"City"}
                    rules={[
                      {
                        required: true,
                        message: "City is required!",
                      },
                      {
                        max: 25,
                        message: "City should be less than 25 symbols!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input onChange={(event) => onInputChange(event, "city")} placeholder="City" />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name={"zip"}
                    label={"Postal code"}
                    rules={[
                      {
                        required: true,
                        message: "Postal code is required!",
                      },
                      {
                        pattern: /^\d+$/,
                        message: "Postal code is not valid!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={(event) => onInputChange(event, "zip")}
                      placeholder="Postal code"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify={"start"}>
                <Col span={10}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      {
                        min: 10,
                        message: "The Phone Number should be more than 7 symbols!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <PhoneInput
                      country={countryCode.toLocaleLowerCase()}
                      style={{ width: "100%" }}
                      value={phoneNumber}
                      onChange={onChangePhone}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={"end"} className="personal-btn-wrap">
                <Form.Item>
                  <Button
                    className="primary-button"
                    disabled={disabled}
                    htmlType="submit"
                    type="primary"
                  >
                    Save and continue
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PersonalDetailsPage;
