// modules
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "../../../assets/img/transactions/search.png";
import UndoIcon from "../../../assets/img/transactions/undo-arrow.png";
import { Select } from "antd";
import { GetPaymentMethodList, GetStatusList, GetTokenList } from "../../../services/users";
import { UIContext } from "../../RegisterComponents/UIContext";

const TransactionsSubHeader = () => {
  const { Option } = Select;
  const [tokenList, setTokenList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { setSelectTokenPaymentMethod, setSelectTokenPaymentStatus } = useContext(UIContext);

  useEffect(() => {
    GetTokenList()
      .then((res) => {
        setTokenList(res);
      })
      .catch((err) => {
        console.log(err);
      });

    GetStatusList()
      .then((res) => {
        setStatusList(res);
      })
      .catch((err) => {
        console.log(err);
      });

    GetPaymentMethodList()
      .then((res) => {
        setPaymentMethods(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleTokenChange(value) {
    console.log(`selected ${value}`);
  }

  const handleStatusChange = (value) => {
    setSelectTokenPaymentStatus(value);
  };

  const handlePaymentChange = (value) => {
    setSelectTokenPaymentMethod(value);
  };

  return (
    <div className="transactions-sub-header">
      <div className="searchBox">
        <div className="input-field">
          <img src={SearchIcon} alt="Search icon" />
          <input type="text" placeholder="Search" />
        </div>
      </div>
      <div className="right-side">
        <div className="token-selection">
          <Select defaultValue="Select Token" style={{ width: 125 }} onChange={handleTokenChange}>
            {tokenList.length
              ? tokenList.map((token, index) => {
                  return (
                    <Option key={index} value={token.id}>
                      {token.id}
                    </Option>
                  );
                })
              : null}
          </Select>
        </div>
        <div className="payment-method-selection">
          <Select
            defaultValue="Payment Status"
            style={{ width: 160 }}
            onChange={handleStatusChange}>
            {statusList.length
              ? statusList.map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  );
                })
              : null}
          </Select>
        </div>
        <div className="status-selection">
          <Select
            defaultValue="Payment Method"
            style={{ width: 155 }}
            onChange={handlePaymentChange}>
            {paymentMethods.length
              ? paymentMethods.map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  );
                })
              : null}
          </Select>
        </div>
        <div className="options">
          <button>Search</button>
          <div className="btn-reset">
            <img src={UndoIcon} alt="Undo icon" />
            <button>Reset</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsSubHeader;
