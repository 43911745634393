import React, { useEffect, useRef, useState } from "react";
import BusinessTab from "./BusinessTab";
import Header from "./Header";
import PrivateTab from "./PrivateTab";
import gsap from "gsap";
import "./register.scss";

export default function Register() {
  const [currentTab, setCurrentTab] = useState(null);
  useEffect(() => {}, [currentTab]);
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      gsap.fromTo(
        cardRef.current,
        {
          opacity: 0,
          ease: "expo.out",
          delay: 0.2,
          duration: 0.45,
          scale: 0.5,
        },
        { opacity: 1, ease: "expo.out", delay: 0.2, duration: 0.45, scale: 1 }
      );
    }
  }, []);

  return (
    <div className="register-page">
      <Header />
      <div className="register-page__content">
        <div className="auth-card" ref={cardRef}>
          <div className="auth-card__info">
            <div className="auth-card__header">
              <h3>Create your Account</h3>
            </div>
            <div className="auth-card__description">
              <p>Please provide your required information and start Mint &amp; Redeem EURST.</p>
            </div>
          </div>
          <hr />
          <div className="auth-card__tabs">
            <div
              className="auth-card__active"
              style={{
                transform: `translateX(${currentTab === 0 || currentTab === null ? 0 : 104}%)`,
              }}></div>
            <div className="auth-card__tab">
              <button
                onClick={() => {
                  setCurrentTab(0);
                }}
                className={currentTab === 0 || currentTab === null ? "active" : ""}
                type="button">
                Private
              </button>
            </div>
            <div className="auth-card__tab">
              <button
                className={currentTab === 1 ? "active" : ""}
                type="button"
                onClick={() => {
                  setCurrentTab(1);
                }}>
                Business
              </button>
            </div>
          </div>
          <div
            className="auth-card__content"
            style={{ minHeight: currentTab === 0 || currentTab === null ? "773px" : "669px" }}>
            <div
              className="auth-card__wrapper"
              style={{
                transform: `translateX(-${currentTab === 0 || currentTab === null ? 0 : 100}%)`,
              }}>
              <div
                className="auth-card__inputs auth-card__private"
                style={{ left: 0, opacity: currentTab === 0 || currentTab === null ? 1 : 0 }}>
                <PrivateTab />
              </div>
              <div
                className="auth-card__inputs auth-card__business"
                style={{ left: "100%", opacity: currentTab === 0 || currentTab === null ? 0 : 1 }}>
                <BusinessTab />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
