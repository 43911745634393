import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MEDIA_QUERIES } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
import { InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { Col, DatePicker, Option, Form, Input, Row, Select, Space } from "antd";
import SuccessIcon from "../../../assets/img/popup/success-icon.png";
import { getPersonalInfo, getUser, getUserInfo } from "../../../services/users";
import moment from "moment";

const BasicInfo = () => {
  const [isApproved, setIsApproved] = useState(true);
  const { Option } = Select;
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const [userData, setUserData] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [personalInfo, setPersonalInfo] = useState(null);

  useEffect(() => {
    getUser().then((user) => {
      setUserData(user);
    });
    getPersonalInfo()
      .then((res) => {
        setPersonalInfo(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getUserInfo()
      .then((res) => {
        setUserInfo(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {}, [userInfo]);

  // window size
  const windowSize = useWindowSize();
  const dateFormatList = ["YYYY/MM/DD", "YY/MM/DD"];
  const disabledDate = (current) => {
    const start = moment("1900-01-01", "YYYY-MM-DD");
    const end = moment("2004-01-01", "YYYY-MM-DD");
    return current < start || current > end || current > moment();
  };
  return (
    <div className="basic-info infoBx">
      <div className="infoBx-heading">
        <h3 className="container-heading">
          {!userData ? "" : userData?.type === "company" ? "KYB Status" : "KYC Status"}
        </h3>
        <p className="status">
          <img src={SuccessIcon} alt="Success icon" />
          <span>{isApproved ? "Approved" : null}</span>
        </p>
      </div>
      <div className="info__warning">
        <InfoCircleFilled style={{ color: "#A88100" }} />
        <p>
          If you want to edit your profile details, please contact our{" "}
          <Link to="/">Support Team</Link>
        </p>
      </div>

      <div className="info__content">
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Full Name"
              label="Full Name"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input placeholder="John Deo" disabled={isApproved} value={userInfo?.firstName} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Email Address"
              label="Email Address"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input type="email" placeholder="John_deo@mail.com" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Date of birth"
              label="Date Of Birth"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Space direction="vertical">
                <DatePicker
                  size="default"
                  inputReadOnly
                  placeholder={"YEAR/MM/DD"}
                  disabledDate={disabledDate}
                  format={dateFormatList}
                />
              </Space>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Gender"
              label="Gender"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select defaultValue="Male" onChange={handleChange} disabled={isApproved}>
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Row gutter={16}>
              <Form.Item
                name="Phone number"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Row gutter={6}>
                  <Col className="gutter-row" span={4}>
                    <Select defaultValue="+91" onChange={handleChange} disabled={isApproved}>
                      <Option value="+91">+91</Option>
                      <Option value="+20">+20</Option>
                    </Select>
                  </Col>
                  <Col className="gutter-row" span={20}>
                    <Input placeholder="123456789" disabled={isApproved} />
                  </Col>
                </Row>
              </Form.Item>
            </Row>
          </Col>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Country of residence"
              label="Country Of Residence"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select defaultValue="India" onChange={handleChange} disabled={isApproved}>
                <Option value="India">India</Option>
                <Option value="Egypt">Egypt</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item
            name="Full Address"
            label="Full Address"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select defaultValue="1" onChange={handleChange} disabled={isApproved}>
              <Option value="1">Cross Cut Rd</Option>
              <Option value="2">Cross Cut Rd</Option>
            </Select>
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={windowSize.width < MEDIA_QUERIES.MD ? 24 : 12}>
            <Form.Item
              name="Citizenship"
              label="Citizenship"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select defaultValue="India" onChange={handleChange} disabled={isApproved}>
                <Option value="India">Indian</Option>
                <Option value="Egypt">Egyptian</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BasicInfo;
