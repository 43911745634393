// modules & hooks
import React, { useState, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../constants/revamp";
import { useContext } from "react";
import { Context } from "../../store";
import axios from "axios";
// children
//import { KYBForm } from "../components/KYBComponents";
// styles
import "./index.scss";
import SumsubWebSdk from "@sumsub/websdk-react";
import Cookies from "js-cookie";

const KybPage = () => {
  // window size
  const windowSize = useWindowSize();
  const [state, dispatch] = useContext(Context);
  const { user } = state;
  const [token, setToken] = useState(null);
  const [userId, setuserId] = useState("");
  const [options, setOptions] = useState([]);
  const handler = () => {};
  const errorHandler = () => {};
  const messageHandler = () => {};
  const config = { email: user?.email };

  useEffect(() => {
    let email;
    if (user) {
      ({ email } = user);
    }
    if (!email) {
      email = JSON.parse(localStorage.getItem("user")).email;
    }
    axios
      .post(
        "https://eurst-upgrade.blockgemini.net/api/v1/applicant/kyb/create-access-token",
        { email },
        { headers: { Authorization: Cookies.get("token") } }
      )
      .then((resp) => {
        setToken(resp.data.data.token);
        setuserId(resp.data.data.userId);
        console.log(resp);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className={`kyc-container`}>
      {token && (
        <SumsubWebSdk
          width={"100px"}
          accessToken={token}
          expirationHandler={handler}
          config={config}
          options={options}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      )}
    </section>
  );
};

export default KybPage;
