// modules
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// assets
import DashoardAtiveIcon from "../assets/img/header/dashboard-active.png";
// import HomeIcon from "../assets/img/header/home-mobile-icon.png";
import HomeActiveIcon from "../assets/img/header/home-mobile-active.png";
import TransactionsActiveIcon from "../assets/img/header/transactions-mobile-active.png";
import TransferActiveIcon from "../assets/img/header/transfer-mobile-active.png";
import KybActiveIcon from "../assets/img/header/kyb-mobile-active.png";
// styles
import "../styles/WidgetsStyles/HeaderNavStyles.scss";
import { Context } from "../store";

const SubHeader = () => {
  const [state, dispatch] = useContext(Context);
  const { user } = state;

  useEffect(async () => {
    if (!user.type) {
      const user = await JSON.parse(localStorage.getItem("user"));
      setLocalUser(user);
    }
  }, []);

  const [localUser, setLocalUser] = useState(null);
  return (
    <div className="main-header-nav responsive-md container">
      <nav>
        <ul className="scrollable">
          <li>
            <NavLink to="/main/dashboard" activeClassName="active-link">
              <img src={DashoardAtiveIcon} alt="Dashboard active icon" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/main/home" activeClassName="active-link">
              <img src={HomeActiveIcon} alt="Dashboard active icon" />
              <span>Mint &amp; Redeem</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/main/withdraw" activeClassName="active-link">
              <img src={TransferActiveIcon} alt="Dashboard active icon" />
              <span>Transfer</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/main/transactions" activeClassName="active-link">
              <img src={TransactionsActiveIcon} alt="Dashboard active icon" />
              <span>Transactions</span>
            </NavLink>
          </li>
          {user?.type === "company" || localUser?.type === "company" ? (
            <li>
              <NavLink to="/main/kyb" activeClassName="active-link">
                <img src={KybActiveIcon} alt="Dashboard active icon" />
                <span>KYB</span>
              </NavLink>
            </li>
          ) : (
            <li>
              <NavLink to="/main/kyc" activeClassName="active-link">
                <img src={KybActiveIcon} alt="Dashboard active icon" />
                <span>KYC</span>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default SubHeader;
