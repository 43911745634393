import { getInstance } from "../utils/helpers";
import Cookies from "js-cookie";

const storage = localStorage;
const personalInfoStorageKey = "personalInfo";

function postPersonalInfo(personalInfo, residenceInfo) {
  const payload = {
    ...personalInfo,
    ...residenceInfo,
  };
  const url = `/api/users/personal-info`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().patch(url, payload);
}

function setKycStatus() {
  const url = `/api/users/status`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().patch(url);
}

function getUser() {
  const url = `/api/v1/user/me`;
  const instance = getInstance();
  // const token = Cookies.get("token");
  // instance.defaults.headers.Authorization = `${token}`;
  return instance.get(url);
}

function getPersonalOnfo() {
  const url = `/api/v1/user/personal-info`;
  const instance = getInstance();
  // const token = Cookies.get("token");
  // instance.defaults.headers.Authorization = `${token}`;
  return instance.get(url);
}

function GetTokenList() {
  const url = `/api/v1/tokens/list`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function GetTransactions() {
  const url = `/api/v1/transactions/list`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function GetStatusList() {
  const url = `/api/v1/transactions/status-types`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function GetPaymentMethodList() {
  const url = `/api/v1/transactions/payment-methods`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function getCompanyInfo() {
  const url = `/api/users/company-info`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function patchUserEmail() {
  const url = `/api/users/email`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().patch(url);
}

function getPreviewAgreement(payload) {
  const url = `/api/users/agreement-preview`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.post(url, payload);
}

function fetchDoc(ptDpcumentId) {
  const url = `/api/users/document-url?ptDocumentId=${ptDpcumentId}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function getPersonalInfo() {
  const url = `/api/v1/user/personal-info`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function getUserInfo() {
  const url = `/api/v1/user/me`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function savePersonalDataToLs(personalInfo) {
  storage.setItem(personalInfoStorageKey, JSON.stringify(personalInfo));
}

function getPersonalDataFromLs() {
  const json = storage.getItem(personalInfoStorageKey);
  if (json) {
    try {
      const result = JSON.parse(json);
      return result;
    } catch (error) {
      console.error("Error From json", error);
    }
  }
}

function removePersonalDataFromLs() {
  storage.removeItem(personalInfoStorageKey);
}

export {
  postPersonalInfo,
  getPersonalInfo,
  getUser,
  savePersonalDataToLs,
  getPersonalDataFromLs,
  removePersonalDataFromLs,
  setKycStatus,
  getPreviewAgreement,
  fetchDoc,
  GetTokenList,
  GetTransactions,
  patchUserEmail,
  GetStatusList,
  GetPaymentMethodList,
  getPersonalOnfo,
  getCompanyInfo,
  getUserInfo,
};
