import React, { createContext, useState } from "react";

export const UIContext = createContext({
  visible: false,

  setVisible: (value) => {},
  selectTokenPaymentMethod: "",
  selectTokenPaymentStatus: "",
  setSelectTokenPaymentStatus: (paymentStatus) => {},
  setSelectTokenPaymentMethod: (paymentMethod) => {},
});

function UIContextProvider({ children }) {
  const [visible, setVisible] = useState(false);
  const [selectTokenPaymentMethod, setSelectTokenPaymentMethod] = useState("");
  const [selectTokenPaymentStatus, setSelectTokenPaymentStatus] = useState("");

  return (
    <UIContext.Provider
      value={{
        visible,
        setVisible,
        selectTokenPaymentMethod,
        selectTokenPaymentStatus,
        setSelectTokenPaymentMethod,
        setSelectTokenPaymentStatus,
      }}>
      {children}
    </UIContext.Provider>
  );
}

export default UIContextProvider;
