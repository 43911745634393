import React, { useEffect, useState } from "react";
import Icon from "./Icon";

const CheckBox = ({
  id,
  style,
  onChange,
  label,
  name,
  hasBorder,
  border,
  isAnimated,
  hasBackground,
  background,
  checked,
}) => {
  const [state, setState] = useState(checked);

  useEffect(() => {
    onChange(state);
  }, [state]);

  useEffect(() => {
    setState(checked);
  }, [checked]);

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        checked={state}
        onChange={(e) => {
          setState(e.target.checked);
        }}
        name={name}
        id={id}
        className="checkbox__control"
      />
      <label className="checkbox__label" htmlFor={id}>
        <div
          className={`checkbox__icon${style && !hasBackground ? ` checkbox__icon-${style}` : ""}${
            isAnimated ? ` checkbox__icon-animated ${state ? "checked" : ""}` : ""
          }${hasBackground ? ` checkbox__icon-${background}-bg` : ""}${
            hasBorder ? ` checkbox__icon-${border}-border` : ""
          }`}>
          {state ? <Icon icon={"check"} size={20} /> : null}
        </div>
        <span>{label}</span>
      </label>
    </div>
  );
};

export default CheckBox;
