// modules
import React from "react";
// components
import { RecentTransactions } from "../components/TransactionsComponents";
import { MEDIA_QUERIES } from "../constants";
import useWindowSize from "../hooks/useWindowSize";
// styles
import "../styles/PagesStyles/TransactionsStyles/index.scss";

const TransactionsPage = () => {
  // window size
  const windowSize = useWindowSize();
  return (
    <section
      className={`transactions ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "conatiner-fluid"
      }`}>
      <RecentTransactions />
    </section>
  );
};

export default TransactionsPage;
