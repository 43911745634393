// modules
import { Button, Input } from "antd";
import React, { useState } from "react";
// assets
import CloseIcon from "../assets/img/reset-password/close.png";
import ResetIcon from "../assets/img/transactions/reset.png";
// antd
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleTransactionsApplyFilter } from "../context/actions";
const { Option, OptGroup } = Select;

const TransactionsApplyFilter = () => {
  // constants
  const STATUS_TYPES = { ALL: "ALL", SUCCESS: "SUCCESS", FAILED: "FAILED" };
  // states
  const [searchText, setSearchText] = useState(null);
  const [status, setStatus] = useState(STATUS_TYPES.ALL);

  // dispatcher
  const dispatcher = useDispatch();

  // redux
  const isTransactionsApplyFilter = useSelector(
    (state) => state.shared.ui.isTransactionsApplyFilter
  );
  console.log({ isTransactionsApplyFilter });

  const toggleModal = (e) => {
    e.stopPropagation();
    dispatcher(toggleTransactionsApplyFilter());
  };

  // change status
  const changeStatusHandler = (status) => {
    setStatus((ps) => status);
  };

  // change handler
  const changeSearchHandeler = (e) => {
    setSearchText((ps) => e.target.value);
  };

  // antd select methods
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <div className="transactions-apply-filter" onClick={(e) => e.stopPropagation()}>
      <div className="apply-filter-heading">
        <h2>Apply Filter</h2>
        <img src={CloseIcon} alt="Close icon" className="close" onClick={toggleModal} />
      </div>
      <Input
        placeholder="Search"
        id="searchTxt"
        name="searchText"
        value={searchText}
        onChange={changeSearchHandeler}
      />
      <div className="select-token">
        <h4>Select Token</h4>
        <Select defaultValue="lucy" style={{ width: 200 }} onChange={handleChange}>
          <OptGroup label="Manager">
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
          </OptGroup>
          <OptGroup label="Engineer">
            <Option value="Yiminghe">yiminghe</Option>
          </OptGroup>
        </Select>
      </div>
      <div className="select-payment">
        <h4>Select Payment</h4>
        <Select defaultValue="lucy" style={{ width: 200 }} onChange={handleChange}>
          <OptGroup label="Manager">
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
          </OptGroup>
          <OptGroup label="Engineer">
            <Option value="Yiminghe">yiminghe</Option>
          </OptGroup>
        </Select>
      </div>
      <div className="payment-status">
        <h4>Payment Status</h4>
        <ul>
          <li
            className={`${status === STATUS_TYPES.ALL ? "selected" : null}`}
            onClick={() => changeStatusHandler(STATUS_TYPES.ALL)}>
            All
          </li>
          <li
            className={`${status === STATUS_TYPES.SUCCESS ? "selected" : null}`}
            onClick={() => changeStatusHandler(STATUS_TYPES.SUCCESS)}>
            Success
          </li>
          <li
            className={`${status === STATUS_TYPES.FAILED ? "selected" : null}`}
            onClick={() => changeStatusHandler(STATUS_TYPES.FAILED)}>
            Failed
          </li>
        </ul>
      </div>
      <div className="options">
        <Button icon={<img src={ResetIcon} alt="Reset icon" />}>Reset</Button>
        <Button type="primary">Search</Button>
      </div>
    </div>
  );
};

export default TransactionsApplyFilter;
