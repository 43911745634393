import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Icon from "./Icon";

export default function InputGroup({
  name,
  label,
  type,
  value,
  placeholder,
  markAsValid,
  onChange,
  icon,
  rules = [],
}) {
  const [inputType, setInputType] = useState(type);
  const [touched, setTouched] = useState(false);
  const [errorValue, setErrorValue] = useState("");

  useEffect(() => {
    if (touched) {
      for (let i = 0; i < rules.length; ++i) {
        if (rules[i].type === "required" && !value.trim()) {
          setErrorValue(rules[i].message);
          markAsValid(false);
          break;
        } else if (rules[i].type === "check" && value.trim() !== rules[i].against) {
          setErrorValue(rules[i].message);
          markAsValid(false);
          break;
        } else if (rules[i].type === "min" && value.trim().length < rules[i].min) {
          setErrorValue(rules[i].message);
          markAsValid(false);
          break;
        } else if (rules[i].type === "max" && value.trim().length > rules[i].max) {
          setErrorValue(rules[i].message);
          markAsValid(false);
          break;
        } else if (
          value.trim() &&
          rules[i].type === "email" &&
          !String(value.trim())
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          setErrorValue(rules[i].message);
          markAsValid(false);

          break;
        } else if (
          rules[i].type === "password" &&
          !String(value).match(
            /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]/
          )
        ) {
          setErrorValue(rules[i].message);
          markAsValid(false);
          break;
        } else {
          setErrorValue("");
          markAsValid(true);
        }
      }
    }
  }, [touched, value]);

  return (
    <div className="form-group">
      <label htmlFor={name} className="form-label">
        {label}&nbsp; <span style={{ color: "red" }}>*</span>
      </label>
      {type === "password" ? (
        <Tooltip
          title={
            <div className="password-rules">
              <h4 className="container-heading-color">Password should contain</h4>
              <ul>
                <li>More than 8 characters</li>
                <li>Uppercase letters (A-Z)</li>
                <li>Lowercase letters (a-z)</li>
                <li>Digits (0-9)</li>
                <li>Special characters (!, $, #, %, etc.)</li>
              </ul>
            </div>
          }>
          <div className="info-icon">
            <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
          </div>
        </Tooltip>
      ) : null}
      {icon && (
        <div
          onClick={() => {
            if (inputType === "password") {
              setInputType("text");
            } else {
              setInputType("password");
            }
          }}
          className={`show-password ${errorValue && touched ? "error" : touched ? "success" : ""}`}>
          {inputType === "password" ? (
            <Icon icon={"visibility_off"} size={20} />
          ) : (
            <Icon icon={icon} size={20} />
          )}
        </div>
      )}
      <input
        className={`form-control select-country__input ${
          errorValue ? "invalid" : touched ? "success" : ""
        }`}
        type={inputType}
        placeholder={placeholder}
        onBlur={(e) => {
          e.target.value = e.target.value.trim();
          setTouched(true);
        }}
        name={name}
        id={name}
        onChange={(e) => {
          e.target.value = e.target.value.trim();
          onChange(e);
        }}
        value={value}
      />
      {errorValue && touched ? (
        <div className="invalid-icon">
          <Icon icon="highlight_remove" size={22} />
        </div>
      ) : touched ? (
        <div className="valid-icon">
          <Icon icon="check" size={22} />
        </div>
      ) : null}
      {errorValue ? <span className="invalid-feedback">{errorValue}</span> : null}
    </div>
  );
}
