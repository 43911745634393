/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { USER_STATUSES_AS_OBJECT, PT_DOCUMENT_TYPES } from "eurst-shared/src/enums";
import { toast } from "react-toastify";
import Col from "antd/lib/grid/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Upload from "antd/lib/upload";
import { InboxOutlined } from "@ant-design/icons";
import { Context } from "../../store";
import Button from "antd/lib/button";
import {
  updateKycDocumentStatus,
  createKycDocumentChecksInPt,
} from "../../services/kyc-document-checks";
import { getInstance } from "../../utils/helpers";
import BackSideMessage4Upload from "../../components/BacksideMessage4Upload";

const { Dragger } = Upload;

const UploadListItem = ({ originNode, file }) => {
  console.info("originNode, file", originNode, file);
  const ref = React.useRef();
  const errorNode = <div title="Upload Error">{originNode.props.children}</div>;
  return (
    <div ref={ref} className={`ant-upload-draggable-list-item`} style={{ cursor: "pointer" }}>
      {file.status === "error" ? errorNode : originNode}
    </div>
  );
};

function UpdateKYCDocumentPage() {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [state] = useContext(Context);

  const { user } = state;

  const kycDocumentCheck = user.kycDocumentCheckResults.find((r) => {
    return r.id.toString() === id;
  });

  kycDocumentCheck.feDetails = PT_DOCUMENT_TYPES.find(
    (r) => r.id === kycDocumentCheck.details.type
  );

  if (state.user && ![USER_STATUSES_AS_OBJECT.kycVerification].includes(state.user.status)) {
    return <Redirect to="/main/dashboard" />;
  }
  const maxCount = kycDocumentCheck.feDetails.backside ? 2 : 1;

  const uploadDocuments = async () => {
    setLoading(true);

    try {
      setLoading(true);

      const url = `/api/users/personal-info/upload/${files[0].entity}`;
      const data = new FormData();
      data.append("file", files[0]);

      const defaultHeaders = getInstance().defaults.headers;
      const config = {
        headers: {
          ...defaultHeaders,
          "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
        },
      };

      const parent = await getInstance().post(url, data, config);

      if (files.length > 1) {
        const url = `/api/users/personal-info/upload/${files[1].entity}/parent/${parent.data.history.ptDocumentId}`;
        const data = new FormData();
        data.append("file", files[1]);

        const defaultHeaders = getInstance().defaults.headers;
        const config = {
          headers: {
            ...defaultHeaders,
            "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
          },
        };

        await getInstance().post(url, data, config);
      }

      toast(`${kycDocumentCheck.feDetails.label} file uploaded successfully.`, {
        type: "success",
        position: "bottom-right",
      });

      await updateKycDocumentStatus(id);

      await createKycDocumentChecksInPt(id, { docId: parent.data.history.id });

      history.push("/main/dashboard");
    } catch (error) {
      console.error(error);
      toast(`${error?.data?.message}`, {
        type: "error",
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const DraggerProps = {
    name: "file",
    multiple: false,
    fileList: files,
    accept: "image/*, .pdf",
    previewFile: (file) => {
      console.info("previewFile", file);
    },
    onRemove: (file) => {
      const newFiles = files.filter((f) => f.uid !== file.uid);
      setFiles(newFiles);
      setDisabled(true);
    },
    beforeUpload: (file) => {
      if (files.length === maxCount) {
        toast(`You need to upload ${maxCount} files only`, { type: "warning" });
        return false;
      }
      const hasFileFitSize = file.size < 5000000;
      if (!hasFileFitSize) {
        toast("Your document is too large!Try another file, please.", { type: "warning" });
        return false;
      }

      const hasFileAllowedExtension = /image/.test(file.type) || /pdf/.test(file.type);
      if (!hasFileAllowedExtension) {
        toast("You can upload only pictures and PDF!\nTry another file, please.", {
          type: "warning",
        });
        return false;
      }

      file.entity = kycDocumentCheck.feDetails.id;
      files.push(file);
      setFiles(files);

      if (files.length === maxCount) {
        setDisabled(false);
      }
      return true;
    },
    customRequest: ({ onSuccess }) => {
      onSuccess();
    },
  };
  const uploadHint = kycDocumentCheck.feDetails.backside
    ? "Click here to upload front and back sides"
    : "Click to this area to upload";

  const disabledUploadButton = files.length < maxCount;

  return (
    <Row className="upload-kyc-wrap" align={"flex-start"} justify={"center"}>
      <Space direction="vertical">
        <h2>Update your documents</h2>
        <p className="upload-kyc-desc">
          This step is necessary to get access to the full range of EURST operations
        </p>
        <Divider className="upload-devider" orientation="left">
          Document type: {kycDocumentCheck.feDetails.label}
        </Divider>
        <Row align={"middle"} justify={"center"} wrap>
          <Col className="upload-drag-wrap" sm={24} md={18}>
            <Dragger {...DraggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{uploadHint}</p>

              <BackSideMessage4Upload docType={kycDocumentCheck.details.type} />

              <p className="ant-upload-hint">
                The image must be high quality, unobstructed and uncropped.
              </p>
              <p className="ant-upload-hint">
                The image must show a full document page (all 4 corners must be visible)
              </p>
            </Dragger>
          </Col>
        </Row>
        <Row align={"middle"} justify={"center"} wrap>
          <Col sm={24} style={{ margin: "2rem 0" }}>
            <p className="kyc-finish-note">
              Note:
              <br />
              The KYC procedure check requires 1-5 days.
              <br />
              You will receive an email when it is complete.
              <br />
              After successful completion you will have access to the services.
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Button
            className="primary-button"
            disabled={disabled}
            loading={loading}
            onClick={uploadDocuments}
            type="primary"
          >
            Upload documents
          </Button>
        </Row>
      </Space>
    </Row>
  );
}

export default UpdateKYCDocumentPage;

// const CustomDragger = ({ uniqueProps, docType }) => {
//   // console.log('uniqueProps', identityDoc, otherDoc);
//   const onPreview = async (file) => {
//     if (/image/.test(file.type)) {
//       let src = file.url;
//
//       if (!src) {
//         src = await new Promise((resolve) => {
//           const reader = new FileReader();
//           reader.readAsDataURL(file);
//           reader.onload = () => resolve(reader.result);
//         });
//       }
//
//       const image = new Image();
//       image.src = src;
//
//       const imgWindow = window.open(src);
//       imgWindow.document.write(image.outerHTML);
//     } else if (/pdf/.test(file.type)) {
//       const pdfFile = new Blob([file], { type: "application/pdf" });
//       const fileURL = URL.createObjectURL(pdfFile);
//
//       window.open(fileURL);
//     } else {
//       console.error(
//         "There was a problem with the file preview!\n Please report this issue to the development team. Thank you =)"
//       );
//     }
//   };
//
//   const beforeUpload = (file) => {
//     try {
//       const isFileUnique = uniqueProps.fileList.every(
//         (doc) => doc.size !== file.size && doc.name !== file.name
//       );
//       if (!isFileUnique) throw "You have already selected this file!\nDon't duplicate, please.";
//
//       const hasFileFitSize = file.size < 5000000;
//       if (!hasFileFitSize) throw "Your document is too large!\nTry another file, please.";
//
//       const hasFileAllowedExtension = /image/.test(file.type) || /pdf/.test(file.type);
//       if (!hasFileAllowedExtension)
//         throw "You can upload only pictures and PDF!\nTry another file, please.";
//
//       const isAmountLimit = uniqueProps.fileList?.length < 5;
//       if (!isAmountLimit) throw "You can upload only 5 documents!";
//     } catch (message) {
//       toast(message, {
//         type: "warning",
//         position: "bottom-right",
//       });
//       return false;
//     }
//   };
//   console.log("docType", docType);
//   return (
//     <Dragger
//       accept="image/*, .pdf"
//       listType="picture"
//       multiple={false}
//       showUploadList
//       onPreview={onPreview}
//       beforeUpload={beforeUpload}
//       {...uniqueProps}>
//       <p className="ant-upload-drag-icon">
//         <InboxOutlined />
//       </p>
//       <p className="ant-upload-text">Select a document type,</p>
//       <p className="ant-upload-text">then drag and drop or select a file here.</p>
//       <p className="ant-upload-hint">{`Upload a copy of а valid ${
//         Object.keys(docType).length !== 0 ? docType.label : "document"
//         } in colour.`}</p>
//       {REQUIRED_PT_DOC_TYPES.some(
//         (id) => id === docType.id && id !== PT_DOCUMENT_TYPES_AS_OBJECT["passport"]
//       ) && <p className="ant-upload-hint">Please, upload both sides of the document.</p>}
//       <p className="ant-upload-hint">The image must be high quality, unobstructed and uncropped.</p>
//       <p className="ant-upload-hint">
//         The image must show a full document page (all 4 corners must be visible)
//       </p>
//       {/*<p className="ant-upload-hint">*/}
//       {/*  You can upload up to 5 files. MAX size for each file is 5MB.*/}
//       {/*</p>*/}
//     </Dragger>
//   );
// };
//
// CustomDragger.propTypes = {
//   uniqueProps: PropTypes.object,
// };
//
// const CustomDropOptions = ({ options, onClick }) => (
//   <Menu subMenuCloseDelay triggerSubMenuAction={"click"}>
//     {options.map((docType) => (
//       <Menu.Item key={docType.id} onClick={() => onClick(docType)}>
//         {docType.label}
//       </Menu.Item>
//     ))}
//   </Menu>
// );
//
// CustomDropOptions.propTypes = {
//   options: PropTypes.arrayOf(PropTypes.object),
//   onClick: PropTypes.func,
// };
