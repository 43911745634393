// modules
import React from "react";
// antd
import { CloseCircleFilled } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { Space, Typography } from "antd";

const PrivacyPolicy = ({ handleCancelPrivacy }) => {
  const { Title } = Typography;
  return (
    <div className="privacy-policy">
      <div className="privacy-policy-heading">
        <h3 className="container-heading">EURST Privacy Policy</h3>
        <CloseCircleFilled style={{ color: "#505050" }} onClick={handleCancelPrivacy} />
      </div>
      <Typography className="privacy-policy-content">
        <Title level={5}>Guidelines and Resources</Title>
        <Paragraph>
          Wallex Trust (“Company,” “our,” “we”) understands the importance of privacy, especially
          when it comes to your personal information. Data privacy and your trust are our top
          priorities, and in this Privacy Policy we explain what information we collect from you
          when you use https://eurst.io and subdomains of https://eurst.io, (collectively, the
          “Website”), how we will use this information, and the very limited ways that we may share
          your information
        </Paragraph>
        <Paragraph>
          Please read this policy carefully to understand our policies and practices regarding your
          information, as you agree to this Privacy Policy by using the Website. ​If you do not
          agree with our policies and practices, do not use the Website. This Privacy Policy is part
          of our Terms of Use available at https://eurst.io.
        </Paragraph>
        <Title level={5}>Users Under Age 18</Title>
        <Paragraph>
          The Website is only intended for adults eighteen (18) years of age and older. You are not
          allowed to use the Website or provide information on it if you are under eighteen (18)
          years of age.
        </Paragraph>
        <Paragraph>
          The Website is not directed at children under age thirteen (13), and therefore if the
          Company receives any information about a child under thirteen (13), the Company will
          assume that such information is being given with parental consent. If you believe we might
          have any information from or about a child under thirteen (13) without parental consent,
          please contact us immediately at legal@eurst.io.
        </Paragraph>
        <Title level={5}>Information We Collect About You</Title>
        <Paragraph>
          We collect several types of information from and about users of the Website, including:
        </Paragraph>
        <Paragraph>
          Information about your internet connection, the equipment you use to access the Website,
          data associated with usage of Google Analytics and Website usage details, as well as
          information you may provide to us, such as your email address.
        </Paragraph>
        <Space></Space>
        <Title level={5}>How We Collect Information From You</Title>
        <Paragraph>We collect information from you in two ways:</Paragraph>
        <Paragraph>
          <ul>
            <li>
              First, we collect information directly from you when you provide it to us, such as or
              entering your email address to sign up for our newsletter.
            </li>
            <li>
              Second, we collect generic information about your usage and equipment automatically as
              you navigate through the Website, such as usage details, IP addresses, web browser
              preferences, and information collected through cookies and other tracking
              technologies. Although we do not correlate tracking information to individuals, some
              information collected, such as IP addresses, will be unique.
            </li>
          </ul>
        </Paragraph>
        <Title level={5}>How We Collect Information From You</Title>
        <Paragraph>
          We do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser
          mechanism is in place.
        </Paragraph>
        <Title level={5}>How We Use Your Information</Title>
        <Paragraph>
          We use information that we collect about you or that you provide to us, including personal
          information:
        </Paragraph>
        <Paragraph>
          <ul>
            <li>To continue the daily operation of the Website.</li>
            <li>
              To meet the requirements to make disclosures under the requirements of any law binding
              on the Website under and for the purposes of any guidelines issued by regulatory or
              other authorities with which the Website is expected to comply.
            </li>
            <li>
              To provide you with information, products, or services that you request from us.
            </li>
            <li>
              To notify you about changes to the Website or any products or services we offer or
              provide through it.
            </li>
            <li>In any other way we may describe when you provide the information.</li>
          </ul>
        </Paragraph>
        <Title level={5}>Disclosure of Your Information</Title>
        <Paragraph>
          We only collect personal information that we believe to be relevant and required to
          conduct our business. We may share your personal information with our business partners
          and service providers, but only to the extent that they each need to know specific
          information to continue to provide the Website or services to you. This includes:
        </Paragraph>
        <Paragraph>
          <ul>
            <li>
              Our contractors, employees, service providers, and other third parties that we use to
              support our business and improve the Website;
            </li>
            <li>
              To a buyer or other successor in the event of a merger or reorganization if the
              personal information of our users is part of the assets transferred;
            </li>
            <li>
              To comply with any court order, law, or legal process, including to respond to any
              government or regulatory request;
            </li>
            <li>
              If we believe disclosure is necessary or appropriate to protect the rights, property,
              or safety of the Website, our users, or others;
            </li>
            <li>For any other purpose disclosed by us when you provide the information.</li>
          </ul>
        </Paragraph>
        <Paragraph>
          We may disclose aggregated information about our users, which means information that does
          not identify any individual, without restriction. As an example, we may include in our
          marketing materials that a certain percentage of our users are female, but we won’t share
          a list of all female users in these materials.
        </Paragraph>
        <Title level={5}>Accessing and Correcting Your Information</Title>
        <Paragraph>
          You may send us an email at support@eurst.io to request access to, correct, or delete any
          personal information that you have provided to us. We may not accommodate a request to
          change information if we believe the change would violate any law or legal requirement or
          cause the information to be incorrect.
        </Paragraph>
        <Title level={5}>Data Security</Title>
        <Paragraph>
          We have implemented measures designed to secure your personal information from accidental
          loss and from unauthorized access. For example, access by a user to his or her personal
          information is available through a unique customer ID and password selected by the user
          that should be regularly updated. This information is encrypted through the Website with
          Secure Socket Layer technology (SSL) and is also encrypted when it is stored by us to
          prevent unauthorized parties from viewing such information. Also, we perform regular
          malware scanning of the Website and all servers and computers used by us to support the
          Website. All Company employees are required to adhere to our security and confidentiality
          procedures and undergo training related to maintaining the security of user personal
          information.
        </Paragraph>
        <Paragraph>
          The safety and security of your information also depends on you. Where you have chosen a
          password for access to certain parts of the Website, you are responsible for keeping this
          password confidential. We ask you not to share your password with anyone and be careful
          about giving out information to other users on the Website if requested.
        </Paragraph>
        <Paragraph>
          Unfortunately, the transmission of information via the internet is not completely secure.
          Although we do our best to protect your personal information, we cannot guarantee the
          security of your personal information transmitted to the Website. Any transmission of
          personal information is at your own risk. We are not responsible for circumvention of any
          privacy settings or security measures contained on the Website.
        </Paragraph>
        <Title level={5}>Third Party Links</Title>
        <Paragraph>
          The Website may contain links that lead to other websites, and the Company is not
          responsible for the privacy practices, content, and/or activities of these linked
          websites. Nonetheless, we seek to protect the integrity of the Website and welcome any
          feedback about these external websites.
        </Paragraph>
        <Title level={5}>Changes to Our Privacy Policy, if any.</Title>
        <Paragraph>
          We will post any changes we make to our privacy policy on this page and you will be
          notified of any material changes on the Website home page and in an email to the most
          recent email address that you provided to us, if any.
        </Paragraph>
        <Title level={5}>Contact Information</Title>
        <Paragraph>
          To ask questions or comment about this Privacy Policy and our privacy practices, contact
          us at legal@eurst.io.
        </Paragraph>
      </Typography>
    </div>
  );
};

export default PrivacyPolicy;
