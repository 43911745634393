import React, { useEffect, useRef, useState } from "react";
import Icon from "./Icon";
import DatePicker from "react-datepicker2";
import moment from "moment";

function DateOfBirth({ name, onDateChange, markAsValid }) {
  const [invalidMessage, setInvalidMessage] = useState("");
  const dropDownRef = useRef(null);
  const [value, setValue] = useState(moment("1995, 10, 16", "YYYY-MM-DD"));
  const wrapperRef = useRef();
  const [touched, setTouched] = useState(false);

  window.addEventListener("click", (e) => {
    if (!e.target.closest(".select-country") && dropDownRef.current) {
      dropDownRef.current.classList.remove("active");
    }
  });

  useEffect(() => {
    const element = document.querySelector(".datepicker-input");
    if (element) {
      element.id = "calendar-id";
    }
    if (wrapperRef.current) {
      const input = wrapperRef.current.querySelector("input");
      const invalidIcon = wrapperRef.current.querySelector(".invalid-icon-calendar");
      const validIcon = wrapperRef.current.querySelector(".valid-icon-calendar");
      const invalidFeedback = wrapperRef.current.querySelector(".invalid-feedback");
      const selectCountryIcon = wrapperRef.current.querySelector(".select-country__icon");
      const validatityIcons = wrapperRef.current.querySelector(".validity-icons");

      input.addEventListener("blur", (e) => {
        // const prevValue = e.target.value;
        // if (/[A-Za-z]/.test(e.target.value)) {
        //   e.target.value = prevValue;
        // }
        input.value = input.value.replace(" ", "");
        setTouched(true);
        validatityIcons.style.opacity = 1;
        validatityIcons.style.visibility = "visible";
        const hasLetters = /[A-Za-z]/.test(e.target.value);
        if (e.target.value === "1995/10/16" && !touched) {
          invalidIcon.style.visibility = "visible";
          invalidIcon.style.opacity = 1;
          validIcon.style.visibility = "hidden";
          validIcon.style.opacity = 0;
          selectCountryIcon.classList.add("active");
          e.target.classList.add("invalid");
          e.target.classList.remove("success");
          invalidFeedback.style.visibility = "visible";
          invalidFeedback.style.opacity = 1;
          setInvalidMessage("Date of birth is required");
          markAsValid(false);
        } else if (+e.target.value.split("/")[0] > 2003 && !touched) {
          invalidIcon.style.visibility = "visible";
          invalidIcon.style.opacity = 1;
          validIcon.style.visibility = "hidden";
          validIcon.style.opacity = 0;
          selectCountryIcon.classList.add("active");
          input.classList.add("invalid");
          input.classList.remove("success");
          invalidFeedback.style.visibility = "visible";
          invalidFeedback.style.opacity = 1;
          setInvalidMessage("Age should be greater than Or equal 18");
          markAsValid(false);
        } else if (+e.target.value.split("/")[0] < 1900 && !touched && !hasLetters) {
          invalidIcon.style.visibility = "visible";
          invalidIcon.style.opacity = 1;
          validIcon.style.visibility = "hidden";
          validIcon.style.opacity = 0;
          selectCountryIcon.classList.add("active");
          input.classList.add("invalid");
          input.classList.remove("success");
          invalidFeedback.style.visibility = "visible";
          invalidFeedback.style.opacity = 1;
          setInvalidMessage("Selected Date is invalid.");
          markAsValid(false);
        } else {
          invalidIcon.style.visibility = "hidden";
          invalidIcon.style.opacity = 0;
          validIcon.style.visibility = "visible";
          validIcon.style.opacity = 1;
          selectCountryIcon.classList.add("active");
          e.target.classList.remove("invalid");
          e.target.classList.add("success");
          invalidFeedback.style.visibility = "hidden";
          invalidFeedback.style.opacity = 0;
          onDateChange(value);
          markAsValid(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (wrapperRef.current && touched) {
      const input = wrapperRef.current.querySelector(".datepicker-input");
      input.value = input.value.trim();
      if (value._i === "1995/10/16" && !touched) {
        wrapperRef.current.querySelector(".invalid-icon-calendar").style.visibility = "visible";
        wrapperRef.current.querySelector(".invalid-icon-calendar").style.opacity = 1;
        wrapperRef.current.querySelector(".valid-icon-calendar").style.visibility = "hidden";
        wrapperRef.current.querySelector(".valid-icon-calendar").style.opacity = 0;
        wrapperRef.current.querySelector(".select-country__icon").classList.add("active");
        input.classList.add("invalid");
        input.classList.remove("success");
        wrapperRef.current.querySelector(".invalid-feedback").style.visibility = "visible";
        wrapperRef.current.querySelector(".invalid-feedback").style.opacity = 1;
        setInvalidMessage("Date of birth is required");
        markAsValid(false);
      } else if (value?._pf?.parsedDateParts[0] > 2003) {
        wrapperRef.current.querySelector(".invalid-icon-calendar").style.visibility = "visible";
        wrapperRef.current.querySelector(".invalid-icon-calendar").style.opacity = 1;
        wrapperRef.current.querySelector(".valid-icon-calendar").style.visibility = "hidden";
        wrapperRef.current.querySelector(".valid-icon-calendar").style.opacity = 0;
        wrapperRef.current.querySelector(".select-country__icon").classList.add("active");
        input.classList.add("invalid");
        input.classList.remove("success");
        wrapperRef.current.querySelector(".invalid-feedback").style.visibility = "visible";
        wrapperRef.current.querySelector(".invalid-feedback").style.opacity = 1;
        setInvalidMessage("Age should be greater than Or equal 18");
        markAsValid(false);
      } else if (value?._pf?.parsedDateParts[0] < 1900) {
        wrapperRef.current.querySelector(".invalid-icon-calendar").style.visibility = "visible";
        wrapperRef.current.querySelector(".invalid-icon-calendar").style.opacity = 1;
        wrapperRef.current.querySelector(".valid-icon-calendar").style.visibility = "hidden";
        wrapperRef.current.querySelector(".valid-icon-calendar").style.opacity = 0;
        wrapperRef.current.querySelector(".select-country__icon").classList.add("active");
        input.classList.add("invalid");
        input.classList.remove("success");
        wrapperRef.current.querySelector(".invalid-feedback").style.visibility = "visible";
        wrapperRef.current.querySelector(".invalid-feedback").style.opacity = 1;
        setInvalidMessage("Selected Date is invalid.");
        markAsValid(false);
      } else {
        wrapperRef.current.querySelector(".invalid-icon-calendar").style.visibility = "hidden";
        wrapperRef.current.querySelector(".invalid-icon-calendar").style.opacity = 0;
        wrapperRef.current.querySelector(".valid-icon-calendar").style.visibility = "visible";
        wrapperRef.current.querySelector(".valid-icon-calendar").style.opacity = 1;
        wrapperRef.current.querySelector(".select-country__icon").classList.add("active");
        input.classList.remove("invalid");
        input.classList.add("success");
        wrapperRef.current.querySelector(".invalid-feedback").style.visibility = "hidden";
        wrapperRef.current.querySelector(".invalid-feedback").style.opacity = 0;
        onDateChange(value);
        markAsValid(true);
      }
    }
  }, [value]);

  return (
    <div className="select-country">
      <label htmlFor={name} className="form-label">
        Date of Birth&nbsp; <span style={{ color: "red" }}>*</span>
      </label>
      <div ref={wrapperRef}>
        <div
          className="select-country__icon calendar"
          style={{ top: "55%", cursor: "pointer" }}
          onClick={() => {
            console.log("Clicked");
          }}>
          <label htmlFor="calendar-id">
            <Icon size={20} icon="calendar" />
          </label>
        </div>
        <div className="validity-icons" style={{ opacity: 0, visibility: "hidden" }}>
          <div className="invalid-icon-calendar" style={{ opacity: 0, visibility: "hidden" }}>
            <Icon icon="highlight_remove" size={22} />
          </div>
          <div className="valid-icon-calendar" style={{ opacity: 0, visibility: "hidden" }}>
            <Icon icon="check" size={22} />
          </div>
        </div>
        <DatePicker
          timePicker={false}
          value={value}
          onChange={(value) => {
            setValue(value);
          }}
          isGregorian={true}
        />
        <span
          className="invalid-feedback"
          style={{ opacity: 0, visibility: "hidden", transition: "none" }}>
          {invalidMessage}
        </span>
      </div>
    </div>
  );
}

export default DateOfBirth;
