// modules
import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";

const BankAccount = ({ account, setSelectedAccount, selectedAccount }) => {
  // check if this bank account is the current selected one
  const isCurrentSelected = (accountData) => {
    if (selectedAccount && accountData.id === selectedAccount.id) return true;
    return false;
  };

  // selected account handler
  const selectAccountHandler = (accountData) => {
    if (isCurrentSelected(accountData)) return setSelectedAccount(null);
    setSelectedAccount(accountData);
  };

  return (
    <div
      className={`transfer__bankAccount transfer__box ${
        isCurrentSelected(account) ? "selected" : null
      }`}
      onClick={() => selectAccountHandler(account)}>
      {isCurrentSelected(account) ? <CheckCircleFilled style={{ color: "#0054B8" }} /> : null}
      <h2>{account.bank_name}</h2>
      <h3>{account.username}</h3>
      <p>Account No : {account.account_no}</p>
    </div>
  );
};

export default BankAccount;
