/* eslint-disable no-unused-vars */
import React from "react";
// assets
import GrowthIcon from "../../../../assets/img/dashboard/growth-chart.png";

const ProcessCard = ({ img, title, content, hasOption }) => {
  console.log({ content });
  return (
    <div className="process__card">
      <img src={img} alt={title} />
      <div className="process__content">
        <h4>{title}</h4>
        <p>{content}</p>
      </div>
      {hasOption ? (
        <div className="process__options">
          <button>{hasOption}</button>
        </div>
      ) : null}
      {title === "EURST Balance" ? (
        <div className="wallet__stats">
          <div className="percentage">
            <img src={GrowthIcon} alt="USD wallet stats" />
            <span>78.6%</span>
          </div>
          <span>VS Previous 28 Days</span>
        </div>
      ) : null}
    </div>
  );
};

export default ProcessCard;
