/* eslint-disable */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../constants";
// children
import HeaderMenu from "./HeaderMenu";
// assets
import Logo from "../assets/img/sidebar/logo.png";
// antd
import { BellFilled } from "@ant-design/icons";
// styles
import "../styles/WidgetsStyles/HeaderStyles.scss";
// redux
import { useDispatch, useSelector } from "react-redux";
import { toggleHeaderMenuModal } from "../context/actions";

const Header = () => {
  const history = useHistory();

  // check if the current route is an auth one
  const isAuthRoute =
    history.location.pathname.includes("login") || history.location.pathname.includes("register")
      ? true
      : false;

  // dispatcher
  const dispatcher = useDispatch();

  // toggle menu modal
  const toggleMenuModal = () => dispatcher(toggleHeaderMenuModal());
  // window size
  const windowSize = useWindowSize();

  if (isAuthRoute) return <h1>Header</h1>;

  return (
    <header
      className={`${isAuthRoute ? "block" : null} ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null
      }`}>
      {/* <div className="logo">
        {!isAuthRoute ? (
          <Link to="/main/dashboard">
            <img src={Logo} alt="Logo icon" />
          </Link>
        ) : null}
      </div> */}
      {windowSize.width > MEDIA_QUERIES.MD ? (
        <div className="notifications">
          <div className="notifications__icon">
            <BellFilled style={{ fontSize: "20px" }} />
          </div>
        </div>
      ) : (
        <HeaderMenu onClick={toggleMenuModal} />
      )}
    </header>
  );
};

export default Header;
