/* eslint-disable */
import React, { useState } from "react";
import { Form, message, Button, Radio } from "antd";
import { useHistory } from "react-router-dom";
import Card from "antd/lib/card";
import "./index.scss";
import Cookies from "js-cookie";
import auth from "../../services/auth";
import { toast } from "react-toastify";
import { ShieldIcon, BusinessIcon } from "../../components/Icons";

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 5 },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const info = (e) => {
  e.preventDefault();
  message.info("Business sign up will be available soon.");
};

function AccountTypePage() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const [accType, setAccType] = useState();

  const onClickRegistration = () => {
    history.push("/registration");
  };

  const onClickRegistrationEntity = () => {
    history.push("/registration-entity");
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const onAccTypeChange = (e) => {
    setAccType(e.target.value);
  };
  return (
    <React.Fragment>
      <div className="login-wrap">
        <h2 className="registration-header">Choose your account type</h2>
        <Card className="login-card">
          <Form
            form={form}
            layout={"vertical"}
            size={"large"}
            name="register"
            onFieldsChange={handlerFieldsChange}
            onFinish={() => {}}
            initialValues={{}}
            scrollToFirstError
          >
            <Form.Item
              shouldUpdate={true}
              className="acc-type-wrap"
              name="agreement"
              valuePropName="checked"
            >
              <Radio.Group onChange={onAccTypeChange} value={accType}>
                <Radio value={0}>
                  <div className="icon-wrap">
                    <ShieldIcon width={"60px"} color={accType === 0 ? "#2058E5" : "grey"} />
                  </div>
                  <p>Private</p>
                  <p>
                    Create your private <br /> account
                  </p>
                  <Button className="primary-button" type="primary" onClick={onClickRegistration}>
                    Create
                  </Button>
                </Radio>
                <Radio value={1}>
                  <div className="icon-wrap">
                    <BusinessIcon width={"60px"} color={accType === 1 ? "#2058E5" : "grey"} />
                  </div>
                  <p>Business</p>
                  <p>
                    Submit business <br /> application
                  </p>
                  <Button
                    className="primary-button"
                    type="primary"
                    onClick={onClickRegistrationEntity}
                    // disabled={accType !== 1}
                  >
                    Submit
                  </Button>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default AccountTypePage;
