// modules
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
// components
import PasswordChangedMessage from "./PasswordChangedMessage";
// assets
import SuccessIcon from "../../assets/img/popup/success-icon.png";
// antd
import { Form, Input, Button, Modal } from "antd";

// services
import auth from "../../services/auth";

const UpdatePasswordForm = () => {
  // states
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [formData, setFormData] = useState(null);
  // auth states
  const [tempToken, setTempToken] = useState(null);
  const [isValid, setIsValid] = useState(false);
  // antd modal states & methods
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading] = React.useState(false);

  // popup methods
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    setTempToken(query.get("token"));
    history.push({ search: "" });
  }, [history]);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      await auth.resetPasswordConfirm(tempToken, values);
      setFormData(values);
      showModal();
    } finally {
      setLoading(false);
      history.push("/login");
    }
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "Please Enter the Registered Email address!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  // back to login
  const backToLogin = () => {
    history.push("/login");
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      history.push("/main/dashboard");
    }
  }, []);
  console.log("disabled", disabled);

  return (
    <div>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ password: "", confirm: "" }}
        onFieldsChange={(_, fields) => {
          const errors = fields.reduce((acc, field) => [...acc, ...field.errors], []);
          console.log(!errors.length);
          setIsValid(!errors.length);
        }}
        onFinish={onFinish}>
        <div className="reset-password-form-heading">
          <div className="heading-content">
            <h3 className="container-heading">Update your password</h3>
            <p>Please create your new password.</p>
          </div>
        </div>
        <Form.Item
          name="password"
          label="Create Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback>
          <Input.Password placeholder="123456" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}>
          <Input.Password placeholder="123456" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Modal
            className="password-changed-modal"
            title={
              <>
                <img src={SuccessIcon} alt="Success icon" />
                <span>Password Changed!</span>
              </>
            }
            visible={isModalVisible}
            centered
            footer={[
              <Button key="link" href="/login" onClick={handleOk}>
                Login
              </Button>,
            ]}>
            <PasswordChangedMessage email={formData?.email} setIsModalVisible={setIsModalVisible} />
          </Modal>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdatePasswordForm;
