import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Spin from "antd/lib/spin";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { getUser } from "../../services/users";
import { Context } from "../../store";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = Cookies.get("token");
  const [loading, setLoading] = useState(true);

  /* eslint-disable */
  const [state, dispatch] = useContext(Context);
  /* eslint-enable */

  useEffect(() => {
    if (rest.path !== "/logout" && token) {
      (async () => {
        try {
          const response = await getUser();
          dispatch({ type: "SET_USER", payload: response.data });
        } catch (error) {
          console.error("router", error);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
  }, [dispatch, rest.path, token]);

  if (!token) {
    return <Redirect to="/" />;
  }

  if (loading) return <Spin size="large" className="ant-spinner" />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <div>
          <Component {...props} />
        </div>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
