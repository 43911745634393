// modules
import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
import SuccessIcon from "../../assets/img/popup/success-icon.png";
// assets
import ViewIcon from "../../assets/img/login/view.png";
// antd
import { Form, Input, Button, Checkbox, Modal } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
// services
import auth from "../../services/auth";
import { Context } from "../../store";
import { getOwnerSettings } from "../../services/general";
import { UIContext } from "../RegisterComponents/UIContext";

const LoginForm = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { visible, setVisible } = useContext(UIContext);

  const onFinish = async (values) => {
    ReactGA.event({
      category: "Sign Up",
      action: "User pressed the big blue login button",
    });
    setLoading(true);
    try {
      const token = await auth.login(values);
      if (token) {
        Cookies.set("token", token.token.header);
        localStorage.setItem("user", JSON.stringify(token));
        history.push("/main/dashboard");
      }
    } catch (error) {
      console.error("login page", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancelClick = () => {
    history.goBack();
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "Please Enter the Registered Email address!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      history.push("/main/dashboard");
    }
  }, []);
  console.log("disabled", disabled);

  return (
    <div>
      <Form
        form={form}
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFieldsChange={handlerFieldsChange}
        validateMessages={validateMessages}
        onFinish={onFinish}>
        <div className="login-form-heading">
          <h3 className="container-heading">Login</h3>
          <p>Please enter your registered email and password.</p>
        </div>
        <Form.Item
          name="email"
          label="Email Address"
          rules={[
            {
              required: true,
              message: "Please enter your registered email address!",
              whitespace: true,
            },
            { type: "email" },
          ]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            onChange={() => console.log("hi")}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
          hasFeedback>
          <Input.Password placeholder="password" />
        </Form.Item>
        <Link className="login-form-forgot" to="/reset-password" style={{ marginLeft: "auto" }}>
          Forgot password
        </Link>
        <Form.Item style={{ marginTop: "1rem" }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            loading={loading}
            className="login-form-button">
            Login
          </Button>
          <Modal
            className="register-success"
            title={
              <>
                <img src={SuccessIcon} alt="Success icon" />
                <span>Congratulations</span>
              </>
            }
            visible={visible}
            centered
            okText="Done"
            onOk={() => {
              setVisible(false);
            }}
            onCancel={() => {
              setVisible(false);
            }}
            confirmLoading={false}>
            <p>
              You have successfully registered an account. Please check your email and verify your
              account.
            </p>
          </Modal>
          <div className="submit-message">
            <span>Do not have an account?</span>
            <Link className="login-form-forgot" to="/registration">
              Register
            </Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
