/* eslint-disable react/display-name */
// modules & hooks
import React from "react";
import { useSelector } from "react-redux";
// antd
import { Table } from "antd";

const CoinItem = () => {
  const isVisibleActionsModal = useSelector((state) => state.shared.isVisibleActionsModal);
  console.log({ isVisibleActionsModal });

  // antd table dummy columns & data
  const columns = [
    {
      title: "S.NO.",
      dataIndex: "key",
      key: "S.No.",
    },
    {
      title: "Coin Name",
      dataIndex: "coin_name",
      key: "coin_name",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const actions = (() => {
    return (
      <div className="coin-actions">
        <button>Mint</button>
        <button>Redeem</button>
      </div>
    );
  })();

  const data = [
    {
      key: "1",
      coin_name: "EURST",
      total: 10000,
      actions,
    },
    {
      key: "2",
      coin_name: "EURST",
      total: 10000,
      actions,
    },
    {
      key: "3",
      coin_name: "EURST",
      total: 10000,
      actions,
    },
    {
      key: "4",
      coin_name: "EURST",
      total: 10000,
      actions,
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default CoinItem;
