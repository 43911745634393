import React from "react";

export default function Header() {
  return (
    <div className="app-header">
      <div className="app-header__logo">
        <img src="/images/logo.png" draggable="false" alt="logo" />
      </div>
    </div>
  );
}
