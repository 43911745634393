import React from "react";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../constants";
// components
import { TransferForm, TransferShowcase } from "../components/TransferComponents";
// styles
import "../styles/PagesStyles/TransferStyles/index.scss";

const TransferPage = () => {
  // window size
  const windowSize = useWindowSize();
  return (
    <section
      className={`transfer ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "container-fluid"
      }`}>
      <TransferShowcase />
      <TransferForm />
    </section>
  );
};

export default TransferPage;
