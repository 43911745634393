// modules & hooks
import React, { useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../../constants";
// children
import TransactionsSubHeader from "./TransactionsSubHeader";
// assets
import TransactionsIcon from "../../../assets/img/home/transactions-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleTransactionsApplyFilter } from "../../../context/actions";
const TransactionsHeader = () => {
  // header filters
  const headerFilters = { minting: "MINTING", redeem: "REDEEM", withdraw: "WITHDRAW" };
  // states
  const [filter, setFilter] = useState(headerFilters.minting);
  // filter handler
  const filterHandler = (filter) => {
    setFilter(filter);
  };

  // dispatcher
  const dispatcher = useDispatch();

  // redux
  const isTransactionsApplyFilter = useSelector(
    (state) => state.shared.ui.isTransactionsApplyFilter
  );
  console.log({ isTransactionsApplyFilter });

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="transactions__header">
      {windowSize.width > MEDIA_QUERIES.MD ? (
        <div className="top-header">
          <h3 className="container-heading">Transactions</h3>
          <div className="transactions__header__options">
            <ul>
              <li
                className={`${filter === headerFilters.minting ? "active" : null}`}
                onClick={() => filterHandler(headerFilters.minting)}>
                Minting
              </li>
              <li
                className={`${filter === headerFilters.redeem ? "active" : null}`}
                onClick={() => filterHandler(headerFilters.redeem)}>
                Redeem
              </li>
              <li
                className={`${filter === headerFilters.withdraw ? "active" : null}`}
                onClick={() => filterHandler(headerFilters.withdraw)}>
                Withdraw
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="top-header responsive-md">
          <div className="top">
            <h3 className="container-heading">Transactions</h3>
            <img
              src={TransactionsIcon}
              alt="Transactions icon"
              onClick={(e) => {
                e.stopPropagation();
                dispatcher(toggleTransactionsApplyFilter());
              }}
            />
          </div>
          <div className="bottom">
            <div className="transactions__header__options">
              <ul>
                <li
                  className={`${filter === headerFilters.minting ? "active" : null}`}
                  onClick={() => filterHandler(headerFilters.minting)}>
                  Minting
                </li>
                <li
                  className={`${filter === headerFilters.redeem ? "active" : null}`}
                  onClick={() => filterHandler(headerFilters.redeem)}>
                  Redeem
                </li>
                <li
                  className={`${filter === headerFilters.withdraw ? "active" : null}`}
                  onClick={() => filterHandler(headerFilters.withdraw)}>
                  Withdraw
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {windowSize.width > MEDIA_QUERIES.MD ? <TransactionsSubHeader /> : null}
    </div>
  );
};

export default TransactionsHeader;
