// modules
import React, { useEffect, useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../../constants";
// antd
import { Select } from "antd";
// components
import ShowcaseProcesses from "./ShowcaseProcesses";
import { GetTokenList } from "../../../services/users";

const HomeShowcase = () => {
  // antd select methods
  const { Option } = Select;
  const [tokenList, setTokenList] = useState([]);

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  useEffect(() => {
    GetTokenList()
      .then((res) => {
        setTokenList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // window size
  const windowSize = useWindowSize();

  return (
    <div className={`showcase ${windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null}`}>
      <div className="showcase__header">
        <h4>Select Currency</h4>
        <div className="showcase__select">
          <Select defaultValue="EURST" style={{ width: 200 }} onChange={handleChange}>
            {tokenList.length
              ? tokenList.map((token, index) => {
                  return (
                    <Option key={index} value={token.id}>
                      {token.id}
                    </Option>
                  );
                })
              : null}
          </Select>
        </div>
      </div>
      <ShowcaseProcesses />
    </div>
  );
};

export default HomeShowcase;
