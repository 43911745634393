// modules
import React from "react";
import { Link, useHistory } from "react-router-dom";
// assets
import CloseIcon from "../../assets/img/reset-password/close.png";

const EmailSentMessage = ({ email, setIsModalVisible }) => {
  const history = useHistory();
  // navigate to the reset password confirm page
  const navToConfirm = () => {
    history.push("/reset-password-confirm");
  };

  // close the modal
  const closeModal = () => {
    setIsModalVisible(false);
    navToConfirm();
  };

  return (
    <div>
      <img src={CloseIcon} alt="Close icon" className="close" onClick={closeModal} />
      <p>
        We have sent a reset password email to <span>{email ?? email}</span>. Follow the
        instructions to reset your password.
      </p>
      <div className="message-footer">
        <span>Did not recieve an email?</span>
        <Link to="/resend">Re-send Email</Link>
      </div>
    </div>
  );
};

export default EmailSentMessage;
