import Cookies from "js-cookie";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import auth from "../../services/auth";
import Header from "./Header";
import InputGroup from "./InputGroup";
import gsap from "gsap";
import "./register.scss";
import { Modal } from "antd";
import { UIContext } from "../RegisterComponents/UIContext";
import SuccessIcon from "../../assets/img/popup/success-icon.png";

export default function Login() {
  const [email, setEmail] = useState("");
  const [emailValidity, setEmailValidity] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [autoFill, setAutoFill] = useState(false);
  const [password, setPassword] = useState("");
  const loginInputs = useRef(null);
  const [state, setState] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const history = useHistory();
  const cardRef = useRef(null);
  const { setVisible: setVisibleFn, visible: visibleModel } = useContext(UIContext);

  const onSubmit = async () => {
    try {
      const requestPayload = {
        email,
        password,
      };
      const token = await auth.login(requestPayload);
      if (token) {
        Cookies.set("token", token.token.token);
        localStorage.setItem("user", JSON.stringify(token));
        history.push("/main/dashboard");
      }
    } catch (error) {
      console.error("login page", error);
    }
  };

  const handleEmailChange = (e) => {
    e.persist();
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    e.persist();
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (cardRef.current) {
      gsap.fromTo(
        cardRef.current,
        {
          opacity: 0,
          ease: "expo.out",
          delay: 0.2,
          duration: 0.45,
          scale: 0.5,
        },
        { opacity: 1, ease: "expo.out", delay: 0.2, duration: 0.45, scale: 1 }
      );
    }
  }, []);

  useEffect(() => {
    document.addEventListener("onautocomplete", function (e) {
      if (!state) {
        e.target.hasAttribute("autocompleted");
        setAutoFill(true);
      }
    });
  }, []);

  return (
    <div className="register-page login-auth">
      <Header />
      <div className="register-page__content">
        <div className="auth-card" ref={cardRef}>
          <div className="auth-card__info">
            <div className="auth-card__header">
              <h3>Login</h3>
            </div>
            <div className="auth-card__description">
              <p>Please enter your registered email and password.</p>
            </div>
          </div>
          <hr />
          <div className="auth-card__tabs"></div>
          <div className="auth-card-contyent">
            <div className="wrapper">
              <form>
                <div className="login-inputs" ref={loginInputs}>
                  <InputGroup
                    name="email"
                    placeholder="john_deo855@gmail.com"
                    type="email"
                    value={email}
                    markAsValid={(validatity) => {
                      setEmailValidity(validatity);
                    }}
                    rules={[
                      { type: "required", message: "Email is required." },
                      { type: "email", message: "Email is invalid." },
                    ]}
                    onChange={handleEmailChange}
                    error={emailError}
                    label="Email Address"
                  />
                  <div className="forget-password">
                    <a
                      onClick={() => {
                        history.push("/reset-password");
                      }}>
                      Forgot Password
                    </a>
                  </div>
                  <InputGroup
                    name="password"
                    placeholder="*********"
                    rules={[
                      { type: "required", message: "Password is required." },
                      {
                        type: "min",
                        min: 8,
                        message: "Password must be at least 8 Symbols.",
                      },
                      {
                        type: "password",
                        message:
                          "password must have at least 1 uppercase character & 1 number & 1 Symbol",
                      },
                    ]}
                    type="password"
                    markAsValid={(validatity) => {
                      setPasswordValidity(validatity);
                    }}
                    value={password}
                    onChange={handlePasswordChange}
                    error={passwordError}
                    icon="remove_red_eye"
                    label="Password"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="auth-card__submit">
            <button
              className={`btn btn-primary btn-block ${
                autoFill || (emailValidity && password) ? "" : "disabled"
              }`}
              onClick={(e) => {
                if ((emailValidity && password) || autoFill) {
                  onSubmit();
                } else {
                  e.preventDefault();
                }
              }}>
              Login
            </button>
          </div>
          <div className="login-message">
            <p>
              Don&apos;t have an account ?{" "}
              <a
                onClick={() => {
                  history.push("/registration");
                }}
                style={{ cursor: "pointer" }}>
                Register
              </a>
            </p>
          </div>
        </div>
      </div>
      <Modal
        className="register-success"
        title={
          <>
            <img src={SuccessIcon} alt="Success icon" />
            <span>Congratulations</span>
          </>
        }
        visible={visibleModel}
        centered
        okText="Done"
        onOk={() => {
          setVisibleFn(false);
        }}
        onCancel={() => {
          setVisibleFn(false);
        }}
        confirmLoading={false}>
        <p>
          You have successfully registered an account. Please check your email and verify your
          account.
        </p>
      </Modal>
    </div>
  );
}
