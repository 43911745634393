// action types
import * as actionTypes from "../actions/actionTypes";

// initial state
const initialState = {
  isKybSubmitted: false,

  step1: {
    accountName: null,
  },

  step2: {
    entityContactInfo: {
      entityName: null,
      entityContactEmail: null,
      phoneNumber: null,
    },
    entityAddress: {
      entityAddressCountry: null,
      streetAddress: null,
      streetAddress2: null,
      city: null,
      region: null,
      postalCode: null,
    },
    entityDocumentVerification: {
      taxCountry: null,
      registrationNumber: null,
      regionOfFormation: null,
      proofOfAddress: null,
      proofOfEntityFormation: null,
    },
  },

  step3: {
    beneficialEntity: {
      entityInfo: {
        relationToShiftDotTech: null,
        entityName: null,
        entityEmail: null,
        entityPhoneNumber: null,
      },
      entityAddress: {
        countryOfResidence: null,
        streetAddress: null,
        streetAddress2: null,
        city: null,
        region: null,
        postalCode: null,
      },
      entityDocVerification: {
        taxCountry: null,
        registrationNumber: null,
        regionOfFormation: null,
        proofOfAddress: null,
        proofOfEntityFormation: null,
      },
    },

    beneficialOwner: {
      entityContactInfo: {
        relationToShiftDotTech: null,
        firstName: null,
        midName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
      },
      address: {
        countryOfResidence: null,
        streetAddress: null,
        streetAddress2: null,
        city: null,
        region: null,
        postalCode: null,
      },
      entityDocVerification: {
        taxCountry: null,
        governmentIssuedId: null,
        dateOfBirth: null,
        idDocument: null,
        frontOfID: null,
        backOfID: null,
      },
    },
  },

  step4: {
    additionalInformation: {
      purposeOfAccount: null,
      associationsWithOtherAccount: null,
      sourceOfAssets: null,
      intendedUseOfAccount: null,
      anticipatedTypesOfAssets: null,
      anticipatedMonthlyCashVolume: null,
      anticipatedTradingPatterns: null,
      anticipatedIncomeMonthlyTransactions: null,
      anticipatedOutcomeMonthlyTransactions: null,
      natureOfCompanyBusiness: null,
      fullName: null,
    },
  },
};

// reducer
const kybReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_KYB_STEP_ONE:
      return { ...state, step1: action.payload };
    case actionTypes.SUBMIT_KYB_STEP_TWO:
      return { ...state, step2: action.payload };
    case actionTypes.SAVE_BENEFICIAL_ENTITY:
      return {
        ...state,
        step3: { ...state.step3, beneficialEntity: action.payload },
      };
    case actionTypes.SAVE_BENEFICIAL_OWNER:
      return {
        ...state,
        step3: { ...state.step3, beneficialOwner: action.payload },
      };
    case actionTypes.SUBMIT_KYB_STEP_THREE:
      return { ...state, step3: action.payload };
    case actionTypes.SUBMIT_KYB_STEP_FOUR:
      return { ...state, step4: action.payload, isKybSubmitted: true };
    case actionTypes.GET_KYB:
      return { ...state };
    default:
      return { ...state };
  }
};

// exports
export default kybReducer;
