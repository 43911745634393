// modules
import React from "react";

const PasswordChangedMessage = () => {
  return (
    <div>
      <p>
        Your password has been changed successfully. Please login to your account with your new
        password.
      </p>
    </div>
  );
};

export default PasswordChangedMessage;
