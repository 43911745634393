// modules & hooks
import React from "react";
import { MEDIA_QUERIES } from "../../../../constants";
import useWindowSize from "../../../../hooks/useWindowSize";
// components
import ProcessCard from "./ProcessCard";
// images
import Balance from "../../../../assets/img/home/balance.png";
import Mint from "../../../../assets/img/home/mint.png";
import Redeem from "../../../../assets/img/home/redeem.png";

const balanceContent = () => {
  return (
    <p className="balance__content">
      <span>45.890.809</span>
      <span>45.809 USD</span>
    </p>
  );
};

const mintContent = () => {
  return (
    <p className="mint__content">
      Choose your Payment method and follow the payment instructions to sent the <span>EURST</span>{" "}
      to your wallet.
    </p>
  );
};

const redeemContent = () => {
  return (
    <p className="mint__content">
      Send the <span>EURST</span> to the redemption address and check your USD deposit balance at
      Wallex.
    </p>
  );
};

const ShowcaseProcesses = () => {
  // window size
  const windowSize = useWindowSize();

  return (
    <div
      className={`showcase__processes ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null
      }`}>
      <ProcessCard img={Balance} title="EURST Balance" content={balanceContent()} />
      <ProcessCard img={Mint} title="MINT EURST" content={mintContent()} hasOption="Proceed" />
      <ProcessCard
        img={Redeem}
        title="REDEEM EURST"
        content={redeemContent()}
        hasOption="Proceed"
      />
    </div>
  );
};

export default ShowcaseProcesses;
