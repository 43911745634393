// modules
import React, { useState, useEffect } from "react";
import axios from "axios";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useContext } from "react";
import { Context } from "../../store";
import "./index.scss";
import Cookies from "js-cookie";

const KYCPage = () => {
  const [state, dispatch] = useContext(Context);
  const { user } = state;
  console.log("User: ", user);
  const [token, setToken] = useState(null);
  const [userId, setuserId] = useState("");
  const [options, setOptions] = useState([]);
  const handler = () => {};
  const errorHandler = () => {};
  const messageHandler = () => {};
  const config = {
    email: user?.email,
    uiConf: {
      customCssStr: `input[type="email"] {
    pointer-events:none !important;
    cursor: not-allowed !important;
    color:#AAA !important;
    background:#F5F5F5 !important;
  }`,
    },
  };

  useEffect(() => {
    let email;
    if (user) {
      ({ email } = user);
    }
    if (!email) {
      email = JSON.parse(localStorage.getItem("user")).email;
    }
    axios
      .post(
        "https://eurst-upgrade.blockgemini.net/api/v1/applicant/kyc/create-access-token",
        { email },
        { headers: { Authorization: Cookies.get("token") } }
      )
      .then((resp) => {
        console.log(resp.data.data.token);
        setToken(resp.data.data.token);
        setuserId(resp.data.data.userId);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <section className="kyc-container">
      {token && (
        <SumsubWebSdk
          width={"100px"}
          accessToken={token}
          expirationHandler={handler}
          config={config}
          options={options}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      )}
    </section>
  );
};

export default KYCPage;
