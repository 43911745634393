// modules & hooks
import React from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../constants";
// components
import { RedeemForm } from "../components/RedeemComponents";
// assets
import BackArrow from "../assets/img/redeem/back-arrow.png";
// styles
import "../styles/ScreensStyles/RedeemStyles/index.scss";

const RedeemScreen = () => {
  // window size
  const windowSize = useWindowSize();

  return (
    <div
      className={`mint__screen redeem ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "container-fluid"
      }`}>
      <Link to="/main/dashboard" className="back-link">
        <img src={BackArrow} alt="Back arrow" />
        <span>Back</span>
      </Link>
      <div className="redeem-body">
        <div className="mint-heading">
          <h4>Redeem EURST</h4>
          <div className="exchange-info">1 EURST = 1.139537 USD</div>
        </div>
        <RedeemForm />
      </div>
    </div>
  );
};

export default RedeemScreen;
