// modules
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
// reducers
import { kycReducer, kybReducer, sharedReducer } from "./reducers";

const rootReducer = combineReducers({
  main: kycReducer,
  kyb: kybReducer,
  shared: sharedReducer,
});

const store = createStore(rootReducer, applyMiddleware(compose(thunk)));

// exports
export default store;
