// modules & hooks
import React from "react";
import { MEDIA_QUERIES } from "../../../constants/revamp";
import useWindowSize from "../../../hooks/useWindowSize";
// components
import CoinItem from "./CoinItem";

const AllCoins = () => {
  // window size
  const windowSize = useWindowSize();

  // responsive classes
  const responsiveClasses = () => {
    if (windowSize.width < MEDIA_QUERIES.XS) return "responsive-xs";
    else if (windowSize.width < MEDIA_QUERIES.SM) return "responsive-sm";
    else if (windowSize.width < MEDIA_QUERIES.MD) return "responsive-md";
    else return null;
  };

  return (
    <div className="coins">
      <div
        className={`${windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : "container-fluid"}`}>
        <h4>All Coins</h4>
        <div className="coins__grid">
          <div className="coins__values">
            <CoinItem />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCoins;
