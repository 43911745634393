// modules
import React from "react";
// antd
import { Select } from "antd";

const TransferResponsiveForm = () => {
  const { Option } = Select;

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <form>
      <div className="form__grid">
        <div className="form__col">
          <div className="input__field">
            <label htmlFor="recipientName">Recipient Name</label>
            <input
              type="text"
              placeholder="Enter Recipient Name"
              id="recipientName"
              name="recipientName"
            />
          </div>
          <div className="input__field">
            <label htmlFor="country">Country</label>
            <Select defaultValue="Select Country" style={{ width: 200 }} onChange={handleChange}>
              <Option value="Select Country">Select Country</Option>
              <Option value="Select Country">Select Country</Option>
            </Select>
          </div>
          <div className="input__field">
            <label htmlFor="recipientPhoneNumber">Recipient Phone Number</label>
            <Select
              defaultValue="Enter Reciept Number"
              style={{ width: 200 }}
              onChange={handleChange}>
              <Option value="Select Number"></Option>
              <Option value="Select Number">Select Country</Option>
            </Select>
          </div>
          <div className="input__field">
            <label htmlFor="bank">Bank</label>
            <Select defaultValue="Select Bank" style={{ width: 200 }} onChange={handleChange}>
              <Option value="Select Bank">Select Bank</Option>
              <Option value="Select Bank">Select Bank</Option>
            </Select>
          </div>
          <div className="input__field">
            <label htmlFor="accountNumber">Account Number</label>
            <input
              type="text"
              placeholder="Enter Recipient Phone Number"
              id="accountNumber"
              name="accountNumber"
            />
          </div>
          <div className="input__field">
            <label htmlFor="confirmAccountNumber">Confirm Account Number</label>
            <input
              type="text"
              placeholder="Re-enter Account Number"
              id="confirmAccountNumber"
              name="confirmAccountNumber"
            />
          </div>
        </div>
      </div>
      <div className="form-submit">
        <button className="btn-black lg">Save</button>
      </div>
    </form>
  );
};

export default TransferResponsiveForm;
