// modules & hooks
import React from "react";
import { MEDIA_QUERIES } from "../constants";
import useWindowSize from "../hooks/useWindowSize";
// components
import { MintForm } from "../components/MintComponents";
// styles
import "../styles/ScreensStyles/MintStyles/index.scss";

const MintScreen = () => {
  // window size
  const windowSize = useWindowSize();

  return (
    <div
      className={`mint__screen ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : "container-fluid"
      }`}>
      <div className="mint-heading">
        <h4>Mint EURST</h4>
        <div className="exchange-info">1 EURST = 1.139537 USD</div>
      </div>
      <div
        className={`mint__warning ${windowSize.width < MEDIA_QUERIES.MD ? "responsive-md" : null}`}>
        <h5>Note</h5>
        <ol>
          <li>Lorem ipsum dolor sit amet consectetur.</li>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente, dolores!</li>
          <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, molestiae!</li>
        </ol>
      </div>
      <MintForm />
    </div>
  );
};

export default MintScreen;
