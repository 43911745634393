// modules & hooks
import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
// antd
import { ExclamationCircleFilled } from "@ant-design/icons";
import { MEDIA_QUERIES } from "../../../constants/revamp";

const USDWallet = () => {
  const noticeText = "Please enter your wallet address to view your balance.";

  // window size
  const windowSize = useWindowSize();

  return (
    <div className="wallet-overview">
      <div className="wallet-header">
        <h3 className="container-heading">USD Wallet</h3>
        <div
          className={`add-wallet-warning ${
            windowSize.width < MEDIA_QUERIES.SM ? "responsive-sm" : null
          }`}>
          <ExclamationCircleFilled />
          <p>{noticeText}</p>
        </div>
      </div>
      <div className="wallet-balance">
        <h6>Wallet Balance</h6>
        <span>-</span>
      </div>
      {/* <div className="wallet-content">
        <h6>Wallet Balance</h6>
        <h4>$17,989,788.90</h4>
      </div>
      <div className="wallet-stats">
        <div className="percentage">
          <img src={GrowthIcon} alt="USD wallet stats" />
          <span>78.6%</span>
        </div>
        <span>VS Previous 28 Days</span>
      </div> */}
    </div>
  );
};

export default USDWallet;
