/* eslint-disable no-unused-vars */
// modules
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// children
import PasswordRules from "../../UI/PasswordRules";
// assets
import SuccessIcon from "../../assets/img/popup/success-icon.png";
// antd
import {
  Form,
  Input,
  Checkbox,
  Button,
  Space,
  DatePicker,
  Modal,
  Select,
  Tooltip,
  Popover,
} from "antd";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import { Link, Redirect, useHistory } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
// context
import { togglePasswordRules } from "../../context/actions";
// services
import { Context } from "../../store";
import { USER_TYPE } from "eurst-shared/src/enums";
import Cookies from "js-cookie";
import moment from "moment";
import auth from "../../services/auth";
import { InfoCircleFilled } from "@ant-design/icons";
import useWindowSize from "../../hooks/useWindowSize";
import { MEDIA_QUERIES } from "../../constants";
import { UIContext } from "./UIContext";

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const RegisterForm = ({ formType, countries }) => {
  const formTypes = { private: "PRIVATE", business: "BUSINESS" };
  const [countryCode, setCountryCode] = useState("");

  // states
  const [isConfirmPasswordModal, setIsConfirmPasswordModal] = useState(false);

  const toggleConfirmModalHandler = (bool) => {
    if (isConfirmPasswordModal) {
      setIsConfirmPasswordModal(false);
      return;
    }
    setIsConfirmPasswordModal(bool);
    dispatcher(togglePasswordRules());
  };

  const [form] = Form.useForm();
  const [size] = useState("default");

  // dispatcher
  const dispatcher = useDispatch();

  const togglePasswordRulesHandler = (e) => {
    e.stopPropagation();
    toggleConfirmModalHandler(false);
  };

  // selectors
  const isActivePasswordRules = useSelector((state) => state.shared.ui.isPasswordRulesModal);

  // popups states
  const [visible, setVisible] = useState(false);
  const [privacyVisible, setPrivacyVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "You have successfully registered an account. Please check your email and verify your account."
  );

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useContext(Context);
  const { setVisible: setVisibleFn } = useContext(UIContext);

  const history = useHistory();

  // api functionality
  const dateFormatList = ["YYYY/MM/DD", "YY/MM/DD"];

  // if (!state.countryCode) {
  //   return <Redirect to="/check-country" />;
  // }

  const onFinish = async (values) => {
    setLoading(true);
    try {
      for (let k in values) {
        if (k.indexOf("-private") != -1) {
          const key = k.split("-private")[0];
          values[key] = values[k];
          delete values[k];
        }
      }
      values.countryCode = Cookies.get("countryCode");

      if (formType === formTypes.private) {
        values.dateOfBirth = moment(values?.dateOfBirth).format("YYYY-MM-DD");
        values.type = USER_TYPE.naturalPerson;
      } else {
        values.type = USER_TYPE.company;
      }
      const registrationResponse = await auth.registration(values);
      if (registrationResponse.id) {
        showModal();
        history.push("/login");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  // antd select utils
  const { Option } = Select;
  function handleChange(value) {
    console.log(`selected ${value}`);
    setCountryCode(value);
    Cookies.set("countryCode", value);
    dispatch({ type: "SET_COUNTRY_CODE", payload: value });
  }

  // popup show method
  const showModal = () => {
    setVisibleFn(true);
  };

  const showPrivacyPolicyModal = () => {
    setPrivacyVisible(true);
  };

  const showTermsPolicyModal = () => {
    setTermsVisible(true);
  };
  // popup OK method
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      history.push("/login", []);
    }, 1000);
    form.resetFields();
  };

  const handleOkPrivacy = () => {
    setPrivacyVisible(false);
  };

  const handleOkTerms = () => {
    setTermsVisible(false);
  };

  // popup cancel menthods
  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancelPrivacy = () => {
    setPrivacyVisible(false);
  };

  const handleCancelTerms = () => {
    setTermsVisible(false);
  };

  const disabledDate = (current) => {
    const start = moment("1900-01-01", "YYYY-MM-DD");
    const end = moment("2004-01-01", "YYYY-MM-DD");
    return current < start || current > end || current > moment();
  };

  // window size
  const windowSize = useWindowSize();

  return (
    <Form
      form={form}
      layout={"vertical"}
      size={"large"}
      name="register"
      onFieldsChange={handlerFieldsChange}
      onFinish={onFinish}
      initialValues={{}}
      scrollToFirstError>
      {formType === formTypes.private ? (
        <>
          <Form.Item
            name="country-private"
            label="Select Country"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select
              onChange={handleChange}
              defaultValue="Select Country"
              className="register-select-country">
              {countries?.map((e) => (
                <Option value={e.code} key={e.code} disabled={!e.available}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="email-private"
            label="Email Address"
            rules={[
              {
                type: "email",
                message: "please enter a valid email",
              },
              {
                required: true,
                message: "Please enter your E-mail!",
              },
            ]}>
            <Input placeholder="John_deo@email.com" />
          </Form.Item>
          <Form.Item
            name="userName-private"
            label="Create User Name"
            rules={[
              {
                required: true,
                message: "Please enter your username!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="John Deo" />
          </Form.Item>
          <Form.Item
            name="dateOfBirth-private"
            label="Date Of Birth"
            rules={[
              {
                required: true,
                message: "Please enter your date of birth!",
              },
            ]}>
            <DatePicker
              size={size}
              inputReadOnly
              placeholder={"YEAR/MM/DD"}
              disabledDate={disabledDate}
              format={dateFormatList}
            />
          </Form.Item>
          <Form.Item
            className="password-field"
            name="password-private"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
              {
                min: 8,
                message: "Password must be more than 8 symbols!",
              },
              {
                pattern: /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]/,
                message: "Password is not valid!",
              },
            ]}
            hasFeedback>
            <Input.Password
              prefix={
                <Tooltip
                  title={
                    <div className="password-rules">
                      <h4 className="container-heading-color">Password should contain</h4>
                      <ul>
                        <li>More than 8 characters</li>
                        <li>Uppercase letters (A-Z)</li>
                        <li>Lowercase letters (a-z)</li>
                        <li>Digits (0-9)</li>
                        <li>Special characters (!, $, #, %, etc.)</li>
                      </ul>
                    </div>
                  }>
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
              placeholder="Abcd@123"
            />
          </Form.Item>

          <Form.Item
            name="confirm-private"
            label="Confirm Password"
            dependencies={["password-private"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              {
                min: 8,
                message: "Confirm password must be more than 8 symbols!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password-private") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}>
            {/* <InfoCircleFilled className="info-icon" onClick={() => toggleConfirmModalHandler(true)} /> */}
            <Input.Password placeholder="Abcd@123" />
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item
            name="Country"
            label="Select Country"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select
              onChange={handleChange}
              defaultValue="Select Country"
              className="register-select-country">
              {countries?.map((e) => (
                <Option value={e.code} key={e.code} disabled={!e.available}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please enter your E-mail!",
              },
            ]}>
            <Input placeholder="John_deo@email.com" />
          </Form.Item>
          <Form.Item
            name="userName"
            label="Create Company Name"
            rules={[
              {
                required: true,
                message: "Please enter your company name!",
                whitespace: true,
              },
            ]}>
            <Input placeholder="John Deo" />
          </Form.Item>
          <Form.Item
            className="password-field"
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
              {
                min: 8,
                message: "Password must be more than 8 symbols!",
              },
              {
                pattern: /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]/,
                message: "Password is not valid!",
              },
            ]}
            hasFeedback>
            <Input.Password
              style={{ "-webkit-text-security": "square" }}
              prefix={
                <Tooltip
                  title={
                    <div className="password-rules">
                      <h4 className="container-heading-color">Password should contain</h4>
                      <ul>
                        <li>More than 8 characters</li>
                        <li>Uppercase letters (A-Z)</li>
                        <li>Lowercase letters (a-z)</li>
                        <li>Digits (0-9)</li>
                        <li>Special characters (!, $, #, %, etc.)</li>
                      </ul>
                    </div>
                  }>
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
              placeholder="Abcd@123"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            // rules={[
            //   {
            //     required: true,
            //     message: "Please confirm your password!",
            //   },
            //   ({ getFieldValue }) => ({
            //     validator(_, value) {
            //       if (!value || getFieldValue("password") === value) {
            //         return Promise.resolve();
            //       }
            //       console.log('return')
            //       return Promise.reject(new Error("The two passwords that you entered do not match!"));
            //     },
            //   }),
            // ]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              {
                min: 8,
                message: "Confirm password must be more than 8 symbols!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}>
            {/* <InfoCircleFilled className="info-icon" onClick={() => toggleConfirmModalHandler(true)} /> */}
            <Input.Password placeholder="Abcd@123" />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("Please read and accept the agreement")),
          },
        ]}
        {...tailFormItemLayout}>
        <Checkbox
          style={{
            display: "inline-flex",
            maxWidth: "100%",
          }}>
          <span>I have read the</span>
          <span className="terms-of-use-container">
            <span type="primary" onClick={showTermsPolicyModal} className="link">
              Terms of use
            </span>
            <Modal
              className="terms-of-use-popup"
              visible={termsVisible}
              centered
              okText="Agree"
              onOk={handleOkTerms}
              cancelText="Close"
              onCancel={handleCancelTerms}>
              <TermsOfUse handleCancelTerms={handleCancelTerms} />
            </Modal>
          </span>
          <span className="nowrap">and the</span>
          <span className="privacy-policy-container">
            <span type="primary" onClick={showPrivacyPolicyModal} className="link">
              Privacy policy
            </span>
            <Modal
              className="privacy-policy-popup"
              visible={privacyVisible}
              centered
              okText="Agree"
              onOk={handleOkPrivacy}
              cancelText="Close"
              onCancel={handleCancelPrivacy}>
              <PrivacyPolicy handleCancelPrivacy={handleCancelPrivacy} />
            </Modal>
          </span>
        </Checkbox>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
        <Modal
          className="register-success"
          title={
            <>
              <img src={SuccessIcon} alt="Success icon" />
              <span>Congratulations</span>
            </>
          }
          visible={visible}
          centered
          okText="Done"
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={confirmLoading}>
          <p>{modalText}</p>
        </Modal>
      </Form.Item>
      <Form.Item>
        <p>
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
