// modules & hooks
import React, { useEffect, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
// childrens
import HeaderNav from "./HeaderNav";
import DashboardPage from "../pages/DashboardPage";
import TransferPage from "../pages/TransferPage";
import Header from "./Header";
import HomePage from "../pages/HomePage";
import ProfilePage from "../pages/ProfilePage";
import TransactionsPage from "../pages/TransactionsPage";
import KYCPage from "../pages/KYCPage";
import KYBPage from "../pages/KYBPage";
import MintScreen from "../screens/MintScreen";
import RedeemProcessScreen from "../screens/RedeemScreen";
import Sidebar from "./Sidebar";
import LoginPage from "../screens/LoginScreen";
import ResetPasswordPage from "../screens/ResetPasswordScreen";
import RegisterPage from "../screens/RegisterScreen";
// styles
import "../styles/WidgetsStyles/LayoutStyles.scss";
import {
  setCurrentNav,
  toggleDashboardActionsModal,
  toggleHeaderMenuModal,
  togglePasswordRules,
  toggleTransactionsApplyFilter,
} from "../context/actions";
import NewSidebar from "./NewSidebar";

const Layout = () => {
  // selectors
  const Nav = useSelector((state) => state.shared.currentNav);
  console.log({ Nav });
  // history
  const history = useHistory();
  console.log(history.location.pathname);

  useEffect(() => {
    setCurrentNav(history.location.pathname);
  }, [history]);

  const isAuthRoute =
    history.location.pathname.includes("login") || history.location.pathname.includes("register")
      ? true
      : false;

  // TODO: GET RID OF THIS METHOD AND FIX THE SIDE BAR LINKS BETTER
  useEffect(() => {
    setTimeout(() => {
      isAuthRoute;
    }, 2000);
  }, []);

  // dispatcher
  const dispatcher = useDispatch();

  // selectors
  const isTransactionsApplyFilter = useSelector(
    (state) => state.shared.ui.isTransactionsApplyFilter
  );
  const isThereActiveModal = useSelector((state) => state.shared.ui.isThereActiveModal);
  const isHeaderMenuModal = useSelector((state) => state.shared.ui.isHeaderMenuModal);
  const isDashboardActionsModal = useSelector((state) => state.shared.ui.isDashboardActionsModal);
  const isPasswordRulesModal = useSelector((state) => state.shared.ui.isPasswordRulesModal);

  console.log({ isTransactionsApplyFilter });

  // window size
  const windowSize = useWindowSize();

  console.log({ windowSize });

  const clickLayoutHandler = () => {
    isTransactionsApplyFilter && dispatcher(toggleTransactionsApplyFilter());
    isHeaderMenuModal && dispatcher(toggleHeaderMenuModal());
    isDashboardActionsModal && dispatcher(toggleDashboardActionsModal());
    isPasswordRulesModal && dispatcher(togglePasswordRules());
  };

  console.log({ isPasswordRulesModal });

  return (
    <BrowserRouter>
      <div className="main-container" onClick={clickLayoutHandler}>
        {isThereActiveModal ? <div className="overlay"></div> : null}
        {!isAuthRoute && windowSize.width > 1200 ? <NewSidebar /> : null}
        <section className="main">
          <Header />
          {windowSize.width < 1200 ? <HeaderNav /> : null}

          <Switch>
            <Route path="/main/dashboard" component={DashboardPage} />
            <Route path="/main/home" component={HomePage} />
            <Route path="/main/withdraw" component={TransferPage} />
            <Route path="/main/transactions" component={TransactionsPage} />
            <Route path="/main/kyc" component={KYCPage} />
            <Route path="/main/kyb" component={KYBPage} />
            <Route path="/main/profile" component={ProfilePage} />
            <Route path="/main/mint-process" component={MintScreen} />
            <Route path="/main/redeem-process" component={RedeemProcessScreen} />
            <Route path="/main/login" component={LoginPage} />
            <Route path="/main/register" component={RegisterPage} />
            <Route path="/main/reset-password" component={ResetPasswordPage} />
          </Switch>
        </section>
      </div>
    </BrowserRouter>
  );
};

export default Layout;
