// modules & hooks
import React, { useState, useEffect, useContext } from "react";
import useWindowSize from "../hooks/useWindowSize";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { MEDIA_QUERIES } from "../constants";
// components
import RegisterForm from "../components/RegisterComponents/RegisterForm";
// styles
import "../styles/ScreensStyles/RegisterStyles/index.scss";

import { getCountries } from "../services/countries";
import { Context } from "../store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { togglePasswordRules } from "../context/actions";

const RegisterPage = () => {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const formTypes = { private: "PRIVATE", business: "BUSINESS" };
  // states
  const [formType, setFormType] = useState(formTypes.private);

  // toggle form type method
  const toggleFormTypeHandler = (type) => {
    setFormType(type);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      history.push("/main/dashboard");
    } else {
      (async () => {
        try {
          const data = await getCountries();

          setCountries(data.filter((item) => item.available));
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [history]);

  // window size
  const windowSize = useWindowSize();

  // dispatcher
  const dispatcher = useDispatch();

  // selectors
  const isPasswordRulesModal = useSelector((state) => state.shared.ui.isPasswordRulesModal);

  // click register screnn handler
  const clickScreenHandler = () => {
    isPasswordRulesModal && dispatcher(togglePasswordRules());
  };

  return (
    <div
      className={`register ${
        windowSize.width < MEDIA_QUERIES.MD ? "responsive-md container" : null
      }`}
      onClick={clickScreenHandler}>
      <div className="register-container">
        <div className="form-heading">
          <h3 className="container-heading">Create your Account</h3>
          <p>Please, provide your required information ant start Mint &amp; Redeem EURST.</p>
        </div>
        <div className="switch-box">
          <div className="switch-options">
            <div
              className={`private-option option ${
                formType === formTypes.private ? "active" : null
              }`}
              onClick={() => toggleFormTypeHandler(formTypes.private)}>
              Private
            </div>
            <div
              className={`business-option option ${
                formType === formTypes.business ? "active" : null
              }`}
              onClick={() => toggleFormTypeHandler(formTypes.business)}>
              Business
            </div>
          </div>
        </div>
        <RegisterForm formType={formType} countries={countries} />
      </div>
    </div>
  );
};

export default RegisterPage;
